.mscDetailsContainer {
  @include flex(row, flex-start, center, wrap);

  @media (max-width: $mobile-width) {
    @include flex(row, center, center, wrap);
  }

  width: 100%;

  #supplierCard {
    .dashboardCardMain {
      overflow: unset;
    }
  }

  #viewSupplier {
    width: 'fit-content';
  }

  #supplierCard {
    // min-width: 380px;
    // max-width: 393px;

    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $mobile-width) {
      margin-right: 0;
    }

    .dashboardCardHeader {
      // This is here so the dashboard card header heights match.
      #manageSuppliersSearch {
        margin-right: 2rem;
        width: 550px;
        @media (max-width: $tablet-width) {
          margin-right: 1rem;
          width: 350px;
        }
        @media (max-width: $mobile-width) {
          margin-right: 0rem;
          width: 250px;
        }
      }
      min-height: 47.6px;
    }
  }
}

.mscLotContainer {
  @include flex(column, center, center, nowrap);
  width: 100%;
  border-bottom: 1px solid map-get($colour-palette, open-divider);
  margin-bottom: 1rem;

  .lotTitleContainer {
    color: map-get($colour-palette, open-brand-primary);
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    margin: 2rem 0rem 2rem 0rem;
    font-weight: 600;
    font-size: 1.8rem;
  }
  .lotName{
    font-size: 1.8rem;
  }
  &:last-child {
    border-bottom: none;
  }
}

.mscSupplierRow {
  @include flex(row, space-between, flex-start, nowrap);
  border: 1px solid map-get($colour-palette, open-brand-primary);
  width: 98%;
  margin-bottom: 2rem;
  border-radius: 1rem;
  padding: 1rem;
  &.red{
    border: 1px solid red;
  }
  &.green{
    border: 1px solid green;
  }
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
  @media (max-width: $tablet-width) {
    width: auto;
  }

  .mscSupplierDetails {
    @include flex(row, space-between, flex-start, nowrap);
    width: 100%;
    padding: 0rem 1rem;
    .companyDetails{
      @include flex(row, flex-start, flex-start, nowrap);
      .comapanyTitle {
        font-size: 1.6rem;
        line-height: 1.9;
        font-weight: 600;
        color: black;
      }
    }
   
  }
}

.mscButtonContainer {
  @include flex(row, flex-end, center, nowrap);
  width: 100%;

  button {
    margin-left: 1rem;
    font-weight: bold;
  }
}