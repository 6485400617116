.opportunityOverviewContainer {
  @include flex(row, space-between, stretch, wrap);
  width: 100%;

  #submissionDeadlineContainer {
    width: 100%;
  }

  #opportunityDetails {
    height: 100%;
    .dashboardCardMain {
      overflow: hidden;
      .sectionHeader {
        margin-top: 2rem;
      }
      .sectionContent {
        padding-top: 0;
        padding-right: 0;
        padding-left: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        &Tags {
          @include flex(row, flex-start, flex-start, wrap);
          flex: 1;

          @media (max-width: $mobile-width) {
            padding: 1.5rem 0;
          }

          .tag-content {
            margin: 0 2rem 2rem 0;

            @media (max-width: $mobile-width) {
              margin: 0 0 1rem 0;
            }
          }
        }
      }
      .sectionContent:last-child {
        border-bottom: none;
      }
    }
  }

  #lots {
    min-height: 520px;
    height: 100%;
  }

  #correspondenceContent {
    margin-bottom: 1rem;
  }

  #correspondenceContent > *:not(:last-child) {
    margin-bottom: 1rem;
  }

  #pastActionContanier {
    @include flex(column, flex-start, flex-start, wrap);
    .actionItem {
      width: 100%;
      margin-top: 2rem;

      p {
        margin-bottom: 1rem;
      }

      button {
        width: auto;
      }
    }
  }
}

.faq_content {
  padding: 1.5rem;
}

#activityLog {
  .logItem {
    @include flex(column, flex-start, flex-start, nowrap);
    .headerTitle {
      margin: 0;
    }
  }
  .logItem:not(:last-child) {
    margin-bottom: 1rem;
  }
}

#removeInterestModal {
  .caption {
    margin: 1rem 0 1rem 0;
  }

  #statusReasons-select {
    height: 50px;
    width: 100%;
    max-width: 100%;
    font-size: 1.4rem;
    margin-bottom: 1rem;
  }

  #otherReason-textarea {
    width: 100%;
  }
}
