#lotOverviewContainer {
  width: 100%;
  margin-top: 3rem;
  #awardedSuppliers {
    width: 100%;
    .supplierItem {
      @include flex(row, space-between, center, nowrap);
      .supplierDetails {
        @include flex(column, flex-start, flex-start, nowrap);
        width: 100%;
      }
    }
    .supplierItem:not(:last-child) {
      margin-bottom: 1rem;
    }
  }
}
#lotSection {
  #lotFormContent {
    #title-input {
      width: 50%;

      @media (max-width: $mobile-width) {
        width: 100%;
      }
    }
    .radio-button-group {
      @media (max-width: $mobile-width) {
        @include flex(column, flex-start, start, nowrap);
      }
    }

    .labelled-textarea {
      width: 100%;
      #description-textarea {
        width: 100%;
      }
    }
  }
}
#lotSectionContent {
  border-top: 1px solid #ebebeb;
  padding: 1rem 0;
  width: 100%;

  .textIconContainer {
    .sectionContentTags {
      @include flex(row, flex-start, start, wrap);

      .tag-content.large {
        margin: 0 1rem 1rem 0;
      }
    }
  }
}
.sectionContent {
  padding: 2rem 2rem;
  border: none;
  width: 100%;
  word-break: break-word;
  @media (max-width: $mobile-width) {
    padding-bottom: 1rem;
  }

  .sectionRow {
    @include flex(row, flex-start, flex-start, nowrap);
    margin-top: 2rem;

    @media (max-width: $mobile-width) {
      flex-wrap: wrap;
      p {
        width: 100%;
        margin-left: 0;

        &:first-of-type {
          margin-bottom: 1rem;
        }
      }
    }

    .sectionLabel {
      width: 100%;
      @media (min-width: $laptop-width) {
        max-width: 250px;
      }

      @media (min-width: $mobile-width) {
        max-width: 230px;
      }
    }

    .sectionValue {
      width: 100%;
      word-break: break-word;
      color: map-get($colour-palette, open-text-primary);
    }
  }
}

.lfModal {
  p {
    margin-bottom: 1rem;
  }

  .lf-margin-top {
    margin-top: 2rem;
  }
}
