.dashboardContainer {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;

  .addProject {
    width: auto;
  }

  #selection-questionnaire {
    .overview-body {
      p {
        margin-bottom: 1rem;
      }
    }
  }

  #actions {
    #dashboardActionContainer {
      margin-top: 2rem;

      #dashboardActionWorknotes > *:not(:last-child) {
        margin-bottom: 1rem;
      }

      #dashboardActionWorknotes {
        margin-bottom: 2rem;
      }
    }
    .uploadContainer {

      .upFormInputContainer {
        width: 100%;
        max-width: 100%;

        .flex-break {
          margin: 0;
          height: 0;
        }

        .upDocumentButtonContainer {
          padding: 0;
          margin: 0;
        }
      }

      .upFormInputContainer > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }
}

#supplierPendingApproval {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  flex: 1;
  border-radius: 10px;
  background-color: map-get($colour-palette, open-white);
  border: 1px solid map-get($colour-palette, open-content-container-border);
  padding-bottom: 4rem;
  #boError {
    margin: 4rem 0 0 0;
  }
}

.headerTitle {
  color: map-get($colour-palette, open-brand-primary);
  margin-right: 2rem;

  @media (max-width: $mobile-width) {
    margin-right: 0;
  }
}

.cardContentContainer {
  @include flex(column, space-between, space-between, nowrap);
  width: 100%;
  max-height: 520px;
  padding-bottom: 1.2rem;
  box-sizing: border-box;

  @media (max-width: $mobile-width) {
    padding-bottom: 1.5rem;
  }

  .eventItem {
    @include flex(row, flex-start, flex-start, nowrap);
    margin-bottom: 1rem;
    max-width: 360px;
    width: 100%;

    .leftColumn {
      width: 22%;
      margin-right: 0.2rem;
      word-break: break-word;
      p {
        color: map-get($colour-palette, open-grey);
        margin-top: 0.4rem;
      }
    }

    .middleColumn {
      width: 48%;
      .eventHeader {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        padding-bottom: 1rem;
        word-break: break-word;
        .eventStatus {
          color: map-get($colour-palette, open-grey);
          text-align: right;
        }
      }

      .eventContent p {
        line-height: 125%;
      }
    }

    .rightColumn {
      width: 30%;
      .statusText {
        @include flex(column, space-around, center, nowrap);
        height: 25px;
        min-width: 100px;
        border-radius: 50px;
        margin-right: 2rem;
        color: map-get($colour-palette, open-white);

      }

    }

    #Overdue {
      background-color: #da6a6a;
    }
    #In-progress {
      background-color: #f7d08e;
    }
    #Pending {
      background-color: #78cbc3;
    }
    #Approve {
      width: 100px;
      height: 30px;
      font-size: 1.4rem;
    }
    #Approve:first-of-type {
      margin-bottom: 1rem;
    }
  }

  .eventItem:last-of-type {
    margin-bottom: 0;
  }

  .submitRequestItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin-bottom: 2rem;

    .button {
      height: 30px;
      width: 100px;
      border-radius: 5px;
    }
  }

  .submitRequestItem:last-of-type {
    margin-bottom: 0;
  }

  .approvalItem {
    @include flex(column, space-between, space-between, nowrap);
    width: 100%;
    margin: 1rem 0;

    .button {
      width: 100%;
      max-width: 200px;
      font-size: 1.4rem;
      height: 30px;
      margin-top: 0.5rem;
    }
  }

  .approvalItem:last-of-type {
    margin-bottom: 0;
  }

  .solutionAccessRequestItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin-bottom: 2rem;

    .button {
      height: 30px;
      width: 100px;
      border-radius: 5px;
    }

    .button:first-of-type {
      margin-bottom: 1rem;
    }
  }

  .escalationItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin: 1rem 0;

    .caption {
      margin-top: 0.5rem;
    }

    .caption-small {
      text-align: right;
    }

    .button {
      height: 30px;
      width: 100px;
      font-size: 1.4rem;
      margin-top: 0.5rem;
    }
  }

  .escalationItem:last-of-type {
    margin-bottom: 0;
  }

  .requestItem {
    @include flex(column, space-between, space-between, nowrap);
    margin: 1rem 0;

    .requestButtons {
      @include flex(row, space-between, space-between, nowrap);
      margin-top: 1rem;

      .button {
        height: 30px;
        max-width: 100px;
        font-size: 1.4rem;
      }

      .button:first-of-type {
        width: 120px;
        max-width: 120px;
        margin-right: 10%;
      }

      .button:last-of-type {
        margin-left: 1rem;
      }

      @media (max-width: $tablet-width) {
        @include flex(column, space-between, flex-end, nowrap);
        .button:first-of-type {
          margin-right: 0;
        }
      }
    }
  }
}

#openRequestContentCard,
#openUserRequestContentCard {
  .accountAccessRequestItem {
    @include flex(column, space-between, center, nowrap);
    width: 100%;
    margin-bottom: 2rem;
    .userDetails {
      width: 100%;
      margin-bottom: 1rem;
    }
    .actionsButtonsContainer {
      width: 100%;
      @include flex(row, space-between, center, nowrap);
      .button {
        height: 30px;
        width: 100px;
        border-radius: 5px;
      }

      .button:first-of-type {
        margin-right: 1rem;
      }
    }
  }
}
#callOffs {
  .dashboardCardMain{
    @include flex(row, space-around, flex-start, nowrap);
    overflow: hidden;
  }
}