.supplierCard:hover {
    box-shadow: 0 4px 8px 0 #c9d8e4;
}

.supplierCard {
    @include flex(column, flex-start, flex-start, nowrap);
    @include boxSizing(border-box);
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    width: 100%;
    max-width: 225px;
    // margin: 1rem 0;
    position: relative;
    height: 100%;

    .content {
        padding: 1rem 0;
        width: 100%;
    }
}

.supplierCard:last-of-type {
    margin-right: 0;
}

.supplier-card-container {
    @include flex(column, flex-start, flex-start, nowrap);
    max-width: 225px;
    max-height: 32rem;
    overflow: hidden;
    @include boxSizing(border-box);
    padding: 2rem 1rem;

    .header {
        width: 100%;
        height: 12.5%;
        @include boxSizing(border-box);
    }

    .projectTitle {
        margin-top: 1rem;
        color: map-get($colour-palette, open-brand-primary);
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;

        &.alertRed {
            color: map-get($colour-palette, open-high-risk);
            margin-top: 0;
        }

        &:hover {
            -webkit-line-clamp: unset;
            text-overflow: unset;
        }
    }

    .rtm {
        margin-top: 1rem;
        overflow-wrap: anywhere;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }

    .contentItem {
        @include flex(column, space-between, space-between, nowrap);
        width: 100%;
        padding-bottom: 1rem;

        .itemLabel {
            width: 100%;
            word-break: break-word;
            font-family: 'Roboto', sans-serif;
        }

        .itemValue {
            width: 100%;
            color: map-get($colour-palette, open-brand-primary);
            overflow-wrap: anywhere;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            font-family: montserrat, sans-serif;
        }
    }

    .btnFooter {
        @include flex(column, flex-start, center, nowrap);
        width: calc(100% - 2rem);
        flex: 1;
        vertical-align: baseline;
        margin-top: 2rem;
        position: absolute;
        bottom: 2rem;
    }

    .itemValue.ellipsis-on-unhover {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        word-break: break-word;

        &:hover {
            -webkit-line-clamp: unset;
            text-overflow: unset;
        }
    }
}