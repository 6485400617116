#profileFormContainer {
    background: map-get($colour-palette, open-white);
    border-radius: 0 10px 10px 0;

    @media (max-width: $tablet-width) {
        width: 100%;
    }

    @media (max-width: $mobile-width) {
        border-radius: 0 0 10px 10px;
    }

    // p {
    //     color: map-get($colour-palette, open-brand-primary);
    // }

    @media (max-width: $tablet-width) {
        padding: 2rem 3rem;
    }

    @media (max-width: $mobile-width) {
        padding: 2rem 1rem;
    }

    #profileContent {
        // min-width: 750px;
        width: 100%;
    }

    #profileForm {
        @include flex(column, flex-start, flex-start, wrap);
        // margin-top: 2rem;

        #organisationalInformation {
            .form-label {
                width: 210px;
                margin-top: 1rem;
            }

            #orgField {
                width: 470px;
            }
        }

        #personalInformation,
        .two-inputs-row {
            width: 100%;

            & .form-label.title {
                width: 210px;
                margin-top: 1rem;
            }
        }

        .two-inputs-row input {
            width: 155px
        }

        #companyInformation {
            width: 100%;
        }

        #specialPrivileges {
            .form-label {
                width: 300px;
                margin-top: 1rem;
            }

            .labelled-select {
                @include flex(row, flex-start, flex-start, nowrap);
            }
        }

        #supplierAdminSection {
            width: 100%;
        }

        #departmentSectionContent {
            .textIconContainer {
                @include flex(row, flex-start, flex-start, flex-start);

                #addDepartment {
                    margin-top: 1.8rem;
                }

                @media (max-width: $mobile-width) {
                    @include flex(row, flex-start, baseline, baseline);
                }
            }
        }

        #departmentSectionContent,
        #supplyRegionSectionContent,
        #codesCategorySectionContent {
            width: 100%;

            .textIconContainer {
                @include flex(row, flex-start, flex-start, flex-start);

                @media (max-width: $mobile-width) {
                    @include flex(row, flex-start, baseline, baseline);
                }
            }
        }

        // .form-label {
        //     // max-width: 250px;
        //     width: 250px;
        //     margin-top: 1rem;
        //   }

        .form-input {
            max-width: 250px;
            // width: 100%;
        }

        .labelled-input {
            @include flex(row, flex-start, flex-start, nowrap);
            width: 100%;
        }

        #phoneNo,
        .two-inputs-row {
            @include flex(row, flex-start, flex-start, nowrap);

            .labelled-select {
                @include flex(row, flex-start, flex-start, nowrap);
                // min-width: 500px;
                margin-right: 0.5rem;
                width: 40%;
            }

            #countryCode-select,
            select {
                width: 90px;
                font-size: 1.4rem;
                padding: 0 5rem 0 0.5rem;
            }

            & input {
                width: 155px;
            }
        }

        #countrySelect {

            // @include flex(row, flex-start, flex-start, nowrap);
            .labelled-select {
                @include flex(row, flex-start, flex-start, nowrap);
            }

            #country-select {
                font-size: 1.4rem;
                padding: 0 5rem 0 0.5rem;
            }
        }

        .labelled-radio {
            @include flex(row, flex-start, flex-start, nowrap);

            label {
                width: 100%;
                margin-right: 1rem;
            }
        }

        &>.formInputContainer:nth-child(odd) {
            margin-right: 4rem;

            @media (max-width: $tablet-width) {
                margin-right: 0;
            }
        }

        .formInputContainer {
            @include flex(row, space-between, flex-start, nowrap);

            @media (max-width: $tablet-width) {
                width: 100%;

                .labelled-input,
                .labelled-textarea {
                    width: 100%;
                }
            }

            &>#char-count {
                align-self: flex-end;
            }
        }

        .formButtonContainer {
            @include flex(row, flex-start, flex-end, nowrap);
            width: 100%;
        }

        .twoActionBtnContainer {
            margin-top: 2em;
        }
    }

    #addDepartment,
    #addCodesCategoryButton,
    #addSupplyRegionButton {
        margin-top: 1.8rem;

        .labelled-input {
            @include flex(column, flex-start, flex-start, wrap);
        }
    }
}