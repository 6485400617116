.contractOverviewContainer {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;
  .leftcontractOverviewContainer {
    @include flex(column, space-between, stretch, wrap);
    width: 33%;
  }
  .dashboardCard {
    .dashboardCardMain {
      .logItem {
        padding-bottom: 1rem;
        border-bottom: 1px solid map-get($colour-palette, open-divider);

        .headerTitle {
          color: #2c4874;
          margin-right: 2rem;
          margin-bottom: 1px;
        }
      }
    }
  }

  #worknotesButton {
    width: 65%;
    margin-bottom: 2rem;
  }

  div.small,
  div.medium {
    height: 400px;
  }

  .dashboardHeaderText {
    word-break: break-word;
  }

  #contractEscalations {
    .escalationItem {
      @include flex(column, space-between, nowrap);
      margin-top: 2rem;
    }

    #escalationBtnContainer {
      @include flex(row, space-between, center, nowrap);
      margin-top: 0.5rem;
    }
  }
  #workflowEvent {
    height: auto;
  }
  #publishedNotices {
    .noticeItem {
      margin-top: 2rem;

      #noticeBtnContainer {
        @include flex(row, space-between, center, nowrap);
        margin-top: 0.5rem;

        button:first-of-type {
          width: 102px;
        }
      }
    }
  }

  #projectActionContainer {
    @include flex(row, flex-end, center, nowrap);
    width: 100%;

    @media (max-width: $mobile-width) {
      @include flex(column, space-between, center, nowrap);
    }

    .cancelButtonContainer {
      @include flex(row, flex-start, center, nowrap);

      @media (max-width: $mobile-width) {
        @include flex(row, space-between, space-between, nowrap);
        padding-bottom: 1rem;
      }

      .statuslabel-content {
        margin-right: 1rem;
      }
    }

    .cancelButtonContainer > *:not(:last-child) {
      margin-right: 1rem;
    }

    .moreActionContainer {
      @include flex(row, flex-end, center, nowrap);
      width: 100%;
      button {
        margin-left: 3rem;
      }
      @media (max-width: $mobile-width) {
        @include flex(row, space-between, space-between, nowrap);
        padding-top: 1rem;
        button {
          margin-left: 0;
        }
      }
    }
  }

  #projectActionContainer > *:not(:last-child) {
    margin-right: 1rem;
  }
}

#pastCurrentUpcomingProgressBar {
  margin-bottom: 3rem;
}

.contractActionContainer {
  @include flex(row, space-around, stretch, nowrap);
  width: 100%;

  .actionStats {
    //@include flex(column, space-around, center, nowrap);
    width: 100%;
    .actionStatsItem {
      margin: 3rem 0;
      text-align: center;

      p:last-of-type {
        font-size: 4rem;
        color: map-get($colour-palette, open-green);
      }
    }
  }
  .actionButtons {
    @include flex(column, space-around, center, nowrap);
    width: 100%;
    .button {
      height: 40px;
      width: 320px;
      left: 0px;
      top: 0px;
      border-radius: 5px;
      margin: 0rem;
      margin-top: 1.5rem;
    }
  }
}

#extentForm {
  .modal-container .modal.medium {
    width: 100%;
    max-width: 525px;
    height: 100%;
    max-height: 650px;

    .sectionRow {
      @include flex(row, flex-start, flex-start, nowrap);
      margin-top: 2rem;

      &:nth-child(3) {
        padding-bottom: 2.5rem;
        border-bottom: 1px solid map-get($colour-palette, open-divider);
      }

      @media (max-width: $mobile-width) {
        flex-wrap: wrap;
        p {
          width: 100%;
          margin-left: 0;

          &:first-of-type {
            margin-bottom: 1rem;
          }
        }
      }

      .sectionLabel {
        width: 100%;
        @media (min-width: $laptop-width) {
          max-width: 300px;
        }

        @media (min-width: $mobile-width) {
          max-width: 280px;
        }
      }

      .sectionValue {
        word-break: break-word;
        color: map-get($colour-palette, open-text-primary);
      }
    }
  }
}

#escalationModal {
  .escalationReason {
    margin-top: 1rem;
  }
}

#addToSteeringGroupBtn {
  width: auto;
}

#actions {
  #contractActionContent {
    #worknoteContent > *:not(:last-child) {
      margin-bottom: 1rem;
    }

    #correspondenceContent > *:not(:last-child) {
      margin-bottom: 1rem;
    }

    #worknoteContent {
      margin-top: 2rem;
      margin-bottom: 2rem;
    }

    #correspondenceContent {
      margin-top: 2rem;

      &> *:not(:last-child) {
        margin-bottom: 1rem;
      }

      &.unread {
        border-radius: 5px;
        padding: 1rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-error);
      }
    }

    .upFormInputContainer {
      width: 100%;
    }
  }
}
