#userNavMenuContainer {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    height: 100%;
    z-index: 1;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    border-radius: 5px;
    background-color: map-get($colour-palette, open-white);

    .userNavMenuItem {
        @include flex(row, flex-start, center, nowrap);
        width: 100%;
        @include boxSizing(border-box);

        &:hover {
            background-color: map-get($colour-palette, open-body-grey);
        }

        &:first-of-type {
            border-radius: 5px 5px 0 0;
        }

        &:last-of-type {
            border-radius: 0 0 5px 5px;
        }

        a {
            display: flex;
            flex: 1;
            align-items: center;
            height: 50px;
            padding: 0 1.5rem;
            cursor: pointer;
            text-decoration: none;
            color: map-get($colour-palette, open-text-primary);
        }
    }
}
