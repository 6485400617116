#answerSummaryContainer {
    @include flex(row, space-around, stretch, wrap);
    width: 100%;
    #answerSummaryBanner {
        width: 100%;
        padding-bottom: 2rem;
    }

    .answerSummaryContentContainer {
        width: 100%;

        .showText {
            div:first-child {
                h4 {
                    margin-bottom: 28px;
                }
            }

            div:nth-child(2) {
                p {
                    margin-bottom: 28px;
                }
            }
        }

        .answerSummaryContent {
            width: 100%;
            .answerSummaryContentSection {
                @include flex(column, center, center, wrap);
                background-color: white;
                width: 100%;
                border-radius: 10px;
                border-radius: 10px;
                .answerSummaryLotTitle {
                    width: 98%;
                    padding: 1rem;
                }
                .answerSummaryContentBody {
                    @include flex(row, space-evenly, center, nowrap);
                    background-color: white;
                    border-bottom: 1px solid lightgray;
                    width: 98%;
                    padding: 1rem;
                    .answerSummaryQuestionTitle {
                        width: 70%;
                        padding: 2rem;
                        font-weight: 700;
                    }
                    .answerSummaryBtnGroup {
                        @include flex(row, flex-end, center, nowrap);
                        width: 25%;
                        padding: 1rem;
                        margin-right: 1rem;

                        @media (max-width: $mobile-width) {
                            width: auto;
                            @include flex(column, flex-end, center, wrap);
                        }

                        .btnSize {
                            height: 30px;
                            width: 35%;
                            @media (max-width: $mobile-width) {
                                width: auto;
                                margin-top: 1rem;
                            }
                        }

                        #questionStatus-statuslabel {
                            margin-right: 1rem;
                        }
                    }
                }
            }

            .answerSummaryActionsContainer {
                @include flex(row, space-between, center, wrap);
                background-color: white;
                margin-bottom: 2rem;
                padding: 2rem;
                border-radius: 10px;

                @media (max-width: $mobile-width) {
                    @include flex(column, flex-start, center, wrap);

                    button {
                        margin-top: 1rem;
                        height: 30px;
                        max-width: 120px;
                        font-size: 1.6rem;
                    }
                }

                .answerSummaryBtnContainer {
                    @include flex(row, flex-end, center, nowrap);

                    button {
                        margin-left: 1rem;
                    }

                    @media (max-width: $mobile-width) {
                        @include flex(row, center, center, wrap);

                        .guidanceInfo {
                            text-align: center;
                        }

                        button {
                            height: 30px;
                            max-width: 120px;
                            font-size: 1.6rem;
                        }
    
                        *{
                            margin-top: 1rem;
                            margin-left: 0;
                        }
                    }

                    .answerSummaryAdditionalBtnContainer {
                        @include flex(row, flex-end, center, nowrap);
                    }
                }
            }
        }
    }
    #answerSummaryNoLots {
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;
        align-items: center;
        height: 500px;
        width: 100%;

        @media (max-width: $mobile-width) {
            height: 300px;
        }
    }

    #lotButton {
        padding: 1rem;
    }

    #accordionContentBody {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        align-items: center;
        border-bottom: 1px solid lightgray;

        .answerSummaryBtnGroup {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: center;

            @media (max-width: $mobile-width) {
                @include flex(column, flex-end, center, wrap);
                width: auto;
            }

            .btnSize {
                height: 30px;
                width: 120px;
                margin-left: 1rem;
                @media (max-width: $mobile-width) {
                    width: auto;
                    margin-top: 1rem;
                    margin-left: 0rem;
                }
            }
        }
        .answerSummaryQuestionTitle{
            .title {
                @media (max-width: $mobile-width) {
                    word-break: break-word;
                }
            }
        }
    }
    #lotAccordion {
        .accordion-section {
            margin-bottom: 2rem;
        }
        .accordion-section-header-data {
            border-bottom: 1px solid lightgray;
            padding: 2rem 1rem 2rem 1rem;
        }
        .answerSummaryQuestionTitle {
            .title {
                @include flex(row, center, center, nowrap);
            }
        }
    }
    #share-icon {
        height: 20px;
        width: 20px;
        margin-right: 1rem;
    }
}
