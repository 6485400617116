.rbc-calendar {
    width: 100%;
    min-height: 800px;
    height: 100%;
    @include boxSizing(border-box);
    padding: 4rem;
    background-color: map-get($colour-palette, open-white);
    font-family: NotoSans-Medium;
    font-size: 1.6rem;
    font-style: normal;
    color: map-get($colour-palette, open-text-primary);
    border-radius: 10px;

    @media (max-width: $tablet-width) {
        padding: 3rem;
        font-size: 1.4rem;
        min-height: 700px;
    }
    @media (max-width: $mobile-width) {
        padding: 0.5rem;
        min-height: 600px;
    }
    .rbc-date-cell {
        padding-top: 1rem;
        padding-right: 1rem;
    }
    .rbc-timeslot-group {
        border: 1px solid map-get($colour-palette, open-footer-border);
    }
    .rbc-day-bg {
        border-left: 1px solid map-get($colour-palette, open-footer-border);
        border-bottom: 1px solid map-get($colour-palette, open-footer-border);
        border-right: 1px solid map-get($colour-palette, open-footer-border);
    }
    .rbc-month-view {
        margin-top: 1rem;
    }
    .rbc-month-row {
        z-index: 1;
    }
    .rbc-header {
        border: 1px solid map-get($colour-palette, open-footer-border);
        padding: 1rem;
        font-family: NotoSans-Light;
        span {
            font-size: 1.8rem;
        }
    }
    .rbc-row.rbc-time-header-cell .rbc-header {
        padding-bottom: 3rem;
    }
    .rbc-row-segment {
        margin: 0.5rem 0;
    }
    .rbc-toolbar {
        &-btn-group {
            @media (max-width: $tablet-width) {
                width: 100%;
            }
            @media (max-width: $mobile-width) {
                width: 100%;
            }
        }
        &-label {
            font-family: NotoSans-Medium;
            font-size: 2rem;
            padding: 1rem;
            @media (max-width: $tablet-width) {
                width: 100%;
            }
            @media (max-width: $mobile-width) {
                width: 100%;
            }
        }
        button {
            width: 75px;
            padding: 0.5rem;
            color: map-get($colour-palette, open-text-primary);
            border: 1.5px solid map-get($colour-palette, open-green);
            font-family: NotoSans-Medium;
            font-size: 1.8rem;
        }
        button.rbc-active {
            background-color: map-get($colour-palette, open-green);
            color: map-get($colour-palette, open-white);
            border: 1.5px solid map-get($colour-palette, open-green);
        }
    }
    .rbc-event {
        background-color: map-get($colour-palette, open-green);
        border-left: 2px solid map-get($colour-palette, open-white);
    }
    .rbc-today {
        background-color: map-get($colour-palette, open-green-light);
    }
    .rbc-event-content {
        font-size: 1.6rem;
        font-family: NotoSans-Regular;

        @media (max-width: $tablet-width) {
            font-size: 1.4rem;
        }
    }
    .rbc-show-more {
        font-size: 1.6rem;
        font-family: NotoSans-Regular;
        background-color: transparent;

        @media (max-width: $tablet-width) {
            font-size: 1.4rem;
        }
    }
    .rbc-day-slot .rbc-event-label {
        font-family: NotoSans-Regular;
    }
    .rbc-day-slot .rbc-background-event {
        color: map-get($colour-palette, open-white);
        background-color: map-get($colour-palette, open-green);
        border-radius: 3px;
    }
}
