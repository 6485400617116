.recipients-container {
  margin-top: 10px;

  .recipients {
      margin-top: 10px;
      margin-bottom: 15px;
  }

  #seeMoreSeeLess .caption {
      font-family: montserrat, sans-serif;
      font-size: 1.2rem;
      font-style: italic;
      color: #999;
  }
}