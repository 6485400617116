#overViewData {
    #viewPublishNoticesSection{
        .itemContainer{
            .vpnItemButtonGroup{
                display: flex;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}

#submit{
    margin-left: 5rem;
}