// #workFlowEventsAccordion {
//     width: 100%;
//     max-width: 750px;
//     border-radius: 10px;

//     .accordion-section {
//       margin-bottom: 0;
//       border: 1px solid map-get($colour-palette, open-green);

//       &:only-of-type {
//         border-radius: 10px;
//       }

//       &:not(:only-of-type):first-of-type {
//         border-radius: 10px 10px 0 0;

//         .accordion-section-header {
//           border-radius: 10px 10px 0 0;
//           border-bottom: 0;
//         }
//       }

//       &:not(:only-of-type):last-of-type {
//         border-radius: 0 0 10px 10px;
//         border-top: 0;

//         .accordion-section-header {
//           border-top: 0;
//           border-radius: 0 0 10px 10px;
//         }
//       }

//       &:not(:first-of-type):not(:last-of-type) {
//         border-top: 0;
//       }

//       @include boxSizing(border-box);

//       .accordion-section-header {
//         padding: 1rem 2rem;

//         &:only-of-type {
//           border-radius: 10px;
//         }

//         .accordion-section-header-data {
//           pointer-events: none;
//           .contentOpen {
//             margin-right: 1rem;
//             border-radius: 5px 5px 0 0;
//           }

//           @media (max-width: $mobile-width) {
//             margin: 1rem 0;

//             .content {
//               margin-right: 1rem;
//               margin-bottom: 0.5rem;
//             }
//           }
//         }

//         img {
//           padding: 0;
//         }

//         #bdAccordionSectionHeaderAddTag {
//           @include flex(row, center, center, nowrap);
//           border-radius: 5rem;
//           margin-right: 2.5rem;
//           width: 150px;
//           height: 25px;

//           &.overdue {
//             color: map-get($colour-palette, open-red);
//             border: 1px solid map-get($colour-palette, open-red);
//           }
//           &.in-progress,
//           &.starts {
//             color: map-get($colour-palette, open-text-grey);
//             border: 1px solid map-get($colour-palette, open-text-grey);
//           }

//           &.completed-early,
//           &.completed-ontime {
//             color: map-get($colour-palette, open-text-success);
//             border: 1px solid map-get($colour-palette, open-text-success);
//           }
//           &.completed-late {
//             color: map-get($colour-palette, open-text-error);
//             border: 1px solid map-get($colour-palette, open-text-error);
//           }
//         }
//       }

//       .open {
//         border-top: 1px solid map-get($colour-palette, open-brand-primary);
//         border-radius: 0 0 5px 5px;
//         overflow-y: auto;
//         max-height: 158px !important;
//       }

//       .accordion-section-content {
//         &:first-of-type {
//           border-radius: 10px 10px 0 0;
//         }

//         &:last-of-type {
//           border-radius: 0 0 10px 10px;
//         }

//         .accordion-section-content-data {
//           @include flex(column, flex-start, flex-start, nowrap);
//           padding-top: 1rem;

//           #faqContent {
//             padding: 0 2rem 1rem;
//           }

//           .row {
//             @include flex(row, flex-start, flex-start, wrap);
//             padding-left: 2rem;
//             margin-bottom: 1rem;
//             width: 100%;
//             @include boxSizing(border-box);

//             .labelData {
//               margin-right: 5rem;

//               @media (max-width: $tablet-width) {
//                 margin-right: 3rem;
//               }
//             }
//           }

//           .eventItem {
//             @include flex(row, flex-end, center, nowrap);
//             width: 100%;

//             @media (max-width: $mobile-width) {
//               @include flex(column, center, center, nowrap);
//             }
//             .eventBtnContainer {
//               @include flex(row, flex-end, center, nowrap);
//               margin-right: 1.5rem;
//               width: 100%;

//               .button {
//                 max-width: 120px;
//                 width: 120px;
//                 height: 30px;
//                 font-size: 1.4rem;
//                 margin-bottom: 2rem;
//               }

//               @media (max-width: $mobile-width) {
//                 @include flex(column, space-between, center, nowrap);
//                 margin-right: 1.5rem;
//               }
//               .button:last-of-type {
//                 margin-left: 1rem;

//                 @media (max-width: $mobile-width) {
//                   margin-left: 0;
//                 }
//               }
//             }
//           }

//           .currentItem {
//             @include flex(row, flex-end, center, nowrap);
//             width: 100%;
//             margin-bottom: 1rem;

//             @media (max-width: $mobile-width) {
//               @include flex(column, center, flex-start, nowrap);
//             }
//             .currentBtnContainer {
//               @include flex(row, space-between, center, nowrap);
//               margin-right: 1.5rem;
//               width: 100%;

//               @media (max-width: $mobile-width) {
//                 @include flex(column, space-between, center, nowrap);
//               }

//               .currentEscalateWrapper {
//                 @include flex(row, flex-start, flex-start, nowrap);

//                 @media (max-width: $mobile-width) {
//                   margin-left: 0;
//                   margin-right: 1rem;
//                 }
//               }

//               .currentEventWrapper {
//                 @include flex(row, flex-start, flex-start, nowrap);

//                 @media (max-width: $mobile-width) {
//                   @include flex(column, flex-start, flex-start, nowrap);
//                 }

//                 .button:first-of-type {
//                   margin-right: 1rem;
//                 }

//                 @media (max-width: $mobile-width) {
//                   margin-left: 0;
//                 }
//               }

//               .button {
//                 max-width: 120px;
//                 width: 120px;
//                 height: 30px;
//                 font-size: 1.4rem;
//                 margin-bottom: 1rem;
//               }

//               @media (max-width: $mobile-width) {
//                 @include flex(column, space-between, center, nowrap);
//                 margin-right: 1.5rem;
//               }
//               .button:last-of-type {
//                 @media (max-width: $mobile-width) {
//                   margin-left: 0;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//     .SectionContentRow {
//       @include flex(row, space-between, flex-start, wrap);
//       width: 100%;

//       .sectionData {
//         @include flex(row, flex-start, flex-start, wrap);
//         padding: 1rem 2rem;

//         .label {
//           margin-right: 1rem;
//         }

//         @media (max-width: $tablet-width) {
//           padding: 1rem;
//         }

//         @media (max-width: $mobile-width) {
//           padding: 0.5rem;

//           p {
//             padding: 0.5rem;
//           }

//           .label {
//             margin-right: 0;
//             width: 100%;
//           }
//         }
//       }
//     }

//     .button {
//       margin: 1rem 0 1rem 0;
//       width: fit-content;
//       width: -moz-fit-content;
//     }
//   }

//   .wide-button {
//     width: 200px;
//   }
.noWorkflowSelected {
  width: 100%;
  height: 100%;
  @include flex(column, center, center, nowrap);
  #selectWorkflowBtn {
    margin-top: 2rem;
  }
}
