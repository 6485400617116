#guidanceBanner-guidanceBannerJoinAccount {
    height: fit-content;
    margin-bottom: 2rem;
    .guidanceBannerContent {
        min-height: min-content;
        .showText {
            width: 100%;
        }
    }
}
