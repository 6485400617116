.progress-bar {
    @include flex(row, center, center, nowrap);
    width: 100%;
    ul {
        @include flex(row, center, flex-start, nowrap);
        max-width: 640px;
        width: 100%;
        li {
            max-width: 128px;
            width: 100%;
        }
    }
    p {
        padding-top: 1rem;
        width: 100%;
        text-align: center;
    }
    .progress-bar-item {
        @include flex(row, center, center, nowrap);
    }
    .progress-bar-items-list > li:first-of-type {
        .progress-bar-item {
            .before {
                visibility: hidden;
            }
        }
    }
    .progress-bar-items-list > li:last-of-type {
        .progress-bar-item {
            .after {
                visibility: hidden;
            }
        }
    }
    .progress-circle {
        @include flex(column, center, center, nowrap);
        width: 48px;
        height: 48px;
        background-color: map-get($colour-palette, open-green);
        border-radius: 24px;
        flex-shrink: 0;

        &.pending {
            background-color: map-get($colour-palette, open-footer-border);
        }
    }
    .progress-inner-circle {
        @include flex(column, center, center, nowrap);
        width: 38px;
        height: 38px;
        border-radius: 19px;
        background: map-get($colour-palette, open-body-grey);
        border: 1px solid map-get($colour-palette, open-white);
        top: 4px;
        left: 4px;

        .active-box {
            text-align: center;
            padding: 4px;
            img {
                height: 25px;
            }
        }

        &.past {
            background-color: map-get($colour-palette, open-green);
        }

        &.current {
            background-color: map-get($colour-palette, open-body-grey);
        }
    }

    .before,
    .after {
        width: 50%;
        height: 4px;

        &.past {
            background-color: map-get($colour-palette, open-green);
        }

        &.current {
            background-color: map-get($colour-palette, open-green);
        }

        &.pending {
            background-color: map-get($colour-palette, open-footer-border);
        }
    }
}
