@import 'cssReset.scss';
@import 'variables.scss';
@import 'mixins.scss';

@include fontFace('NotoSans-Regular', 'NotoSans-Regular');
@include fontFace('NotoSans-Light', 'NotoSans-Light');
@include fontFace('NotoSans-Medium', 'NotoSans-Medium');
@include fontFace('NotoSans-SemiBold', 'NotoSans-SemiBold');
@include fontFace('NotoSans-Bold', 'NotoSans-Bold');

@import 'typeScale.scss';
@import 'genericStyles.scss';
@import 'printing.scss';

// Components
@import '../../components/Modal/index.scss';
@import '../../components/ScreenOverlay/index.scss';
@import '../../components/Footer/index.scss';
@import '../../components/Card/index.scss';
@import '../../components/Button/index.scss';
@import '../../components/SideDrawer/index.scss';
@import '../../components/Form/index.scss';
@import '../../components/LabelledInput/index.scss';
@import '../../components/LabelledSelect/index.scss';
@import '../../components/LabelledRadioButton/index.scss';
@import '../../components/LabelledTextArea/index.scss';
@import '../../components/Form/index.scss';
@import '../../components/Tag/index.scss';
@import '../../components/FilterPanel/index.scss';
@import '../../components/Tabs/index.scss';
@import '../../components/OverviewSection/index.scss';
@import '../../components/LabelledRadioButton/index.scss';
@import '../../components/LabelledCheckBox/index.scss';
@import '../../components/ListedCheckBox/index.scss';
@import '../../components/StatusLabel/index.scss';
@import '../../components/Table/index.scss';
@import '../../components/DashboardCard/index.scss';
@import '../../components/Panel/index.scss';
@import '../../components/Alerts/index.scss';
@import '../../components/GuidanceBanner/index.scss';
@import '../../components/AnswerSummaryModal/index.scss';
@import '../../components/HomepageBanner/index.scss';

// Features
@import '../../features/Notifications/index.scss';
@import '../../features/Notifications/components//NotificationEventRow/index.scss';
@import '../../features/Notifications/components//NotificationToggleButton/index.scss';
@import '../../features/StandstillTimer/index.scss';
@import '../../features/Accordion/components/AccordionSection/index.scss';
@import '../../features/Accordion/index.scss';
@import '../../features/BurgerMenu/components/ToggleButton/index.scss';
@import '../../features/BurgerMenu/index.scss';
@import '../../features/NavBar/MenuItem/index.scss';
@import '../../features/NavBar/index.scss';
@import '../../features/Login/index.scss';
@import '../../features/ContactForm/index.scss';
@import '../../features/ContactForm/components/ContactDrawer/index.scss';
@import '../../features/WorknotesModal/index.scss';
@import '../../features/Breadcrumbs/index.scss';
@import '../../features/SearchBar/index.scss';
@import '../../features/SortDropdown/index.scss';
@import '../../features/Pagination/index.scss';
@import '../../features/BackToTop/index.scss';
@import '../../features/BrowseFilterPanel/index.scss';
@import '../../features/Calendar/index.scss';
@import '../../features/ViewEventPanel/index.scss';
@import '../../features/NewMeetingInviteModal/index.scss';
@import '../../features/BottomNavigation/index.scss';
@import '../../features/BottomNavigation/components/BottomNavigationItem/index.scss';
@import '../../features/UserNavMenu/index.scss';
@import '../../features/UserNavMenu/components/UserNavMenuToggleButton/index.scss';
@import '../../features/BrowseData/index.scss';
@import '../../features/SupplierRegistration/index.scss';
@import '../../features/ProgressBar/index.scss';
@import '../../features/PageHeader/index.scss';
@import '../../features/MyProfileForm/index.scss';
@import '../../features/DocumentBuilder/index.scss';
@import '../../features/DocumentBuilder/components/QuestionModal/index.scss';
@import '../../features/DocumentBuilder/components/EventModal/index.scss';
@import '../../features/DocumentBuilder/components/SectionDetailsModal/index.scss';
@import '../../features/DocumentBuilder/components/VersionHistory/index.scss';
@import '../../features/ProcurementRequestForm/index.scss';
@import '../../features/DocumentsModal/DocumentItem/index.scss';
@import '../../features/DocumentsModal/index.scss';
@import '../../features/CreateProcurementForm/index.scss';
@import '../../features/SummaryBuyer/index.scss';
@import '../../features/Upload/index.scss';
@import '../../features/UploadModal/index.scss';
@import '../../features/UploadContractModal/index.scss';
@import '../../features/UploadModal/UploadItem/index.scss';
@import '../../features/ContractForm/index.scss';
@import '../../features/RebateDetailsForm/index.scss';
@import '../../features/Messenger/index.scss';
@import '../../features/ViewWorkflowEventPanel/index.scss';
@import '../../features/NoticeForm/index.scss';
@import '../../features/OpportunityOverview/index.scss';
@import '../../features/LotForm/index.scss';
@import '../../features/CountdownTimer/index.scss';
@import '../../features/CollaborateUsersModal/index.scss';
@import '../../features/Correspondence/BrowseCorrespondence/index.scss';
@import '../../features/Correspondence/ViewCorrespondence/index.scss';
@import '../../features/CodesModal/index.scss';
@import '../../features/ShowRecipientsModal/index.scss';

// Screens
@import '../../screens/Homepage/index.scss';
@import '../../screens/Dashboard/index.scss';
// Don't remove the old op overview stypes - they impact other pages using the jsx generator
@import '../../screens/DefunctOpportunityOverview/index.scss';
@import '../../screens/ProcurementRequestOverview/index.scss';
@import '../../screens/MyProfile/index.scss';
@import '../../screens/SupplierDashboard/components/index.scss';
@import '../../screens/CreateProcurementProject/index.scss';
@import '../../screens/LocalAuthorityDashboard/index.scss';
@import '../../screens/SelectSteeringGroup/index.scss';
@import '../../screens/ContractOverview/index.scss';
@import '../../screens/ContractOverview/components/ViewContract/index.scss';
@import '../../screens/ContractOverview/components/ContractDocuments/index.scss';
@import '../../screens/ContractOverview/components/ContractManagementDocuments/index.scss';
@import '../../screens/ContractOverview/components/SteeringGroup/index.scss';
@import '../../screens/ContractOverview/components/Suppliers/index.scss';
@import '../../screens/ContractOverview/components/WorkFlowEvents/index.scss';
@import '../../screens/ContractOverview/components/WorkFlowEvents/components/Events/index.scss';
@import '../../screens/ContractOverview/components/WorkFlowEvents/components/EventSection/index.scss';
@import '../../screens/ProjectOverview/components/LotCard/index.scss';
@import '../../screens/ProjectOverview/index.scss';
@import '../../screens/ProjectOverview/components/LotCard/index.scss';
@import '../../screens/ProjectOverview/components/WorkFlowEvents/index.scss';
@import '../../screens/UserManagement/index.scss';
@import '../../screens/TermsAndConditions/index.scss';
@import '../../screens/PrivacyPolicy/index.scss';
@import '../../screens/EvaluationSummary/index.scss';
@import '../../screens/LoginPage/index.scss';
@import '../../screens/ProjectOverview/components/WorkFlowEvents/components/EventSection/index.scss';
@import '../../screens/ProjectOverview/components//WorkFlowEvents/components/Events/index.scss';
@import '../../screens/ManageLots/index.scss';
@import '../../screens/AnswerSummary/index.scss';
@import '../../screens/SupplierOverview/index.scss';
@import '../../screens/ManageQuestionnaires/index.scss';
@import '../../screens/BrowseQuestionnaires/index.scss';
@import '../../screens/ManageSuppliers/index.scss';
@import '../../screens/ManageSuppliersContract/index.scss';
@import '../../screens/OpportunityAwardSummary/index.scss';
@import '../../screens/SolutionOverview/index.scss';
@import '../../screens/SolutionOverview/SolutionAdminPanel/index.scss';
@import '../../screens/ViewNotice/index.scss';
@import '../../screens/AwardedContractOverview/index.scss';
@import '../../screens/DraftContract/index.scss';
@import '../../screens/CompanyDetails/index.scss';
@import '../../screens/AwardSummary/index.scss';
@import '../../screens/ViewSelectionQuestionniare/index.scss';
@import '../../screens/TermsAndConditions/index.scss';
@import '../../screens/AboutOpen/index.scss';
@import '../../screens/BrowseCleverQuotes/index.scss';


@import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

html,
body {
    width: 100%;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    font-size: 62.5%;
    font-family: NotoSans-Regular;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: map-get($colour-palette, open-base-blue-20);

    scroll-padding-top: 140px;
}

#root {
    height: 100%;
    min-height: 100vh;
    @include flex(column, flex-start, center, nowrap);
    color: map-get($colour-palette, open-text-primary);
    overflow-y: auto;
}
