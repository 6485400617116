#prMainContainer {
    @include flex(column, flex-start, initial, nowrap);
    border: 1px solid map-get($colour-palette, open-content-container-border);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    width: 100%;
    padding: 4rem;

    @media (max-width: $mobile-width) {
        @include flex(column, initial, initial, nowrap);
    }

    .prFormInputContainer {
        max-width: 350px;

        @media (max-width: $tablet-width) {
            max-width: 250px;
            // width: 100%;
        }

        @media (max-width: $mobile-width) {
            max-width: 230px;
            // width: 100%;
        }
        .react-date-picker__inputGroup__input:invalid {
            background: none;
        }
    }

    .formLabel {
        margin-bottom: 1rem;
    }

    .buttonDivider {
        width: 6rem;

        @media (max-width: $tablet-width) {
            width: 3rem;
        }
    }

    .formCaption {
        margin-bottom: 0.5rem;
    }

    .prDocumentButtonContainer {
        @include flex(row, space-between, flex-end, nowrap)
    }

    .prFormSubmitContainer {
        @include flex(row, flex-end, flex-end, nowrap);

        @media (max-width: $mobile-width) {
            @include flex(row, space-between, initial, nowrap)
        }

        .prFormButtonContainer {
            margin: 0 2rem 0 2rem;

            @media (max-width: $mobile-width) {
                margin: 0;
            }
        }
    }

    .prFormContent {
        @include flex(column, flex-start, initial, nowrap)
    }

    .prFormTitle {
        margin-bottom: 2rem;
        color: map-get($colour-palette, open-brand-primary);
        font-family: NotoSans-Medium;
        font-size: 1.8rem;
        line-height: normal;
    
        &-xSmall {
            font-family: NotoSans-Medium;
            font-size: 1.6rem;
            line-height: normal;
        }
    
        &-small {
            font-family: NotoSans-Medium;
            font-size: 1.6rem;
            line-height: normal;
        }
    
        &-large {
            font-family: NotoSans-Medium;
            font-size: 2rem;
            line-height: normal;
        }
    
        &-xLarge {
            font-family: NotoSans-Medium;
            font-size: 2.2rem;
            line-height: normal;
    
            @media (max-width: $mobile-width) {
                font-size: 1.8rem;
            }
        }
    
        @media (max-width: $tablet-width) {
            font-size: 1.6rem;
        }
    
        @media (max-width: $mobile-width) {
            font-size: 1.6rem;
        }
    }

    .prContainerHeader {
        margin-bottom: 2.5rem;
        border-bottom: 1px solid map-get($colour-palette, open-grey);
    }

    .prContainerTitle {
        margin-bottom: 2rem;
        color: map-get($colour-palette, open-brand-primary);
        font-family: NotoSans-Medium;
        font-size: 2rem;
        line-height: normal;
    
        &-xSmall {
            font-family: NotoSans-Medium;
            font-size: 1.6rem;
            line-height: normal;
        }
    
        &-small {
            font-family: NotoSans-Medium;
            font-size: 1.8rem;
            line-height: normal;
        }
    
        &-large {
            font-family: NotoSans-Medium;
            font-size: 2.2rem;
            line-height: normal;
        }
    
        &-xLarge {
            font-family: NotoSans-Medium;
            font-size: 2.4rem;
            line-height: normal;
    
            @media (max-width: $mobile-width) {
                font-size: 2rem;
            }
        }
    
        @media (max-width: $tablet-width) {
            font-size: 1.4rem;
        }
    
        @media (max-width: $mobile-width) {
            font-size: 1.4rem;
        }
        
    }
}