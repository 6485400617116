#supplierOverView {
    @include flex(column, flex-start, unset, nowrap);
    width: 100%;

    .componentsContainer {
        @include flex(row, space-between, stretch, wrap);
        width: 100%;
        #requestOverviewCard {
            .dashboardCardMain {
                overflow: unset;
            }
        }
    }

    .sectionHeader {
        margin-bottom: 0rem;
    }

    .dashboardContainer {
        @include flex(row, space-between, center, wrap);
        width: 100%;
    }

    #supplierActions {
        height: auto;

        #supplierActionContainer {
            @include flex(row, flex-end, center, wrap);
        }
    }

    .sectionContentTags {
        @include flex(row, flex-start, stretch, wrap);
        .tag-content.large {
            margin-left: 2%;
        }
    }

    // #regionalOfficeOrHeadquartersCard {
    //     @include flex(column, space-between, center, wrap);

    //     .dashboardCard {
    //         @include flex(column, flex-start, flex-start, wrap);
    //         height: fit-content;
    //     }
    // }

    .dashboardContainer #actions .uploadContainer {
        margin-top: 0rem;
    }

    #riskStatusButton{
        margin-left: 2rem;
    }

    #creditScoreButton {
        margin-left: 2rem;
    }
    .tag-content.large {
        margin-bottom: 1rem;
    }

    .department {
        margin-left: 2rem;
    }

    .departmentContent {
        @include flex(column, space-between, stretch, wrap);
        width: 99%;
        margin-top: 1rem;
    }

    .accountContent {
        @include flex(column, center, flex-start, nowrap);
        width: 98%;
        margin-top: 1rem;

        .cardContentContainer {
            margin-top: 2rem;
            width: 98.5%;

            .solutionAccessRequestItem {
                border: 1px solid map-get($colour-palette, open-brand-primary);
                border-radius: 5px;
                padding: 1rem;
            }
            .button:first-of-type {
                margin-bottom: 0rem;
                width: auto;
            }
        }
    }
    .departmentRequestItem {
        margin-bottom: 0rem;
    }
    #noDepartmentContent {
        margin: 2rem;
    }

    #bdAccordion {
        .accordion-section {
            margin-bottom: 2rem;
            border: 1px solid map-get($colour-palette, open-brand-primary);
            border-radius: 5px;

            &:last-child {
                margin-bottom: 1rem;
            }

            @media (max-width: $tablet-width) {
                margin-bottom: 1rem;
            }

            &-header {
                border-radius: 5px;
                padding: 0 2rem;

                img {
                    padding: 0;
                }

                @media (max-width: $tablet-width) {
                    padding: 0 1rem;
                }

                &.contentOpen {
                    border-radius: 5px 5px 0 0;
                }

                &-data {
                    pointer-events: none;
                    word-break: break-word;

                    p {
                        padding: 1rem 0;
                    }

                    .headerTitleContent {
                        @include flex(row, center, center, nowrap);

                        .share-icon {
                            height: 20px;
                            width: 20px;
                            margin-right: 1rem;
                        }
                    }

                    .headerTitle {
                        margin-right: 2rem;

                        @media (max-width: $tablet-width) {
                            margin-right: 1rem;
                        }
                    }

                    .bdAccordionSectionHeaderAddon {
                        @include flex(row, center, center, nowrap);

                        .status-label:not(:last-child) {
                            margin-right: 2rem;
                        }

                        .status-label {
                            min-width: 130px;
                            max-width: 250px;
                            background: map-get($colour-palette, open-white);
                            color: map-get($colour-palette, open-white);
                            border: 1px solid map-get($colour-palette, open-brand-primary);
                            border-radius: 50px;
                            &.red {
                                color: map-get($colour-palette, open-red);
                                border: 1px solid map-get($colour-palette, open-red);
                            }

                            &.amber {
                                color: map-get($colour-palette, open-amber);
                                border: 1px solid map-get($colour-palette, open-amber);
                            }

                            &.green {
                                color: map-get($colour-palette, open-green);
                                border: 1px solid map-get($colour-palette, open-green);
                            }

                            &.primary {
                                background: map-get($colour-palette, open-blue-90);
                                border: 1px solid map-get($colour-palette, open-blue-90);
                            }
                        }

                        &.cancelled {
                            background-color: map-get($colour-palette, open-red);
                            border-radius: 5rem;
                            margin: 1rem;
                            width: 135px;
                            height: 25px;
                        }

                        &.projects {
                            background-color: map-get($colour-palette, open-green);
                            border-radius: 5rem;
                            margin: 1rem;
                            width: 135px;
                            height: 25px;
                        }

                        .label {
                            margin-right: 1rem;

                            &.projects {
                                color: map-get($colour-palette, open-white);
                                padding: 0;
                                margin: 0 auto;
                            }
                        }

                        .headerTag {
                            color: map-get($colour-palette, open-grey);
                            background: map-get($colour-palette, open-footer-border);
                            min-width: 120px;
                            border-radius: 50px;
                            font-size: 1.6rem;
                            text-align: center;
                            margin: 1rem 0;

                            &.active {
                                color: map-get($colour-palette, open-white);
                                background: map-get($colour-palette, open-green);
                                padding: 0 10px;
                            }

                            @media (max-width: $mobile-width) {
                                width: 100px;
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }

            &-content {
                &.open {
                    border-top: 1px solid map-get($colour-palette, open-brand-primary);
                    border-radius: 0 0 5px 5px;
                    overflow-y: auto;
                    max-height: 18.1rem;
                }

                &-data {
                    @include flex(column, flex-start, space-evenly, nowrap);
                    @include boxSizing(border-box);

                    .bdAccordionSectionContentData {
                        margin: 2rem 2rem 0rem 2rem;
                        word-break: break-word;
                    }
                }
            }
        }
    }

    .title-small,
    .title-xSmall {
        line-height: 2rem;
    }
}
#supplierActionCard{
    #actions{
        .title{
            margin-bottom: 2rem
            ;
        }
    }
}
