#suggestedOpportunities{
        height: 560px!important;
}
.dashboardContainer {
        .dashboardCard{
                &.medium{
                  &.customStyle{
                    height: 560px;
                  }
                }
                &.small{
                  &.customStyle{
                    height: 560px;
                  }
                }

        }
}