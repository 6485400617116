.coLotItem {
    @include flex(column, center, center, nowrap);
    width: 100%;
    .caption{
        align-self: flex-start;
        font-size: 1.6rem;
        font-weight: bold;
        color:  map-get($colour-palette, open-brand-primary);
    }
    margin: .5rem 0 1rem 0;
}

.coSupplierItem {
    @include flex(column, space-between, space-between, nowrap);
    width: 100%;
    margin-top: .5rem;

    .coSupplierButtonContainer {
        @include flex(row, space-between, space-between, wrap);
        margin-top: 1rem;

        .button {
            height: 30px;
            width: 100px;
            border-radius: 5px;
            font-size: 1.4rem;
        }

        .button:first-of-type {
            width: 110px;
            max-width: 110px;
        }
    }
}