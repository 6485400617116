.pagination {
    @include flex(row, center, center, wrap);
    width: 100%;
    height: 100%;

    .paginationItem {
        button {
            background: none;
            border: none;
            padding: 0.75rem;
            margin: 0.75rem;
            cursor: pointer;
            color: map-get($colour-palette, open-brand-primary);

            @media (max-width: $tablet-width) {
                padding: 0.65rem;
                margin: 0.65rem;
            }

            @media (max-width: $mobile-width) {
                padding: 0.5rem;
                margin: 0.5rem;
            }
        }

        &.disabled > button {
            color: map-get($colour-palette, open-grey);
            cursor: not-allowed;
        }

        .paginationItemNumber {
            color: map-get($colour-palette, open-grey);

            &.active {
                color: map-get($colour-palette, open-brand-primary);
                border-bottom: 2px solid map-get($colour-palette, open-brand-primary);
                padding-bottom: 0.3rem;

                @media (max-width: $mobile-width) {
                    padding-bottom: 0.15rem;
                }
            }
        }

        .paginationItemEllipsis {
            color: map-get($colour-palette, open-grey);
            cursor: text;
        }
    }
}
