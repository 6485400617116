.modal-container {
    @include flex(column, center, center, nowrap);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 4;
    padding: 0 1rem;
    @include boxSizing(border-box);

    .modal {
        @include flex(column, flex-start, flex-start, nowrap);
        border-radius: 10px;
        background-color: map-get($colour-palette, open-white);
        min-width: 250px;

        &-header {
            @include flex(row, center, center, nowrap);
            width: 100%;
            height: 25px;
            border-bottom: 1px solid map-get($colour-palette, open-divider);
            border-radius: 10px 10px 0 0;
            position: relative;
            padding-top: 2rem;
            padding-bottom: 2rem;

            &-title {
                width: 100%;
                text-align: center;

                @media (max-width: $mobile-width) {
                    inline-size: 68%;
                    overflow-wrap: break-word;
                }
            }

            &-close-btn {
                position: absolute;
                right: 0;
                margin-right: 2rem;
                border: none;
                background: none;

                &-img {
                    cursor: pointer;
                    height: 30px;
                }
            }
        }

        &-content {
            flex: 1;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            margin: 2rem 0;
            padding: 0 2rem;
            @include boxSizing(border-box);

            &-title {
                font-size: 16px;
            }

            &-label {
                font-size: 14px;
            }

            input:not(.regSearch, #openLinkInNewWindow, #linkTitle, #linkTarget, #fileUpload-input) {
                width: 100%;
                height: 40px;
                padding: 1rem;
                // border: 1px solid map-get($colour-palette, open-brand-primary);
                @include boxSizing(border-box);
                border-radius: 5px;
            }

            select {
                width: 100%;
                height: 40px;
                max-width: inherit;
                border: 1px solid map-get($colour-palette, open-brand-primary);
                @include boxSizing(border-box);
                border-radius: 5px;
            }
        }

        &-footer {
            width: 100%;
            padding: 1rem 2rem 3rem 2rem;
            @include boxSizing(border-box);
            @include flex(row, space-between, center, wrap);

            @media (max-width: $mobile-width) {
                padding: 1rem 2rem 2rem 2rem;
            }

            #main-only-action-btn {
                width: 100%;
                height: 40px;
            }

            @media (max-width: $mobile-width) {
                padding: 1rem 2rem 2rem 2rem;

                #main-only-action-btn {
                    width: 100%;
                    height: 35px;
                }

                #second-action-btn {
                    width: 120px;
                    height: 35px;
                }

                #main-action-btn {
                    width: 120px;
                    height: 35px;
                }
            }

            #helper-txt-container {
                @include flex(row, center, center, wrap);
                width: 100%;
                margin-top: 1.5rem;

                @media (max-width: $mobile-width) {
                    margin-top: 1rem;
                }

                #help-action-button {
                    width: auto;
                    min-width: auto;
                    height: 100%;
                    color: map-get($colour-palette, open-browser-link-blue);
                    background: none;
                    padding: 0;
                    padding-left: 0.3rem;
                }
            }
        }

        &.small {
            width: 100%;
            max-width: 400px;
            height: 100%;
            max-height: 400px;
        }

        &.small-long {
            width: 100%;
            max-width: 550px;
            height: 100%;
            max-height: 400px;
        }

        // This is for placeholder guidance banner images and should be changed once we receive
        // proper ones from Nepo.
        &.temp-size {
            width: 100%;
            max-width: 640px;
            height: 100%;
            max-height: 540px;
        }

        &.medium {
            width: 100%;
            max-width: 500px;
            height: 100%;
            // min-height: 400px;
            max-height: 650px;

            @media (max-width: $mobile-width) {
                min-height: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }

        &.large {
            height: 500px;
            width: 600px;
            // min-height: 500px;
            max-height: 650px;

            @media (max-width: $mobile-width) {
                min-height: 100%;
                max-height: 100%;
                max-width: 100%;
            }
        }

        &.large-long {
            width: 100%;
            max-width: 550px;
            height: 100%;
            max-height: 750px;
        }

        &.x-large {
            width: 100%;
            max-width: 700px;
            height: 100%;
            max-height: 650px;
        }
    }
}

.modal-container.session-timeout {
    z-index: 106
}