.searchBar {
    width: 100%;
    max-width: 500px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid map-get($colour-palette, open-brand-primary);
    padding: 1rem 1rem 1rem 5rem;
    background-image: url('../../styles/images/searchIcon.svg');
    background-repeat: no-repeat;
    background-position: 1.5rem center;
    background-size: auto 80%;
    display: inline-block;

    @media (max-width: $tablet-width) {
        height: 35px;
        padding-left: 4rem;
        background-position: 1rem center;
    }

    @media (max-width: $mobile-width) {
        height: 30px;
        padding-left: 4rem;
        background-position: 1.5rem center;
    }
}
