.control-viewer {
    display: flex;
    gap: 15px;
    margin-left: auto;
    align-items: center;
    justify-content: flex-end;
}
.diff-container {
    width: 100%;
    margin-top: 2rem;
}
.diff-container h3 {
    color: #2c4874;
    font-size: 2rem;
    margin: 2rem;
    padding-bottom: 1rem;
}
.diff-container > .diff-group  ~ .diff-group  {
    margin-bottom: 2rem;
    font-size: 2rem;
}
.diff-container  td[class$='-title-block'] pre { 
    color: #2c4874;
    font-weight: 600;
}
.diff-container  td[class*='-content'] { 
    width: 45%!important;
}
.diffViewer {
    margin-top: 2rem;
}
.diff-section {
    display: flex;
    align-items: center;
    font-size: 14px;
    gap: 10px;
    border-bottom: 1px solid #ececec;
    min-height: 3rem;
}
.diff-section  p {
    width: 35%;
    font-weight: 600;
    line-height: 1.5;
}
.diff-group {
    border: 1px solid #f1f1f1;
    padding: 2rem;
    border-radius: 10px;
    background: #fff;
    margin-bottom: 2rem
}
.diff-group >  .diff-group {
    border: none;
    padding: 0 2rem;
}
.diff-group >  .diff-group  >  .diff-group{
    border: 1px solid #f1f1f1;
    margin: 2rem -2rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background:#fafafa;
}
.diff-group h2 {
    color: #2c4874;
    font-size: 2rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}
.diff-group > .diff-group > h2 {
    border-top: 1px solid #e2e2e2;
    padding-top: 2rem;
    margin-left: -1rem;
    margin-right: -1rem;
}
.diff-group > .diff-group > .diff-group > h2 {
    border-top: none;
    padding-top:inherit;
    margin-left: 0;
    margin-right:0;
}
.diff-group  > .diff-section:nth-of-type(1)
{
    align-items: flex-end;
    padding-bottom: 0.5rem;
    min-height: 2.5rem;
} 

