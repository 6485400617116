#lotContainer {
    @include flex(row, space-evenly, stretch, wrap);
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
        @include flex(row, center, stretch, wrap);
    }

    .lotCard {
        @include flex(column, flex-start, flex-start, nowrap);
        @include boxSizing(border-box);
        border: 1px solid map-get($colour-palette, open-green);
        border-radius: 10px;
        width: 100%;
        max-width: 225px;
        margin: 1rem 1rem 2rem 0;

        @media (max-width: $tablet-width) {
            margin: 1rem 2rem 2rem 0;
        }

        @media (max-width: $mobile-width) {
            &:last-of-type {
                margin-right: 2rem;
            }
        }

        .content {
            min-height: 200px;
            padding: 1rem 0;
        }
    }
    .lot-card-container {
        @include flex(column, flex-start, flex-start, nowrap);
        max-width: 225px;
        @include boxSizing(border-box);
        padding: 2rem 1rem;
        width: 100%;
        .header {
            width: 100%;
            height: 12.5%;
            @include boxSizing(border-box);
        }
        .projectTitle {
            // margin-top: 1rem;
            color: map-get($colour-palette, open-brand-primary);
            word-break: break-word;
        }
        .projectHeader {
            margin-top: 1rem;
        }
        .contentItem {
            @include flex(column, flex-start, flex-start, nowrap);
            width: 100%;
            word-break: break-word;
        }
        .btnFooter {
            @include flex(column, flex-start, center, nowrap);
            width: 100%;
            flex: 1;
            vertical-align: baseline;
            margin-top: 2rem;
            button {
                width: 120px;
            }
        }
    }
}
