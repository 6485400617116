.tabs {
    margin-bottom: 1.5rem;
    margin-top: 0.5rem;

    @media (max-width: $mobile-width) {
        margin-left: -2rem;
        margin-right: -2rem;
    }
}

.tab-header {
    border-bottom: 1px solid map-get($colour-palette, open-green);
    list-style: none;
    display: table;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0.5rem 0rem;
    min-height: 40px;

    @media (max-width: $mobile-width) {
        min-height: 37px;
    }

    & li {
        display: table-cell;
        padding: 0.2rem 0rem;
        text-align: center;
        vertical-align: middle;
        cursor: pointer;
        border-radius: 0.5rem 0.5rem 0rem 0rem;
        color: map-get($colour-palette, open-text-grey);
        background-color: map-get($colour-palette, open-body-grey);

        @media (max-width: $mobile-width) {
            font-size: 1.2rem;
        }

        &.active {
            color: map-get($colour-palette, open-white);
            background-color: map-get($colour-palette, open-green);
        }
    }
}
