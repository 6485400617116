.timerContainer {
    width: 100%;
    @include flex(row, center, center, nowrap);
    margin-bottom: 1rem;

    @media (max-width: $tablet-width) {
        @include flex(row, center, center, wrap);
    }
    .timerTile {
        background-color: map-get($colour-palette, open-white);
        padding: 2rem;
        margin: 1rem;
        color: map-get($colour-palette, open-brand-primary);
        text-align: center;
        &.redTile{
            color:map-get($colour-palette, open-red);
        }
        &.amberTile{
            color: map-get($colour-palette, open-amber);
        }
        &.content {
            white-space: nowrap;

            @media (max-width: $tablet-width) {
                padding: 2rem;
            }
        }
    }
}

#red {
    color:map-get($colour-palette, open-red);
}
#amber{
    color:map-get($colour-palette, open-amber);
}
// .timerHeaderContainer{
//     @include flex(column,center,center,nowrap);
//       .heading{
//         text-decoration: underline;
//         color: map-get($colour-palette, open-brand-primary);
//        }

//     .timerDisplay{
//        display:flex;
//        flex-direction: row;

//        .timerTile{
//         background-color: white;
//         padding: 1%;
//         margin: 1rem;
//         color: map-get($colour-palette, open-brand-primary);
//         text-align: center;
//         white-space: nowrap;
//     }
// }
// }
