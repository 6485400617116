#pageHeader {
    @include flex(column, center, flex-start, nowrap);
    //background: map-get($colour-palette, open-brand-primary);
    background-image: radial-gradient(circle at center, #20b3aa -100%, #1f3765);
    border-radius: 10px;
    min-height: 100px;
    max-height: 100px;
    color: map-get($colour-palette, open-white);
    padding: 2rem 4rem;
    @include boxSizing(border-box);
    width: 100%;
    margin: 3rem 0;
    
    
    @media (max-width: $tablet-width) {
        min-height: 120px;
        margin: 2rem 0;
        padding: 2rem 3rem;
    }

    @media (max-width: $mobile-width) {
        min-height: 100px;
        margin: 1rem 0;
        padding: 2rem;
    }

    #userAccountName {
        font-family: NotoSans-Light;
        font-size: 2.8rem;
        margin-top: 1rem;

        @media (max-width: $tablet-width) {
            font-size: 2.6rem;
            margin-top: 1rem;
        }

        @media (max-width: $mobile-width) {
            font-size: 2.2rem;
            margin-top: 1rem;
        }
    }
}
