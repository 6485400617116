.worknote-item {
    margin-top: 1rem;
    margin-bottom: 1rem;

    &:first-of-type {
        margin-top: 0;
    }
}

.worknote-item-content {
    @include flex(row, center, center, nowrap);
    margin-top: 1rem;
    padding-bottom: 1rem;
    line-height: 150%;
    word-break: break-word;
}

.worknote-item-divider {
    border-bottom: 1px solid map-get($colour-palette, open-divider);

    &:last-of-type {
        display: none;
    }
}

