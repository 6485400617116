.worknote-item{
    @include flex(column, space-between, initial, nowrap);

    .worknote-item-content{
        justify-content: flex-start;
    }

    .buttonContainer {
        @include flex(column, space-between, initial, nowrap);
        .button:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    
    .buttonDivider {
        width: 2rem;
    }
    .worknote-item-row {
        @include flex(row, space-between, initial, nowrap);
        margin-top: 1rem;
        width: 100%;
    }
}
