#userNavMenuButtonContainer {
    @include flex(row, flex-start, center, nowrap);

    #userNavMenuToggleButton {
        @include flex(row, flex-start, center, nowrap);
        background: none;
        border: none;
        cursor: pointer;
        padding: 0;
        @include boxSizing(border-box);
        color: map-get($colour-palette, open-white);

        // &:focus {
        //     outline: none;
        // }

        #userIcon {
            height: 20px;
        }
        
        #dropdownIcon {
            height: 10px;
        }

        #userNavMenuToggleBtnLabel {
            margin: 0 1.5rem;
        }
    }
}
