.labelled-textarea {
    @include flex(column, flex-start, flex-start, nowrap);

    @media print {
        width: 100%;
    }

    .form-label {
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .form-textarea {
        width: 350px;
        max-width: 100%;
        height: 150px;
        min-height: 150px;
        border-radius: 5px;
        vertical-align: top;
        padding: 0.5rem 0.5rem 0.5rem 1rem;
        background-color: map-get($colour-palette, open-white);
        border: 1px solid map-get($colour-palette, open-brand-primary);
        color: map-get($colour-palette, open-text-primary);
        resize: both;
        @include boxSizing(border-box);

        @media (max-width: $tablet-width) {
            width: 100%;
        }

    }

    #char-count {
        @include flex(row, flex-end, flex-start, nowrap);
        width: 100%;
        margin-top: 1rem;
        font-family: NotoSans-Regular;
        font-size: 1.2rem;

        span {
            padding: 0 0.25rem;
        }
    }

    .rich-text-editor-wrapper {
        width: 100%;
        font-size: 1.5rem;
        height: 280px;
        padding-bottom: 50px;
        font-family: montserrat, sans-serif;
        font-size: 16px;
        line-height: normal;
    }

    .rich-text-editor {
        border: 1px solid #F1F1F1;
        line-height: normal;
        overflow: hidden;
    }
}

.DraftEditor-editorContainer {
    height: 275px;
    overflow: auto;
    padding: 0px 14px;
}

.modal {
    .rich-text-editor-wrapper {
        height: 200px;
    }

    .DraftEditor-editorContainer {
        height: 195px;
    }

    .rdw-option-wrapper {
        margin: 0 3px;
    }
}