#nmiModal {
    min-height: 80%;
    @media (max-width: $mobile-width) {
        width: 95%;
        min-height: 100%;
    }
    .nmiBody {
        flex: 1;
        overflow-y: auto;
        @include boxSizing(border-box);
        border-top: 1px solid map-get($colour-palette, open-content-container-border);
        border-bottom: 1px solid map-get($colour-palette, open-content-container-border);
        .nmiContent,
        .nmiFlexContent {
            @include flex(row, space-between, normal, nowrap);
            width: 100%;
            .nmiItem {
                min-width: 48.5%;
            }
        }
        .nmiFlexContent {
            @media (max-width: $mobile-width) {
                flex-direction: column;
                .nmiFieldLabel {
                    padding: 3rem 0 1rem 0 !important;
                }
            }
        }

        div select {
            min-width: 100%;
        }
        .nmiAttachments {
            padding: 3rem 0 0 0;
        }
        #noOfAttachments {
            padding: 1rem 0;
        }
        .nmiFieldLabel,
        .nmiDateLabel {
            padding: 0 0 1rem 0;
        }
        .nmiAaLabel {
            padding: 3rem 0 1rem 0;
        }
        .nmiALabel {
            padding: 2rem 0 1rem 0;
        }
        .nmiButtonGroup {
            display: flex;
            justify-content: space-between;
            width: 60%;
            #nmiModal-view,
            #nmiModal-add {
                width: 120px;
                min-width: 120px;
                @media (max-width: $tablet-width) {
                    width: 100px;
                    min-width: 100px;
                }
                @media (max-width: $mobile-width) {
                    width: 80px;
                    min-width: 80px;
                }
            }
        }
    }

    .labelled-input .form-input {
        @media (max-width: $tablet-width) {
            max-width: 100%;
        }
    }

    /**
 * react-select-search styles
 */
    .select-search {
        position: relative;
        box-sizing: border-box;
    }

    .select-search *,
    .select-search *::after,
    .select-search *::before {
        box-sizing: inherit;
    }

    /**
 * Value wrapper
 */
    .select-search__value {
        position: relative;
    }

    .select-search__value::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: calc(50% - 9px);
        right: 19px;
        width: 11px;
        height: 11px;
    }

    /**
 * Input
 */
    .select-search__input {
        min-width: 100%;
        height: 40px;
        border-radius: 5px;
        border: 1px solid map-get($colour-palette, open-brand-primary);
        padding: 0 5rem 0 1rem;
        background-image: url("../../styles/images/dropdownIcon.svg");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .select-search__input::-webkit-search-decoration,
    .select-search__input::-webkit-search-cancel-button,
    .select-search__input::-webkit-search-results-button,
    .select-search__input::-webkit-search-results-decoration {
        -webkit-appearance: none;
    }

    .select-search__input:not([readonly]):focus {
        cursor: initial;
    }

    /**
 * Options wrapper
 */
    .select-search__select {
        background: #fff;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
        border: solid 1px #000;
    }

    /**
 * Options
 */
    .select-search__options {
        list-style: none;
    }

    /**
 * Option row
 */
    .select-search__row:not(:first-child) {
        border-top: 1px solid #eee;
    }

    /**
 * Option
 */
    .select-search__option,
    .select-search__not-found {
        display: block;
        height: 36px;
        width: 100%;
        padding: 0 16px;
        border: none;
        outline: rgba(0, 0, 0, 0.15);
        font-family: NotoSans-Regular !important;
        font-size: 1.4rem !important;
        text-align: left;
        cursor: pointer;
        background: #fff;
    }

    .select-search--multiple .select-search__option {
        height: 48px;
    }

    .select-search__option.is-selected {
        background: map-get($colour-palette, open-green);
        color: map-get($colour-palette, open-white);
    }

    .select-search__option.is-highlighted,
    .select-search__option:not(.is-selected):hover {
        background: rgba(47, 204, 139, 0.1);
    }

    .select-search__option.is-highlighted.is-selected,
    .select-search__option.is-selected:hover {
        background: map-get($colour-palette, open-brand-primary);
        color: map-get($colour-palette, open-white);
    }

    /**
 * Group
 */
    .select-search__group-header {
        font-size: 1.4rem;
        text-transform: uppercase;
        background: #eee;
        padding: 8px 16px;
    }

    /**
 * States
 */
    .select-search.is-disabled {
        opacity: 0.5;
    }

    .select-search.is-loading .select-search__value::after {
        background-size: 11px;
    }

    .select-search:not(.is-disabled) .select-search__input {
        cursor: pointer;
        font-size: 1.4rem;
        font-family: "NotoSans-Regular";
    }

    /**
 * Modifiers
 */
    .select-search--multiple {
        border-radius: 3px;
        overflow: hidden;
    }

    .select-search--multiple .select-search__input {
        cursor: initial;
    }

    .select-search--multiple .select-search__input {
        border-radius: 3px 3px 0 0;
    }

    .select-search--multiple:not(.select-search--search) .select-search__input {
        cursor: default;
    }

    .select-search:not(.select-search--multiple) .select-search__input:hover {
        border-color: map-get($colour-palette, open-text-success);
    }

    .select-search:not(.select-search--multiple) .select-search__select {
        position: absolute;
        z-index: 2;
        top: 44px;
        right: 0;
        left: 0;
        border-radius: 3px;
        overflow: auto;
        max-height: 360px;
    }

    .select-search--multiple .select-search__select {
        position: relative;
        overflow: auto;
        max-height: 260px;
        border-top: 1px solid #eee;
        border-radius: 0 0 3px 3px;
    }

    .select-search__not-found {
        height: auto;
        padding: 16px;
        text-align: center;
        color: #888;
    }
    .uploadContainer {
        margin-top: 20px;
    }

    #viewDocument-Document {
        width: 120px;
        min-width: 120px;
        @media (max-width: $mobile-width) {
            min-width: 50px;
        }
        height: 40px;
        size: 1.8rem;
    }

    #uploadDocument-Document {
        width: 120px;
        min-width: 120px;
        @media (max-width: $mobile-width) {
            min-width: 50px;
        }
        height: 40px;
        size: 1.8rem;
    }

    .upDocumentButtonContainer {
        width: 335px;
    }
}
