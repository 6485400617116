.sqNoAnswerContainer {
  @include flex(column, center, center, wrap);
  // border-radius: 10px;
  width: 100%;
  height: 100%;
  // background-color: map-get($colour-palette, open-white);
  // min-height: 200px;

  .sqNoAnswerHeader {
    @include flex(row, flex-end, center, wrap);
    width: 100%;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    margin-bottom: 1rem;

    button {
      margin: 1.5rem 1rem;
    }
  }

  .sqNoAnswerBody {
    @include flex(row, center, center, wrap);
    width: 100%;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    min-height: 100px;
  }
}