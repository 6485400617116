#prfOverview {
    width: 100%;

    .prfOverviewContainer {
        #prfActions {
            height: auto;

            #prfActionContainer {
                @include flex(row, flex-end, stretch, nowrap);
            }

            #prfActionContainer > *:not(:last-child) {
                margin-right: 2rem;
            }
        }

        #prfActionCard {
            @include flex(row, center, center, wrap);

            #actions {
                height: 400px;

                #prfActionContent {
                    margin-top: 2rem;

                    #prfActionWorknotes > *:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }

                .uploadContainer {
                    margin-top: 3rem;

                    .upFormInputContainer {
                        width: 100%;
                        max-width: 100%;

                        .flex-break {
                            margin: 0;
                            height: 0;
                        }

                        .upDocumentButtonContainer {
                            padding: 0;
                            margin: 0;
                        }
                    }

                    .upFormInputContainer > *:not(:last-child) {
                        margin-bottom: 1rem;
                    }
                }
            }
            #worknotes {
                margin-bottom: 1em;
            }
        }

        #requestOverviewCard {
            .sectionContent{
                width: auto;
            }
            .dashboardHeaderBtn {
                @include flex(row, space-between, center, nowrap);

                @media (max-width: $mobile-width) {
                    @include flex(column, space-between, center, nowrap);

                    .button:first-child {
                        margin-right: 0rem;
                        margin-bottom: 2rem;
                    }
                    .button {
                        height: fit-content;
                        height: -moz-fit-content;
                        width: 80px;
                    }

                    #actionBtn {
                        margin-right: 0px;
                        margin-left: 20px;
                    }
                }

                #projectApproved-statuslabel {
                    height: 40px;
                    margin-right: 1rem;
                }

                .editProcurementDetails {
                    width: auto;
                }
            }

            .sectionHeader {
                margin-top: 2rem;
                margin-bottom: 2rem;
            }

            .sectionRow {
                @include flex(row, flex-start, flex-start, nowrap);
                margin-top: 2rem;
                .sectionLabel {
                    width: 100%;
                    max-width: 250px;
                }
                .sectionValue {
                    width: 100%;
                    word-break: break-word;
                }

                @media (max-width: $mobile-width) {
                    flex-wrap: wrap;
                    p {
                        width: 100%;
                        margin-left: 0;

                        &:first-of-type {
                            margin-bottom: 1rem;
                        }
                    }
                }
            }
        }

        .error {
            color: map-get($colour-palette, open-text-warning);
        }

        .approved {
            color: map-get($colour-palette, open-text-success);
        }
        #labelText {
            font-weight: bold;
        }
    }
}
