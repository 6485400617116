.uploadContainer {
    @include flex(column, flex-start, initial, nowrap);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    width: 100%;

    @media (max-width: $mobile-width) {
        @include flex(column, initial, initial, nowrap);
    }

    .upFormInputContainer {
        width: 230px;
        max-width: 350px;

        .upDocumentButtonContainer {
            @include flex(row, space-between, flex-start, nowrap);
            padding-top: 1.5rem;
            .button {
                width: 100px;
            }
        }

        @media (max-width: $tablet-width) {
            max-width: 250px;
        }

        @media (max-width: $mobile-width) {
            max-width: 230px;
        }
    }

    .formLabel {
        margin-bottom: 1rem;
    }
}


