.editSteeringGroup {
    justify-content: flex-end;
    width: auto;
}

.steeringGroupsContainer {
    width: 100%;
    .steeringGroupsItem {
        @include flex(row, space-between, space-between, nowrap);
        width: 100%;
        margin-top: 2rem;

        .steeringGroupItem p {
            margin-bottom: 1rem;
        }
    }
}