#vePanel {
    @include flex(column, flex-start, flex-start nowrap);
    top: 100px;
    bottom: 20px;

    @media (max-width: $tablet-width) {
        top: 0;
        bottom: 0;
    }

    .viewEventPanelHeader {
        @include flex(row, space-between, center, nowrap);
        padding: 2rem;
        border-top-left-radius: 10px;
        @include boxSizing(border-box);

        .closeBtn {
            position: absolute;
            right: 0;
            margin-right: 2rem;
            border: none;
            background: none;
            &-img {
                cursor: pointer;
                height: 30px;
            }
        }

        @media (max-width: $mobile-width) {
            padding: 1rem 2rem;
        }
    }

    .vepBody {
        flex: 1;
        overflow-y: auto;
        @include boxSizing(border-box);
        border-top: 1px solid map-get($colour-palette, open-content-container-border);
        border-bottom: 1px solid map-get($colour-palette, open-content-container-border);
        padding: 2rem;

        .viewEventPanelTitle {
            @include flex(row, space-between, center, nowrap);
            padding: 0 0 1rem;
            .viewEventPending {
                color: map-get($colour-palette, open-white);
                background-color: map-get($colour-palette, open-green);
                border-radius: 5rem;
                padding: 0.5rem 2rem;
                @media (max-width: $mobile-width) {
                    padding: 0.5rem 1rem;
                }
            }
        }
        #vepDetails {
            padding-bottom: 1rem;
        }
        .viewEventPanelContent {
            @include flex(row, space-between, center, nowrap);
            padding: 1rem 0;
            .caption {
                width: 70%;
                @media (max-width: $mobile-width) {
                    width: 65%;
                }
            }
        }
        .viewEventPanelDivider {
            width: 100%;
            border: 1px solid map-get($colour-palette, open-divider);
            margin: 1rem 0;
        }

        .button {
            width: 120px;
            min-width: 120px;
            @media (max-width: $mobile-width) {
                min-width: 50px;
            }
        }
        .viewEventPanelButtonGroup {
            @include flex(row, space-between, flex-start, nowrap);
            padding: 1rem 0;

            @media (max-width: $mobile-width) {
                button {
                    width: 100px;
                }
            }
        }

        #viewDocument-Document {
            width: 120px;
            min-width: 120px;
            @media (max-width: $mobile-width) {
                min-width: 50px;
            }
            height: 40px;
            size: 1.8rem;
        }

        #uploadDocument-Document {
            width: 120px;
            min-width: 120px;
            @media (max-width: $mobile-width) {
                min-width: 50px;
            }
            height: 40px;
            size: 1.8rem;
        }

        .upDocumentButtonContainer {
            width: 360px;
        }
    }
    .viewEventPanelFooter {
        @include flex(row, center, flex-start, nowrap);
        padding: 2rem 2rem 3rem;
        border-bottom-left-radius: 10px;
        @include boxSizing(border-box);

        @media (max-width: $mobile-width) {
            padding: 2rem 2rem;
        }
    }
    &-completeEvent.primary {
        min-width: 100%;
    }
    &-completeEvent.disabled {
        min-width: 100%;
        background-color: map-get($colour-palette, open-footer-border);
        color: map-get($colour-palette, open-text-grey);
    }
    #vePanel-completeEvent {
        width: 320px;
        height: 40px;
    }

    #vePanel-checkApproveContainer {
        @include flex(row, center, flex-start, nowrap);
    }
    #vePanel-rejectEvent {
        width: 150px;
        height: 40px;
        margin-right: 11px;
    }
    #vePanel-approveEvent {
        width: 150px;
        height: 40px;
    }
    #vePanel-approved {
        width: 311px;
        height: 40px;
        background-color: map-get($colour-palette, open-text-grey);
    }
    #vePanel-rejected {
        width: 311px;
        height: 40px;
        background-color: map-get($colour-palette, open-text-grey);
    }
}
