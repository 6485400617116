#cPPSection,
#lotSection {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  flex: 1;
  background-color: map-get($colour-palette, open-white);
  border-radius: 10px;
  border: 1px solid map-get($colour-palette, open-content-container-border);
  padding: 4rem;
  @include boxSizing(border-box);

  .sectionHeader {
    color: map-get($colour-palette, open-brand-primary);
    padding-top: 2rem;
    margin-right: 1rem;
  }

  #sectionTitle {
    margin-bottom: 2rem;
  }
  .headerContainer {
    @include flex(row, space-between, space-between, nowrap);
    width: 100%;
  }
  #headerButtonContainer {
    @include flex(row, flex-end, flex-start, nowrap);

    #cancel-action-btn {
      margin-right: 4rem;
    }

    #calloff-action-btn {
      margin-left: 2rem;
    }
  }

  #lotFormContent {
    #title-input{
      width: 100%;
      height: 40px;
  }
}

  #cPPFormContent,
  #lotFormContent {
    border-top: 1px solid map-get($colour-palette, open-divider);
    width: 100%;
    @include boxSizing(border-box);

    #cPPFormHeader,
    #lotFormHeader {
      margin-bottom: 2rem;
    }

    #estimatedValue-input {
      width: 350px;
      height: 40px;
    }

    #projectDescription {
      width: 100%;

      .labelled-textarea {
        width: 66.6%;

        @media (max-width: $mobile-width) {
          width: 100%;
        }

        #description-textarea {
          width: 100%;

          @media (max-width: $mobile-width) {
            width: 100%;
          }
        }
      }
    }

    .formInputContainer {
      @include flex(column, space-between, flex-start, nowrap);

      .customDateInput {
        width: 350px;

        @media (max-width: $tablet-width) {
          max-width: 250px;
          width: 100%;
        }

        @media (max-width: $mobile-width) {
          max-width: 230px;
          width: 100%;
        }

        .react-date-picker__inputGroup__input:invalid {
          background: none;
        }
      }

      .select-search__input {
        width: 350px;
        height: 40px;
        border-radius: 5px;
        padding: 0.5rem;
        border: 1px solid map-get($colour-palette, open-brand-primary);
        background-image: url("../../styles/images/dropdownIcon.svg");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;

        @media (max-width: $tablet-width) {
          width: 250px;
        }

        @media (max-width: $mobile-width) {
          width: 230px;
        }
      }

      .select-search__select {
        background: #fff;
        box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
        border: solid 1px #000;
      }

      .labelled-input {
        width: 100%;
        .form-input {
          @media (max-width: $tablet-width) {
            max-width: 250px;
            width: 100%;
          }

          @media (max-width: $mobile-width) {
            max-width: 230px;
            width: 100%;
          }
        }

        #responsibleCaption {
          margin-bottom: 1rem;
        }

        input[type="number"] {
          width: 350px;
          height: 40px;
          border-radius: 5px;
          padding: 0.5rem;
          border: 1px solid map-get($colour-palette, open-brand-primary);

          @media (max-width: $tablet-width) {
            max-width: 250px;
            width: 100%;
          }

          @media (max-width: $mobile-width) {
            max-width: 230px;
            width: 100%;
          }
        }
      }

      .form-select {
        max-width: 350px;

        @media (max-width: $tablet-width) {
          max-width: 250px;
        }

        @media (max-width: $mobile-width) {
          max-width: 230px;
        }
      }

      .form-textarea {
        @media (max-width: $tablet-width) {
          width: 250px;
        }

        @media (max-width: $mobile-width) {
          width: 230px;
        }
      }
    }

    //#cPPSectionContent,
    #lotSectionContent {
      border-top: 1px solid map-get($colour-palette, open-divider);

      .textIconContainer {
        @include flex(row, flex-start, center, nowrap);

        #addButton {
          .button-icon {
            @media (max-width: $tablet-width) {
              height: auto;
            }
            @media (max-width: $mobile-width) {
              height: auto;
            }
          }
        }
      }

      .sectionContentTags {
        @include flex(row, flex-start, flex-start, wrap);
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        padding: 2rem 0;

        &:last-child {
          border: none;
        }

        .tag-content {
          margin: 0 2rem 2rem 0;
        }
      }
    }
    #lotSectionContent {
      padding: 1rem 0;
    }
    #actionButtonContainer {
      @include flex(row, flex-end, flex-start, nowrap);

      #cancel-action-btn {
        margin-right: 4rem;
      }
    }
  }
}
