@use '../../styles/scss/mixins.scss' as m;
@use '../../styles/scss/variables.scss' as v;
@use './styles.scss';

#awardSummaryContainer {
    @include m.flex(row, space-around, stretch, wrap);
    width: 100%;
    #awardSummaryBanner {
        width: 100%;
        padding-bottom: 2rem;
    }

    .awardSummaryContentContainer {
        width: 100%;
        margin-bottom: 2rem;

        .awardSummaryContent {
            width: 100%;
            .additionalexportbtn {
                width: 10%;
                margin-right: 1rem;
            }
            .additionalnewsubmissionbtn {
                width: 13%;
                margin-right: 1rem;
            }
            .awardSummaryContentSection {
                @include m.flex(column, center, center, wrap);
                background-color: white;
                width: 100%;
                border-radius: 10px;
                border-radius: 10px;
                .awardSummaryLotTitle {
                    width: 98%;
                    padding: 1rem;
                }
                .awardSummaryContentBody {
                    @include m.flex(row, space-evenly, center, nowrap);
                    background-color: white;
                    border-bottom: 1px solid lightgray;
                    width: 98%;
                    padding: 1rem;
                    .awardSummaryQuestionTitle {
                        width: 70%;
                        padding: 2rem;
                        font-weight: 700;
                    }
                    .awardSummaryBtnGroup {
                        @include m.flex(row, flex-end, center, nowrap);
                        width: 40%;
                        padding: 1rem;
                        margin-right: 1rem;

                        .btnSize {
                            height: 30px;
                            width: 25%;
                        }

                        #questionStatus-statuslabel {
                            margin-right: 1rem;
                            margin-left: 1rem;
                        }
                    }
                }
            }

            #buttonDiv {
                padding: 1rem;
                background-color: white;
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 2rem;
                padding: 2rem;
                border-radius: 10px;
                height: 35px;
                .guidanceInfo {
                    margin-right: 2rem;
                }
            }
        }
    }
    #awardSummaryNoLots {
        display: flex;
        flex-direction: column;
        background-color: white;
        justify-content: center;
        align-items: center;
        height: 500px;
        width: 100%;

        @media (max-width: v.$mobile-width) {
            height: 300px;
        }
    }

    #lotButton {
        padding: 1rem;
    }

    #accordionContentBody {
        display: flex;
        justify-content: space-between;
        padding: 2rem;
        align-items: center;
        border-bottom: 1px solid lightgray;

        .awardSummaryBtnGroup {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            width: 30%;
            align-items: center;

            .btnSize {
                height: 30px;
                width: 35%;
            }
        }
    }
    #lotAccordion {
        .accordion-section {
            margin-bottom: 2rem;
        }
        .accordion-section-header-data {
            border-bottom: 1px solid lightgray;
            padding: 2rem 1rem 2rem 1rem;
        }
        .awardSummaryQuestionTitle {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            .awardSummaryQuestionScore{
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 25%;
            }
            .title {
                @include m.flex(row, center, center, nowrap);
            }
        }
    }
    #share-icon {
        height: 20px;
        width: 20px;
        margin-right: 1rem;
    }
    .awardSummaryOutcome{
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
    #awardBtnContainer{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .btnFullWidth{
            width:100%;
        }
        .button:not(:last-child) {
            margin-bottom: 1rem;
        }
    }
    #contractBtnContainer{
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        .btnFullWidth{
            width:100%;
        }
    }
    #contractItem{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    #evaluationItem{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%;
    }
    #evaluationOutcome{
        height:400px;
    }

    #contractOutcome{
        height:400px;
    }
}
