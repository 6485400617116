.form {
    input,
    select,
    textarea {
        @include boxSizing(border-box);
    }
    .sectionHeader{
        font-weight: bold;
        color: map-get($colour-palette, open-brand-primary);
    }
}

#submit-input {
    width: 150px;
    height: 40px;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: map-get($colour-palette, open-brand-primary);
    color: map-get($colour-palette, open-white);
    cursor: pointer;
    border-radius: 5px;

    @media (max-width: $mobile-width) {
        width: 120px;
    }
}

.flex-break {
    flex-basis: 100%;
    height: 10px;
    margin: 1.5rem 0;
}
