.bdContentSection {
    padding: 4rem;
    align-items: flex-start;
}

.about-us {
    &-title {
        margin-top: 3rem;
    }

    &-content {
        margin-top: 2rem;
    }

    &-list {
        padding-left: 4rem;
        list-style-type: disc;
    }

}

.prevButton{
    position: relative;
    height: 35px;
    width: 42px;
    height: 35px;
    width: 42px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 1;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.25s;
    right: initial;
    border: white;

    .prevButtomImage{
        height: 15px;
        width: 10px;
        background: transparent;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(0deg);
    }
}

.nextButton{
    position: relative;
    height: 35px;
    width: 42px;
    background: #fff;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 #0009;
    z-index: 1;
    cursor: pointer;
    font-size: 10px;
    opacity: 0.6;
    transition: opacity 0.25s;
    border: white;

    .nextButtomImage{
        height: 15px;
        width: 10px;
        background: transparent;
        display: inline-block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%) rotate(0deg);
    }
}

.container {
    display: flex;
    width: 100%;
    height: 350px;
    align-items: center;

    .group {
        display: flex;
        align-items: center;
    
        .column {
            flex: 1;
            color: white;
            text-align: center;
            
            img {
                width: 90%;
            }
        }
    }
}
  