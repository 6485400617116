h1 {
    font-family: 'Roboto', sans-serif;
    font-size: 4.8rem;

    @media (max-width: $tablet-width) {
        font-size: 3.2rem;
    }

    @media (max-width: $mobile-width) {
        font-size: 2.6rem;
    }
}

h2 {
    font-family: 'Roboto', sans-serif;
    font-size: 3.6rem;

    @media (max-width: $tablet-width) {
        font-size: 2.8rem;
    }

    @media (max-width: $mobile-width) {
        font-size: 2.4rem;
    }
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-size: 2.8rem;

    @media (max-width: $tablet-width) {
        font-size: 2.6rem;
    }

    @media (max-width: $mobile-width) {
        font-size: 2.2rem;
    }
}

h4 {
    font-family: 'Roboto', sans-serif;
    font-size: 1.9rem;

    @media (max-width: $tablet-width) {
        font-size: 1.8rem;
    }

    @media (max-width: $mobile-width) {
        font-size: 1.7rem;
    }
}

.title {
    font-family: 'Roboto', sans-serif;
    font-size: 1.6rem;
    line-height: normal;

    &-xSmall {
        font-family: 'Roboto', sans-serif;
        font-size: 1.2rem;
        line-height: normal;
    }

    &-small {
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
        line-height: normal;
    }

    &-large {
        font-family: 'Roboto', sans-serif;
        font-size: 1.8rem;
        line-height: normal;

        @media (max-width: $mobile-width) {
            font-size: 1.6rem;
        }
    }

    &-xLarge {
        font-family: 'Roboto', sans-serif;
        font-size: 2rem;
        line-height: normal;

        @media (max-width: $tablet-width) {
            font-size: 1.8rem;
        }

        @media (max-width: $mobile-width) {
            font-size: 1.8rem;
        }
    }

    @media (max-width: $tablet-width) {
        font-size: 1.4rem;
    }

    @media (max-width: $mobile-width) {
        font-size: 1.4rem;
    }
}

.body {
    font-family: montserrat, sans-serif;
    font-size: 1.6rem;
    line-height: 150%;

    &.error, &.unsuccessful {
        color: map-get($colour-palette, open-red);
    }

    &.successful {
        color: map-get($colour-palette, open-green);
    }

    &.bold {
        font-family: montserrat, sans-serif;
    }

    &-xSmall {
        font-family: montserrat, sans-serif;
        font-size: 1.2rem;
        line-height: 150%;
    }
    
    &-small {
        font-family: montserrat, sans-serif;
        font-size: 1.4rem;
        line-height: 150%;
    }

    @media (max-width: $tablet-width) {
        font-family: montserrat, sans-serif;
        font-size: 1.4rem;
        line-height: 150%;
    }

    @media (max-width: $mobile-width) {
        font-family: montserrat, sans-serif;
        font-size: 1.4rem;
        line-height: 150%;
    }
}

.caption {
    font-family: montserrat, sans-serif;
    font-size: 1.6rem;
    line-height: 150%;

    &-large {
        font-family: montserrat, sans-serif;
        font-size: 1.8rem;
        line-height: 150%;
    }

    &-small {
        font-family: montserrat, sans-serif;
        font-size: 1.4rem;
        line-height: 150%;
    }

    &-xSmall {
        font-family: montserrat, sans-serif;
        font-size: 1.2rem;
        line-height: 150%;
    }

    @media (max-width: $tablet-width) {
        font-size: 1.4rem;
        line-height: 150%;
    }

    @media (max-width: $mobile-width) {
        font-size: 1.4rem;
        line-height: 150%;
    }
}

.links {
    font-family: montserrat, sans-serif;
    text-decoration: underline;
    font-size: 1.6rem;
    line-height: 150%;

    &-small {
        font-family: montserrat, sans-serif;
        text-decoration: underline;
        font-size: 1.4rem;
        line-height: 150%;
    }

    &-xSmall {
        font-family: montserrat, sans-serif;
        text-decoration: underline;
        font-size: 1.2rem;
        line-height: 150%;
    }

    @media (max-width: $tablet-width) {
        font-size: 1.4rem;
        line-height: 150%;
    }

    @media (max-width: $mobile-width) {
        font-size: 1.4rem;
        line-height: 150%;
    }
}

.button-text {
    font-family: montserrat, sans-serif;
    font-size: 1.6rem;
    text-transform: capitalize;

    @media (max-width: $tablet-width) {
        font-size: 1.4rem;
    }

    @media (max-width: $mobile-width) {
        font-size: 1.4rem;
    }
}
