#cpFormDrawer {
    @include flex(column, flex-start, flex-start, nowrap);
    background: map-get($colour-palette, open-green);
    color: map-get($colour-palette, open-white);
    border-radius: 10px;
    min-width: 350px;
    @include boxSizing(border-box);
    padding: 4rem 2rem;

    @media (max-width: $tablet-width) {
        min-width: 250px;
        padding: 2rem;
    }

    @media (max-width: $mobile-width) {
        padding: 2rem;
    }

    .cpDescription {
        line-height: 150%;
        padding-top: 1rem;
        a {
            color: map-get($colour-palette, open-white);
        }
    }

    #cpList {
        @include flex(column, flex-start, flex-start, nowrap);
        margin-top: 3rem;

        @media (max-width: $mobile-width) {
            margin-top: 2rem;

            & > .cpListItems:nth-child(3) {
                margin-bottom: 0;
            }
        }

        .cpListItems {
            @include flex(row, flex-start, flex-start, nowrap);
            margin-bottom: 2rem;

            .cpInnerIcons {
                @include flex(column, center, flex-start, nowrap);
                width: 25px;
            }

            .cpListInnerText {
                margin-left: 2rem;
            }

            p {
                line-height: 150%;
            }
            img {
                border-radius: 1px;
                border: 2px solid map-get($colour-palette, open-green);
                width: 25px;
                height: 25px;
            }

            a {
                color: map-get($colour-palette, open-white);
                margin-right: 0.5rem;
            }
        }
    }
}
