
.previous-responses-tree {
    margin-top: 2rem;
}

.previous-responses-tree  div[aria-level='0'] > a {
    color:#2c4874;
    font-size: 1.9rem;
}

.previous-responses-tree  div[aria-level='1'] > a {
    padding-left:2rem;
    font-weight: 600;
}
.previous-responses-tree  div[aria-level='2'] > a {
    padding-left:4rem;
    color:#2c4874;
    font-weight: 600;
}
.previous-responses-tree  div[aria-level='2'] > a .tree-view-menu {
    margin-left: auto;
    margin-right: inherit;
}
.previous-responses-tree  div[aria-level='3'] > div {
    padding-left:6rem;
}

.current-responses-tree div[aria-level='0'] > a {
    color:#2c4874;
    font-size: 1.4rem;
    font-weight: 600;
}
.current-responses-tree div[aria-level='1'] > div {
    padding-left:2rem;
    & .title {
        font-family: inherit;
        font-size: 1.4rem;
    }
}
.button.link {
    text-decoration: none;
}
