.listed-checkBox {
  @include flex(column, flex-start, start, nowrap);

  &.row {
      @include flex(row, flex-start, center, nowrap);
      .form-radio-button-container {
          margin-right: 4rem;
      }
  }

  .disabled {
    opacity: 0.4;
  }

  .form-selected-container {
      @include flex(column, flex-start, flex-start, nowrap);
      width: 100%;
      padding: 1rem;
      // position: relative;

      .title-and-pill {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }

      .form-label {
          margin-bottom: 0.5rem;
          flex: none;
          cursor: pointer;
      }

      .checkBox-label {
          // padding: 0 0 0 4rem;
          &-primary {
            color: map-get($colour-palette, open-brand-primary);
          }

          &-grey {
            color: map-get($colour-palette, open-grey);
          }
      }
      .form-checkBox-container {
        margin-top: -20px;
      }

    //   input[type="checkbox"] {
    //       position: absolute;
    //       opacity: 0;
    //       pointer-events: none;
    //   }

    //   input[type="checkbox"] + .form-label {
    //       display: block;
    //       position: relative;
    //       // padding: 0 0 0 4rem;
    //   }

      // input[type="checkbox"] + .form-label:before {
      //     // left: 0;
      // }

    //   input[type="checkbox"] + .form-label:before {
    //       content: "";
    //       display: inline-block;
    //       width: 2rem;
    //       height: 2rem;
    //       background-color: map-get($colour-palette, open-white);
    //       margin-right: 1.6rem;
    //       position: absolute;
    //       top: 2.5rem;
    //       left: 54rem;
    //       z-index: 1;
    //   }

    //   input[type="checkbox"]:focus + .form-label:before {
    //       outline: 1px solid black;
    //   }

    //   input[type="checkbox"]:focus + .form-label:after {
    //       outline: 1px solid black;
    //   }

    //   input[type="checkbox"]:checked + .form-label:before {
    //       border-width: 0.1rem;
    //       border-color: map-get($colour-palette, open-green);
    //   }

    //   input[type="checkbox"] + .form-label:before {
    //       border: 0.1rem solid map-get($colour-palette, open-green);
    //       background-clip: padding-box;
    //   }

    //   input[type="checkbox"]:checked + .form-label:after {
    //       content: "";
    //       display: inline-block;
    //       width: 2.3rem;
    //       height: 2.3rem;
    //       background-color: map-get($colour-palette, open-green);
    //       background-image: url("../../styles/images/check-icon.svg");
    //       background-position: 50% 40%;
    //       background-size: 50%;
    //       background-repeat: no-repeat;
    //       position: absolute;
    //       top: 2.5rem;
    //       left: 54rem;
    //       z-index: 1;
    //   }
  }
}
