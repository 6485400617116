.documentSection {
    width: 100%;
    padding: 0 2rem;
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    margin: 1.5rem 0;

    @media (max-width: $tablet-width) {
        padding: 0 3rem;
        margin: 1rem 0;
    }

    @media (max-width: $mobile-width) {
        padding: 0 2rem;
    }

    &.view {
        color: map-get($colour-palette, open-brand-primary);
    }
}

.versions-selector {
    display: flex;
    gap: 10px;
    font-size: 14px;
    margin-left: 10px;

    & input {
        min-width: 245px;
        height: 35px;
    }
}

#toolbarSection {
    @include flex(row, flex-end, center, nowrap);
    height: 80px;
    margin-top: 0;
    top: 0; // enables the animation to actually happen

    #primaryToolbarBtn {
        margin-left: 2rem;
    }

    &.view {
        @include flex(column, flex-start, flex-start, wrap);
        height: 170px;
    }

    #disableSection {
        margin-right: 2rem;
        width: 100%;

        #disableToolbarBtn {
            width: 100px;
        }
    }

    #draftToolbarBtn {
        margin-left: 2rem;
        width: 200px;
    }

    #draftedQuestionnaire-statuslabel {
        margin-right: 2rem;
    }

    @media (max-width: $mobile-width) {
        @include flex(row, center, center, nowrap);
    }
}

#toolbarSection.sticky {
    position: fixed;
    top: 100px;
    z-index: 2;
    background: white;
    transition: top 0.5s ease-in-out;
    border-bottom: 2px solid #eee;
    width: calc(100% - 60px);
    max-width: 1220px;

    @media (max-width: $tablet-width) {
        top: 80px;
        width: calc(100% - 40px);
    }

    @media (max-width: $mobile-width) {
        top: 80px;
        width: calc(100% - 20px);
    }
}

#toolbarSectionSQ {
    @include flex(row, space-between, center, nowrap);
    margin-top: 0;
    top: 0; // enables the animation to actually happen

    .buttonsSQ {
        height: 80px;
        width: 60%;
        @include flex(row, flex-end, center, nowrap);

        #saveBtn {
            margin-left: 0;
        }


        #submitBtn {
            margin-left: 0;
        }

        .button {
            margin: 1rem 1rem 0 1rem;

            #cancelAnswerBtn {
                margin-left: 2rem;
            }

            #underEvaluationStatus-statuslabel {
                margin-right: 0;
            }

            #viewSubmissionBtn {
                margin-left: 2rem;
            }

            @media (max-width: 660px) {
                height: auto;
                width: 100%;
                margin-top: 1rem;

                #saveBtn {
                    margin-left: 0;
                }

                #submitBtn {
                    margin-left: 0;
                }

                .button {
                    margin: 1rem 1rem 0 1rem;
                }

                #underEvaluationStatus-statuslabel {
                    margin-right: 0;
                }

                @include flex(row, center, center, wrap);
            }

            @include flex(row, center, center, wrap);
        }
    }

    .bigHeight {
        height: 130px;
        width: 100%;
        display: flex;

        .questionnaireChangesBtns {
            width: 40%;
            height: 100%;
            @include flex(column, space-evenly, flex-start, nowrap);

            #viewSubmissionBtn {
                width: 35%;
                margin-left: 10px;
            }
        }

        .buttonsSQ {
            width: 60%;
            margin-top: 50px;
            height: 100%;
            @include flex(row, flex-end, flex-start, nowrap);

            #saveBtn {
                margin-left: 2rem;
            }

            #submitBtn {
                margin-left: 2rem;
            }

            #cancelAnswerBtn {
                margin-left: 2rem;
            }

            #viewSubmissionBtn {
                margin-left: 2rem;
            }

            @media (max-width: 660px) {
                height: auto;
                width: 100%;
                margin-top: 1rem;

                #saveBtn {
                    margin-left: 0;
                }

                #submitBtn {
                    margin-left: 0;
                }

                .button {
                    margin: 1rem 1rem 0 1rem;
                }

                #underEvaluationStatus-statuslabel {
                    margin-right: 0;
                }

                @include flex(row, center, center, wrap);
            }
        }
    }

    .smallHeight {
        height: 80px;
        width: 100%;
        display: flex;

        .questionnaireChangesBtns {
            width: 40%;
            @include flex(column, center, flex-start, nowrap);

            #viewSubmissionBtn {
                width: 35%;
            }
        }

        .buttonsSQ {
            width: 60%;
            @include flex(row, flex-end, center, nowrap);

            #saveBtn {
                margin-left: 2rem;
            }

            #submitBtn {
                margin-left: 2rem;
            }

            #cancelAnswerBtn {
                margin-left: 2rem;
            }

            #viewSubmissionBtn {
                margin-left: 2rem;
            }

            @media (max-width: 660px) {
                height: auto;
                width: 100%;
                margin-top: 1rem;

                #saveBtn {
                    margin-left: 0;
                }

                #submitBtn {
                    margin-left: 0;
                }

                .button {
                    margin: 1rem 1rem 0 1rem;
                }

                #underEvaluationStatus-statuslabel {
                    margin-right: 0;
                }

                @include flex(row, center, center, wrap);
            }
        }
    }

    #evaluationStatusLabel {
        min-width: 172px;

        #underEvaluationStatus-statuslabel {
            min-width: 172px;
        }
    }

    @media (max-width: $tablet-width) {
        height: auto;
        padding: 1rem;
    }

    @media (max-width: 660px) {
        height: auto;
        width: 100%;

        @include flex(column, center, center, wrap);
    }
}

.answerInputWrapper {
    @include flex(row, flex-start, flex-start, wrap);
    padding-bottom: 20px;

    #scoring-input-label,
    #comments-input-label {
        color: map-get($colour-palette, open-brand-primary);
    }

    .answerInput {
        @include flex(column, flex-start, flex-start, wrap);

        @media print {
            width: 100%;
        }

        .form-label:not(.radio-label):not(.checkBox-label) {
            color: map-get($colour-palette, open-green);
            font-size: 1.8rem;
        }

        .checkBox-label {
            margin: 1rem 0;
        }

        .upFormInputContainer {
            padding-top: 2rem;
            width: 100%;
            max-width: 100%;

            .upDocumentButtonContainer {
                width: 230px;
                max-width: 350px;
            }
        }
    }

    .flexGrow {
        flex: 1;
    }

    #description-textarea,
    #comments-textarea {
        width: 600px;

        @media (max-width: $mobile-width) {
            width: auto;
        }

        @media print {
            width: auto;
            height: auto;
            white-space: pre-wrap;
        }
    }
}

#detailsSection {
    #documentDetailsAccordion {
        #dbAccordionSection-0 {
            .accordion-section-header {
                &-data {
                    padding: 2rem 0;
                    pointer-events: none;

                    p {
                        color: map-get($colour-palette, open-brand-primary);
                    }
                }

                &-icon {
                    height: 25px;
                }
            }

            .accordion-section-content {
                &.open {
                    max-height: none !important;
                    overflow: visible;
                }

                .accordion-section-content-data {
                    border-top: 1px solid map-get($colour-palette, open-content-container-border);
                    padding: 2rem 0;

                    #char-count {
                        width: 100%;
                        max-width: 350px;
                    }
                }

                .formElementsDivider {
                    width: 100%;
                    height: 20px;

                    button {
                        width: auto;
                    }
                }
            }
        }
    }
}

.formElementsDivider {
    width: 100%;
    height: 20px;

    button {
        width: auto;
    }
}

#viewHeaderSectionSQ {

    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    width: 100%;
    padding: 2rem;
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    margin: 1.5rem 0;

    @media (max-width: $tablet-width) {
        padding: 2rem;
        margin: 1rem 0;
    }

    @media (max-width: $mobile-width) {
        padding: 2rem;
    }

    .viewHeading {
        color: map-get($colour-palette, open-brand-primary);
    }

    #viewHeaderSectionDetailsSQ {

        #backBtn {
            margin-left: 2rem;
        }

        .viewHeading {
            color: map-get($colour-palette, open-brand-primary);
        }

        p {
            margin-bottom: 1rem;

            &.selection-questionnaire-desc {
                max-width: 850px;
            }
        }

        width: 100%;
        @include flex(row, space-between, flex-start, nowrap);
    }

    .buttonsSQ {
        @include flex(row, space-between, flex-start, nowrap);

        @media (max-width: $mobile-width) {
            @include flex(column, space-between, flex-start, nowrap);
        }
    }

    .button {
        margin-right: 2%;

        @media (max-width: $mobile-width) {
            margin-bottom: 2%;
        }
    }

    .caption {
        margin-bottom: 2%;
    }
}



#viewHeaderSection {
    @include flex(row, flex-start, center, nowrap);
    width: 100%;
    width: 100%;
    padding: 2rem;
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    margin: 1.5rem 0;

    @media (max-width: $tablet-width) {
        padding: 0 3rem;
        margin: 1rem 0;
    }

    @media (max-width: $mobile-width) {
        padding: 0 2rem;
    }



    #viewHeaderSectionDetails {
        .viewHeading {
            color: map-get($colour-palette, open-brand-primary);
        }

        p {
            margin-bottom: 1rem;

            &.selection-questionnaire-desc {
                max-width: 850px;
            }
        }

        @include flex(column, flex-start, flex-start, nowrap);
    }

    #editDocumentBtn {
        // align-self: flex-start;
        min-width: fit-content;
    }

    .button {
        margin-left: 1rem;
    }

    #changeHistoryBtn {
        min-width: fit-content;
        margin: 0;
    }

    #underEvaluationButton {
        height: 35px;
        font-size: 1.2rem;
        color: map-get($colour-palette, open-amber);
        border: 1px solid map-get($colour-palette, open-amber);
    }

    .flexGrow {
        flex: 1;
    }

    #viewButtonContainer {
        @include flex(row, flex-start, center, nowrap);
    }
}


#toolbarSectionSQ.sticky {
    position: fixed;
    top: 100px;
    z-index: 2;
    background: white;
    transition: top 0.5s ease-in-out;
    border-bottom: 2px solid #eee;
    width: calc(100% - 60px);
    max-width: 1220px;

    @media (max-width: $tablet-width) {
        top: 80px;
        width: calc(100% - 40px);
    }

    @media (max-width: $mobile-width) {
        top: 80px;
        width: calc(100% - 20px);
    }
}

#overviewSection {
    @include flex(row, flex-start, center, nowrap);
    // height: 80px;
    padding: 1.5rem;

    #evaluationOverviewSectionWrapper {
        @include flex(row, flex-start, center, wrap);
        width: 100%;

        .overviewLabelValuePair,
        .overviewLabelScoringPair {
            @include flex(row, flex-start, center, wrap);

            .label {
                margin-right: 1rem;
            }
        }
    }

    #overviewSectionWrapper,
    #answerOverviewSectionWrapper {
        @include flex(row, flex-start, center, wrap);
        width: 100%;

        .overviewButtonWrapper {
            @include flex(row, flex-start, center, wrap);
        }

        .import-stages-btn {
            width: auto;
        }

        .overviewLabelValuePair {
            @include flex(row, flex-start, center, wrap);

            &:nth-child(odd) {
                margin-right: 2rem;
            }

            #valid {
                color: map-get($colour-palette, open-text-success);
            }

            #invalid {
                color: map-get($colour-palette, open-text-warning);
            }

            .label {
                margin-right: 1rem;
            }
        }

        .flexGrow {
            flex: 1;
        }

        #addSectionBtn {
            cursor: pointer;

            img {
                height: 70% !important;
            }
        }
    }
}

#evaluationOverviewSectionWrapper {
    #evaluationStats {
        @include flex(row, flex-start, flex-start, wrap);
    }

    .overviewLabelValuePair {
        // width: 270px;
        margin-right: 3rem;
    }

    span {
        color: map-get($colour-palette, open-green);
    }
}

#answerOverviewSectionWrapper {
    #answerStats {
        @include flex(row, flex-start, flex-end, wrap);
        width: 100%;

        #leftSideStat {
            margin-right: 2rem;
        }

        .overviewLabelValuePair {
            padding: 1rem;
            margin: 0;
        }
    }

    span {
        color: map-get($colour-palette, open-green);

        &.red {
            color: map-get($colour-palette, open-red);
        }
    }
}

#contentSections {
    background-color: transparent;
    padding: 0;

    .contentSectionRow {
        @include flex(row, flex-start, center, nowrap);
        width: 100%;

        .contentSection {
            background-color: map-get($colour-palette, open-white);
            flex: 1;
            padding: 2rem;
            // margin: 2rem 0;
            @include boxSizing(border-box);
            border-radius: 10px;

            &:first-of-type {
                margin-top: 0;
            }

            .contentSectionHeader {
                border-bottom: 1px solid map-get($colour-palette, open-divider);
                padding-bottom: 3rem;

                #valid,
                .title-large {
                    color: map-get($colour-palette, open-green);
                    float: right;
                    font-weight: bold;
                }

                .headerToolbar {
                    @include flex(row, flex-end, flex-start, nowrap);

                    button[id^="editSectionBtn-"] {
                        margin-left: 2rem;
                    }
                }

                .headerTextRowMain {
                    @include flex(row, space-between, flex-start, nowrap);

                    .awTextRow {
                        @include flex(row, space-between, flex-end, nowrap);

                        label {
                            color: map-get($colour-palette, open-green);
                        }
                    }

                    .headerTextRowMainInner {
                        width: 100%
                    }
                }

                // .awTextRow {
                //     @include flex(row, space-between, flex-end, nowrap);
                //     margin: 1rem 0;

                //     p,
                //     label {
                //         color: map-get($colour-palette, open-green);
                //     }
                // }

                .headerTextRow {
                    @include flex(row, space-between, center, nowrap);
                    margin: 1rem 0;

                    .showSectionWeightage {
                        @include flex(row, flex-start, center, nowrap);
                    }

                    .red {
                        color: map-get($colour-palette, open-red);
                    }

                    .label {
                        margin-right: 1rem;

                        &.heading {
                            color: map-get($colour-palette, open-brand-primary);
                        }
                    }

                    .value {
                        word-break: break-word;

                        &.heading {
                            color: map-get($colour-palette, open-brand-primary);
                        }
                    }

                    .headerTextRowWarpper {
                        @include flex(row, flex-start, center, nowrap);
                    }
                }

                & .evaluators-dropdown {
                    display: flex;
                    margin-right: auto;

                    &>div {
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                    }
                }

            }

            .contentSectionMain {
                @include flex(column, flex-start, flex-start, wrap);
                width: 100%;
                min-height: 250px;
                padding-top: 2rem;

                .setionItemMainToolbar {
                    @include flex(row, flex-end, center, nowrap);
                    width: 100%;
                    margin-bottom: 1rem;

                    .setionItemMainToolbarValuePair {
                        @include flex(row, flex-start, center, nowrap);

                        .label {
                            margin-right: 1rem;
                        }
                    }

                    button {
                        width: auto;
                        cursor: pointer;
                    }
                }

                .sectionItemMainContent {
                    @include flex(column, flex-start, flex-start, wrap);
                    flex: 1;
                    width: 100%;
                    height: 100%;

                    .saveSectionBtn {
                        align-self: flex-end;
                    }

                    .sectionItemMainContentRow {
                        @include flex(row, space-between, center, wrap);
                        width: 100%;
                        padding: 1.5rem 0;
                        border-bottom: 1px solid map-get($colour-palette, open-divider);

                        &.answerContent:last-of-type {
                            border-bottom: none;
                        }

                        &:first-of-type {
                            padding-top: 1rem;
                        }

                        .sectionItemReorderBtnsCol {
                            height: 100%;
                            margin-right: 2rem;

                            button {
                                width: 25px;
                                height: 25px;

                                img {
                                    width: 15px;
                                    height: 15px;
                                }
                            }
                        }

                        .sectionItemTextCol {
                            flex: 1;
                            // min-width: 300px;
                            height: 100%;
                            position: relative;
                            word-break: break-word;

                            .sectionItemHeading {
                                color: map-get($colour-palette, open-green);
                                width: 75%;
                                display: flex;

                                .question-updated-info {
                                    display: flex;
                                    margin-left: 15px;
                                    align-items: center;
                                    flex: none;
                                    place-items: flex-start;
                                }

                                .info-tooltip {
                                    @include tooltip(
                                        $data-content: 'There has been an update - Please review and update accordingly',
                                        $width: 220px,
                                        $padding: 5px 12px,
                                        $offset-y: -18px,
                                        $offset-x: 158px,
                                        $arrow-transform: translateY(68%) rotate(90deg),
                                    );
                                    margin-left: 5px;
                                    width: 20px;
                                    display: flex;
                                    top: 3px;

                                    img {
                                        width: 20px;
                                    }
                                }
                            }

                            .sectionItemDescription {
                                margin: 0.5rem 0 1rem 0;
                            }

                            .answerSectionWeightage {
                                position: absolute;
                                right: 0;
                                top: 0;
                            }

                            .viewEvalContainer {
                                @include flex(row, space-between, flex-start, wrap);
                                width: 100%;

                                #viewEvalTitle {
                                    color: map-get($colour-palette, open-brand-primary);
                                }

                                .weightedScore {
                                    @include flex(row, flex-start, flex-start, wrap);
                                }

                                p {
                                    margin-bottom: 1rem;
                                }

                                .evalComments {
                                    @include flex(row, flex-start, flex-start, wrap);
                                }

                                .evalComment {
                                    margin-left: 1rem;
                                }

                            }

                            .awLabelContainer {
                                @include flex(row, flex-start, baseline, wrap);
                                min-width: 250px;
                                margin: 1rem 0;

                                p {
                                    color: map-get($colour-palette, open-green);
                                }

                                label {
                                    color: map-get($colour-palette, open-green);
                                }
                            }

                            .sectionItemTextRow {
                                @include flex(row, flex-start, flex-start, wrap);

                                .labelValueContainer {
                                    @include flex(row, flex-start, baseline, wrap);
                                    min-width: 250px;
                                    margin: 0.5rem 0;

                                    &:first-child,
                                    &:nth-child(even) {
                                        margin-right: 4rem;
                                    }

                                    .label {
                                        margin-right: 1rem;
                                    }

                                    .value {
                                        word-break: break-word;
                                    }
                                }
                            }
                        }

                        .sectionItemBtnCol {
                            @include flex(row, flex-start, center, wrap);
                            height: 100%;
                            margin-left: 2rem;

                            button[id^="editSectionItemBtn-"] {
                                margin: 1rem 1.5rem;
                            }
                        }
                    }
                }
            }
        }

        .sectionOrderBtnsContainer {
            @include flex(column, center, center, nowrap);
            min-width: 50px;
        }

        .orderBtn {
            @include flex(column, center, center, nowrap);
            width: 30px;
            height: 30px;
            border-radius: 5px;
            background-color: map-get($colour-palette, open-brand-primary);
            color: map-get($colour-palette, open-white);
            margin: 0.5rem 0;
            cursor: pointer;
            border: none;

            img {
                width: 20px;
                pointer-events: none;
            }

            &.disabled {
                background-color: map-get($colour-palette, open-grey);
            }
        }
    }
}

#roleAssignment {
    #assigned {
        margin-top: 1rem;
    }
}

#questionnaireDetailsForm,
#roleAssignment {
    #assigned {
        width: 30%;
    }
}

#expand-collipse-button {
    cursor: pointer;
}

.individual-evaluators-scoring-table {
    min-width: 100%;

    tr:nth-child(even) {
        background-color: #F2F5FA;

        th {
            background-color: #F2F5FA;
        }
    }

    .table-header-row {
        th {
            background-color: #2c4874;
            color: #fefefe;
            padding: 1.5rem;
            text-align: start;
        }

        th:nth-child(1) {
            width: 25%;
        }

        th:nth-child(2) {
            width: 17%;
        }

        th:nth-child(3) {
            width: 10%;
        }
    }

    .table-body-row {

        td:nth-child(2),
        td:nth-child(3) {
            text-transform: capitalize;
        }
    }

    .table-body-row-cell {
        text-align: start;
        padding: 1.5rem;
    }

    .table {
        min-width: 100%;
        border: 1px solid #F2F5FA;
        border-radius: 10px;

        td,
        th {
            min-width: 0;
            width: 0;
            border: none;
            font-size: 1.6rem;
        }
    }
}

.individual-evaluators-scoring-table-button {
    background: none;
    border: none;
    width: 100%;
    display: flex;
    justify-content: end;
    font-size: 1.4rem;
    margin-bottom: 10px;
}

p.empty-content {
    font-size: 1.6rem;
    padding: 2rem;
    background-color: #fefefe;
    border-radius: 10px;
    font-family: montserrat, sans-serif;
}

.view-attachments-popup {
    margin: 2rem 0rem 2rem 0rem;

    .uploadContainer {
        width: auto;
        border-top: 1px solid map-get($colour-palette, open-divider);
        border-radius: 0;
        padding-top: 1rem;

        & .formLabel .title {
            font-weight: bold;
            color: map-get($colour-palette, open-brand-primary);
            font-size: 1.8rem;
        }

        & .flex-break {
            display: none;
        }
    }

    & .additional-note {
        font-size: 1.2rem;
        margin: 1.5rem 0 1rem 0;
        color: map-get($colour-palette, open-brand-primary);
        font-style: oblique;
    }
}