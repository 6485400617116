.filterPanelHeader {
    @include flex(row, space-between, center, nowrap);
    padding: 2rem;
    border-top-left-radius: 10px;
    @include boxSizing(border-box);

    .closeBtn {
        position: absolute;
        right: 0;
        margin-right: 2rem;
        border: none;
        background: none;
        &-img {
            cursor: pointer;
            height: 30px;
        }
    }

    @media (max-width: $mobile-width) {
        padding: 2rem 1rem;
    }

    @media (max-width: $small-mobile-width) {
        padding: 1rem;
    }
}

.filterPanelContent {
    flex: 1;
    padding: 2rem;
    overflow-y: auto;
    @include boxSizing(border-box);
    border-top: 1px solid map-get($colour-palette, open-content-container-border);
    border-bottom: 1px solid map-get($colour-palette, open-content-container-border);

    @media (max-width: $mobile-width) {
        padding: 2rem 1rem;
    }
}

.filterPanelFooter {
    @include flex(row, space-around, flex-start, nowrap);
    padding: 2rem 2rem 3rem;
    border-bottom-left-radius: 10px;
    @include boxSizing(border-box);

    @media (max-width: $mobile-width) {
        padding: 2rem 1rem;
    }
}
