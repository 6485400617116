#userManagementContainer {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  flex: 1;
  border-radius: 10px;
  background-color: map-get($colour-palette, open-white);
  @include boxSizing(border-box);
  padding: 1rem 4rem 1rem;

  @media (max-width: $mobile-width) {
    padding: 1rem 2rem 1rem;
  }

  #userManagementBtnContainer {
    width: 100%;
    @include flex(row, flex-end, center, nowrap);
    padding-bottom: 1rem;
    border-bottom: 1px solid
      map-get($colour-palette, open-content-container-border);
    @include boxSizing(border-box);
  }

  .userContentSection {
    @include flex(column, flex-start, center, nowrap);
    width: 100%;
    flex: 1;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    padding: 2rem;
    @include boxSizing(border-box);

    .userContentHeader {
      @include flex(row, flex-start, center, wrap);
      width: 100%;
      min-height: 50px;

      .searchBarContainer {
        width: 50%;
        padding: 1rem;
        @include boxSizing(border-box);

        @media (max-width: $mobile-width) {
          width: 100%;
        }
      }

      .sortFilterContainer {
        @include flex(row, flex-end, center, nowrap);
        width: 50%;
        padding: 1rem;
        @include boxSizing(border-box);

        #usermanagementSortDropdown {
          margin-right: 1rem;

          @media (max-width: $tablet-width) {
            margin-right: 0.5rem;
          }
        }
        #usermanagementFilterButton {
          margin-left: 1rem;

          @media (max-width: $tablet-width) {
            margin-left: 0.5rem;
          }
        }

        @media (max-width: $mobile-width) {
          @include flex(row, space-between, center, nowrap);
          width: 100%;
        }
      }
    }

    .userContentBody {
      @include flex(column, flex-start, center, nowrap);
      width: 100%;
      flex: 1;
      padding: 3rem 0;

      #userAccordion {
        width: 100%;
        max-width: 900px;

        .accordion-section {
          margin-bottom: 3rem;
          border: 1px solid map-get($colour-palette, open-brand-primary);
          border-radius: 5px;
          //border-bottom: 1px solid map-get($colour-palette, open-brand-primary);
          //padding: 5px 0;

          &:last-child {
            margin-bottom: 0;
          }

          @media (max-width: $tablet-width) {
            margin-bottom: 2rem;
          }

          &-header {
            border-radius: 5px;
            padding: 0 2rem;

            img {
              padding: 0;
            }

            @media (max-width: $tablet-width) {
              padding: 0 1rem;
            }

            &.contentOpen {
              border-radius: 5px 5px 0 0;
            }

            &-data {
              pointer-events: none;

              p {
                padding: 1rem 0;
                margin-right: 2rem;
              }

              .headerTitle {
                margin-right: 2rem;

                @media (max-width: $tablet-width) {
                  margin-right: 1rem;
                }
              }
            }
          }
          &-content {
            &.open {
              border-top: 1px solid map-get($colour-palette, open-brand-primary);
              border-radius: 0 0 5px 5px;
              overflow-y: auto;
            }

            &-data {
              @include flex(column, flex-start, center, nowrap);
              @include boxSizing(border-box);

              .userAccordionSectionContentRow {
                @include flex(row, space-between, flex-start, wrap);
                width: 100%;

                .userAccordionSectionContentData {
                  @include flex(row, flex-start, flex-start, wrap);
                  padding: 1rem 2rem;

                  .label {
                    margin-right: 1rem;
                  }

                  @media (max-width: $tablet-width) {
                    padding: 1rem;
                  }

                  @media (max-width: $mobile-width) {
                    padding: 0.5rem;

                    p {
                      padding: 0.5rem;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    #userFilterPanel {
      @include flex(column, flex-start, flex-start nowrap);
      top: 100px;
      bottom: 20px;

      @media (max-width: $mobile-width) {
        top: 0;
        bottom: 0;
      }
    }

    .userContentFooter {
      width: 100%;
      height: 80px;
    }
  }

  .muSelectSearch {
    margin-bottom: 3rem;

    #specialPrivileges {
      margin-top: 1rem;
    }

  }


  #userContent {
    padding: 2rem 0;
    border: none;

    .label {
      @media (max-width: $mobile-width) {
        width: auto;
      }
    }

    .button {
      margin: 0;

      @media (max-width: $mobile-width) {
        margin-bottom: 0;
      }
    }

    .buttonItem {
      @include flex(row, flex-end, center, nowrap);
      width: 100%;
      margin: 1.5rem 0 2rem;

      @media (max-width: $mobile-width) {
        @include flex(column, center, flex-start, nowrap);
      }
      .userButtonsContainer {
        @include flex(row, space-between, center, nowrap);
        padding: 0 1.5rem 0 2rem;
        width: 100%;

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
        }

        .userPasswordWrapper {
          @include flex(row, flex-start, flex-start, nowrap);
          .button {
            max-width: 120px;
            width: 120px;
            height: 30px;
            font-size: 1.2rem;
            // word-break: break-word;
            padding: 0.5rem;
          }

          @media (max-width: $mobile-width) {
            margin: 0 1rem 2rem 0;
          }
        }

        .userButtonsWrapper {
          @include flex(row, flex-start, flex-start, nowrap);

          @media (max-width: $mobile-width) {
            @include flex(column, flex-start, flex-start, nowrap);
          }

          .button {
            max-width: 100px;
            width: 100px;
            height: 30px;
            font-size: 1.4rem;
          }

          .button:first-of-type {
            margin-right: 2rem;

            @media (max-width: $mobile-width) {
              margin-bottom: 2rem;
            }
          }

          @media (max-width: $mobile-width) {
            margin-left: 0;
          }
        }

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
          margin-right: 1.5rem;
        }
        .button:last-of-type {
          @media (max-width: $mobile-width) {
            margin-left: 0;
          }
        }
      }
    }
  }
}
