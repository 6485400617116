@media not print {
    .show-on-print {
        visibility: hidden !important;
    }

    .render-on-print {
        display: none !important;
    }
}

@media print {
    .hide-on-print {
        visibility: hidden !important;
    }

    .remove-on-print {
        display: none !important;
    }

    button {
        display: none !important;
    }

    .mainContent{
        margin: 0 !important;
        padding: 0 !important;
    }

    // To prevent text being chopped in half when printing,
    //Everything that's display flex and is not critical should be block when printing
    div.labelled-textarea,
    div.answerInput,
    div.answerInputWrapper,
    div.sectionItemMainContentRow,
    div.sectionItemMainContent,
    main.contentSectionMain,
    div.contentSectionRow,
    main.mainContent,
    div#root,
    .sectionItemMainContentRow,
    .headerTextRowWarpper,
    .labelled-radio-button,
    .labelled-radio,
    .form-radio-button-container,
    .labelled-checkBox
     {
        display: block !important;
    }

    .files-list {
        margin-bottom: 1rem;;
    }
    
    .answerInputWrapper .answerInput .checkBox-label{
        margin-bottom: 0.5rem !important;
    }
    .labelled-checkBox .form-checkBox-container input[type=checkbox]+label::after {
        display: none !important;
    }
    
    .labelled-checkBox .form-checkBox-container input[type=checkbox]+label::before {
            border: 1px solid #2c4874 !important;
            width: 2rem !important;
            height: 2rem !important;
            color: #2c4874;
            line-height: 2rem;
            font-size: 2rem;
            text-align: center;
            margin-top: -0.35rem;
    }
    .labelled-checkBox .form-checkBox-container input[type=checkbox]:checked+label::before {
        content: "✔";
    }

    
    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]+label::after {
        display: none !important;
    }
    
    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]+label::before {
            border: 1px solid #2c4874 !important;
            width: 2rem !important;
            height: 2rem !important;
            border-radius: 2rem;
            color: #2c4874;
            line-height: 2rem;
            font-size: 2rem;
            text-align: center;
    }
    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"]:checked+label::before {
        content: "⬤";
        font-size: 1.8rem;
    }
    .files-list {
        font-family: "Roboto", sans-serif;
        margin-bottom: 1rem;
        padding-top: 0.5rem;
        padding-left: 1rem;
        font-size: 1.4rem;
        & li {
            list-style-type: none;
            padding: 0.25rem;
        }
    }

    .labelled-radio-button .labelled-radio .form-radio-button-container input[type="radio"] + .form-label{
            line-height: 2.4rem;
            margin: 0.3rem 0 0.7rem 1rem;
    }
    .title-information {
        font-weight: bold;
        font-family: "Roboto", sans-serif;
        font-size: 2rem;
        line-height: normal;
    }

    //Less space between sections
    .answerInputWrapper {
        margin-bottom: 0.5rem;
        padding-bottom: 0.5rem;
    }
    #contentSections .contentSectionRow .contentSection{
        padding: 0 2rem !important;
    }
    .contentSection .contentSectionMain, .labelled-textarea .form-textarea {
        min-height: initial !important;
    }
    .labelled-textarea {
        margin-bottom: 0.5rem;
    }
    .contentSectionHeader {
        padding-bottom: 1rem !important;
    }
    .contentSectionMain {
        padding-top: 1rem !important;
    }
    .sectionItemMainContentRow:last-child {
        margin-bottom: -1rem !important; 
        padding-bottom: 0 !important;
    }
}