#hpMainContent {
    width: 100%;
    flex: 1;
    margin-top: 10rem;

    @media (max-width: $tablet-width) {
        margin-top: 8rem;
    }

    #hpWelcomeContainer {
        @include flex(row, center, flex-start, wrap);
        width: 100%;
        height: 600px;
        color: map-get($colour-palette, open-white);
        background-image: radial-gradient(circle at center, #20b3aa -100%, #1f3765);

        @media (max-width: $tablet-width) {
            height: 350px;
        }

        @media (max-width: $mobile-width) {
            height: 150vh;
        }

        #welcomeMsgContainer {
            @include flex(column, center, flex-start, nowrap);
            width: 45%;
            min-width: 150px;
            height: 100%;
            padding: 4rem 5rem 4rem 12rem;
            @include boxSizing(border-box);

            p {
                font-family: NotoSans-Light;
                font-size: 2.8rem;
                margin: 2rem 0 5.5rem 0;
                line-height: 150%;
                white-space: pre-wrap;

                .bold {
                    font-family: NotoSans-Bold;
                }
            }

            @media (max-width: $laptop-width) {
                padding-left: 4rem;
            }

            #getStartedBtn {
                width: 240px;
                height: 60px;
                border-radius: 50px;
                border: none;
                font-family: NotoSans-Bold;
                font-size: 2.8rem;
                background: none;
                border: 3px solid #d3b04a;
                color: #fff;
            }

            #getStartedBtn:hover, #getStartedBtn:focus-visible {
                border: 3px solid map-get($colour-palette, open-skyblue);
                outline: none;
            }

            @media (max-width: $tablet-width) {
                padding: 2rem;

                p {
                    font-size: 2.2rem;
                    margin: 1rem 0 3rem 0;
                }

                #getStartedBtn {
                    @media (max-width: $tablet-width) {
                        width: 200px;
                        height: 50px;
                        font-size: 2.4rem;
                        z-index: 2;
                    }
                }
                #getStartedBtn:hover, #getStartedBtn:focus-visible {
                    border: 3px solid map-get($colour-palette, open-skyblue);
                    outline: none;
                }
            }

            @media (max-width: $mobile-width) {
                @include flex(column, center, center, nowrap);
                width: 100%;
                height: 50%;
                padding: 1rem 4rem;
                text-align: center;

                p {
                    font-size: 1.8rem;
                    margin: 1rem 0 3rem 0;
                }

                #getStartedBtn {
                    @media (max-width: $tablet-width) {
                        width: 160px;
                        height: 40px;
                        font-size: 1.8rem;
                        z-index: 2;
                    }
                }
                #getStartedBtn:hover, #getStartedBtn:focus-visible {
                    border: 3px solid map-get($colour-palette, open-skyblue);
                    outline: none;
                }
            }
        }

        #welcomeImgContainer {
            @include flex(column, center, center, nowrap);
            width: 55%;
            min-width: 250px;
            height: 100%;
            padding: 4rem 12rem 4rem 5rem;
            @include boxSizing(border-box);

            img {
                width: 80%;
            }

            @media (max-width: $laptop-width) {
                padding-right: 4rem;
            }

            @media (max-width: $tablet-width) {
                padding: 2rem;
            }

            @media (max-width: $mobile-width) {
                @include flex(column, center, center, nowrap);
                width: 100%;
                height: 50%;
                padding: 0;

                img {
                    height: 120%;
                    width: auto;
                    margin-top: -90px;
                }
            }
        }
    }

    #hpInfoContainer {
        width: 100%;
        min-height: 820px;
        background-color: map-get($colour-palette, open-white);
        @include boxSizing(border-box);
        @include flex(column, flex-start, center, nowrap);

        #portalUseQuestionText,
        #describeQuestionText {
            color: map-get($colour-palette, open-brand-primary);
            margin: 8rem 0 6rem 0;
            font-weight: 800;
            font-size: 50px;
            @media (max-width: $tablet-width) {
                margin: 4rem 0 3rem 0;
            }
        }

        #hpInfoCards {
            @include flex(row, space-evenly, space-evenly, wrap);
            width: 100%;

            .card {
                width: 100%;
                height: fit-content;
                height: -moz-fit-content;
                border: none;
                border-radius: 300px;
                margin: 2rem;
                background-image: linear-gradient(to left,#c9d8e4 -144%, #fff 50%);

                section {
                    border: none;
                    width: 45%;
                    height: auto;

                    @media (max-width: $tablet-width) {
                        width: 90%;
                        align-self: center;
                    }

                    @media (max-width: $mobile-width) {
                        width: 90%;
                        align-self: center;
                    }
                }

                .card-content {
                    flex: 1;
                    width: 100%;
                    height: 77.5%;
                    display: flex;
                    flex-direction: row-reverse;

                    @media (max-width: $mobile-width) {
                        flex-direction: column;
                    }
                }

                .card-content-image-container img {
                    width: 80%;
                    height: auto;

                    @media (max-width: $tablet-width) {
                        width: 90%;
                        margin-top: 0;
                    }

                    @media (max-width: $mobile-width) {
                        width: 85%;
                        margin-top: 0;
                    }
                }


                @media (max-width: $tablet-width) {
                    width: 90%;
                }

                @media (max-width: $mobile-width) {
                    width: 90%;
                }

                &-content-container {
                    h3 {
                        color: map-get($colour-palette, open-brand-primary);
                        margin-top: 4rem;
                    }

                    p {
                        text-align: left;
                        width: 100%;
                        margin-top: 2rem;
                        line-height: 150%;
                        color: #1f3765;
                    }
                }
            }

            .card:nth-child(odd) {
                background-image: linear-gradient(to right,#c9d8e4 -144%, #fff 50%);

                .card-content {
                    flex-direction: row;

                    @media (max-width: $mobile-width) {
                        flex-direction: column;
                    }
                }
            }

        }

        @media (max-width: $tablet-width) {
            h2,
            p {
                margin: 4rem 0 3rem 0;
            }
        }

        @media (max-width: $mobile-width) {
            h2,
            p {
                margin: 3rem 0 2rem 0;
            }
        }
    }
}
