.statuslabel-content {
    @include flex(row, center, center, nowrap);
    min-width: 130px;
    max-width: 250px;
    background: map-get($colour-palette, open-white);
    border: 1px solid map-get($colour-palette, open-brand-primary);
    border-radius: 50px;
    padding: 0 1.5rem;

    @include boxSizing(border-box);

    @media (max-width: $mobile-width) {
        max-width: 150px;
    }

    &.red {
        color: map-get($colour-palette, open-red);
        border: 1px solid map-get($colour-palette, open-red);
    }

    &.amber {
        color: map-get($colour-palette, open-amber);
        border: 1px solid map-get($colour-palette, open-amber);
    }

    &.green {
        // open-green is currently rendering the incorrect colour.
        // color: map-get($colour-palette, open-green);
        // border: 1px solid map-get($colour-palette, open-green);

        color: map-get($colour-palette, open-text-success);
        border: 1px solid map-get($colour-palette, open-text-success);
    }
}
