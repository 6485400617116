.accordion-section {
    width: 100%;
    min-height: 40px;
    @include flex(column, flex-start, flex-start, nowrap);

    &-header {
        width: 100%;
        min-height: inherit;
        @include flex(row, space-between, center, nowrap);
        background-color: map-get($colour-palette, open-white);
        cursor: pointer;
        @include boxSizing(border-box);
        border: none;
        gap: 20px;

        &-data {
            @include flex(row, space-between, center, wrap);
            flex: 1;
            padding-right: 2rem;

            @media (max-width: $mobile-width) {
                padding-right: 1rem;
            }

            p {
                word-break: break-word;
            }
        }

        &-icon {
            height: 50%;
            transition: transform 0.6s ease;
            pointer-events: none;
            margin-right: 10px;
            
            @media (max-width: $tablet-width) {
                padding: 0 1rem;
            }

            &.rotate {
                transform: rotate(90deg);
            }
        }
    }

    &-content {
        width: 100%;
        background-color: map-get($colour-palette, open-white);
        overflow: hidden;
        transition: max-height 0.2s ease;
        @include boxSizing(border-box);
    }
}
