.swal2-container {
    .swal2-popup {
        width: 400px;
        padding: 0;
    }

    .swal2-toast {
        padding: 2rem !important;

        .swal2-title {
            font-family: NotoSans-Medium !important;
            font-size: 1.6rem !important;
            line-height: normal;
            margin: 0rem !important;
            padding: 2rem !important;
            font-weight: normal;
        }

        .swal2-icon {
            width: 5em !important;
            height: 5em !important;
        }
    }

    .swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"] {
        width: 3.75em;
        height: 7.5em;
    }

    .swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="left"] {
        top: -0.4375em;
        left: -2.0635em;

        transform: rotate(-45deg);
        transform-origin: 3.75em 3.75em;
        border-radius: 7.5em 0 0 7.5em;
    }

    .swal2-popup.swal2-toast .swal2-success [class^="swal2-success-circular-line"][class$="right"] {
        top: -0.6875em;
        left: 1.875em;
        transform: rotate(-45deg);
        transform-origin: 0 3.75em;
        border-radius: 0 7.5em 7.5em 0;
    }

    .swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring,
    .swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
        width: 100%;
        height: 100%;
    }

    .swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="tip"] {
        top: 2.875em;
        width: 1.5625em;
        left: 0.8125em;
        transform: rotate(45deg);
    }

    .swal2-popup.swal2-toast .swal2-success [class^="swal2-success-line"][class$="long"] {
        top: 2.375em;
        right: 0.5em;
        width: 2.9375em;
        transform: rotate(-45deg);
    }

    .swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
        position: absolute;
        z-index: 1;
        top: 0.5em;
        left: 1.625em;
        width: 0.4375em;
        height: 5.625em;
        transform: rotate(-45deg);
    }

    .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"] {
        top: 2.3125em;
        width: 2.9375em;
    }

    .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="left"] {
        left: 1.0625em;
    }

    .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^="swal2-x-mark-line"][class$="right"] {
        right: 1em;
    }

    .swal2-modal {
        .swal2-title {
            font-family: NotoSans-Medium;
            font-size: 2rem;
            line-height: normal;
            margin-bottom: 2rem;
            padding: 2rem 2rem;
            font-weight: normal;
            border-bottom: 1px solid map-get($colour-palette, open-divider);
            color: map-get($colour-palette, open-text-primary);

            @media (max-width: $tablet-width) {
                font-size: 1.6rem;
            }

            @media (max-width: $mobile-width) {
                font-size: 1.6rem;
            }
        }

        .swal2-actions {
            width: 100%;
            padding: 1rem 2rem 3rem 2rem;
            @include flex(row, center, center, wrap);

            &:not(.swal2-loading) .swal2-styled:hover {
                background-image: none;
            }
        }

        .swal2-styled {
            border: none;
            border-radius: 5px;
            width: 150px;
            height: 40px;
            font-family: NotoSans-Medium;
            font-size: 1.6rem;
            line-height: normal;

            &.swal2-confirm {
                background-color: map-get($colour-palette, open-brand-primary);

                &:focus,
                &:hover {
                    box-shadow: 0 0 0 2px black;
                    outline: 2px solid black;
                }
            }

            // &.swal2-deny {
            //     &:focus {
            //         box-shadow: $swal2-deny-button-focus-box-shadow;
            //     }
            // }

            &.swal2-cancel {
                border: 1px solid map-get($colour-palette, open-brand-primary);
                color: map-get($colour-palette, open-brand-primary);
                background-color: map-get($colour-palette, open-white) !important;

                &:focus,
                &:hover {
                    // box-shadow: 0 0 0 3px map-get($colour-palette, open-body-grey);
                    outline: 2px solid black;
                }
            }

            // &.swal2-default-outline {
            //     &:focus {
            //         box-shadow: $swal2-button-focus-box-shadow;
            //     }
            // }

            // &:focus {
            //     outline: $swal2-button-focus-outline;
            // }
        }

        .swal2-radio {
            @include flex(column, space-around, flex-start, nowrap);
            position: relative;

            label {
                margin: 0;
                width: 100%;
            }

            input {
                border: 0;
                clip: rect(0 0 0 0);
                height: 0.1 rem;
                margin: -0.1 rem;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 0.1 rem;
            }

            span {
                margin-bottom: 1.5rem !important;
                display: block;
                position: relative;
                padding: 0 0 0 3.6rem;
                font-family: NotoSans-Medium;
                font-size: 1.6rem;
                line-height: normal;
            }

            span:before {
                left: 0;
                content: "";
                display: inline-block;
                width: 2.4rem;
                height: 2.4rem;
                background-color: map-get($colour-palette, open-white);
                margin-right: 1.6rem;
                position: absolute;
                top: 0rem;
                z-index: 1;
                border: 0.1rem solid map-get($colour-palette, open-green);
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                background-clip: padding-box;
            }

            span:after {
                content: "";
                display: inline-block;
                width: 1.6 rem;
                height: 1.6 rem;
                background-color: #005353;
                position: absolute;
                border-radius: 50%;
                top: 0.5 rem;
                left: 0.5 rem;
                z-index: 1;
            }

            input:checked+span:before {
                border-width: 0.1rem;
                border-color: map-get($colour-palette, open-green);
            }

            input:focus+span:before {
                outline: 1px solid black;
            }

            input:checked+span:after {
                content: "";
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background-color: map-get($colour-palette, open-green);
                position: absolute;
                border-radius: 50%;
                top: 0.5rem;
                left: 0.5rem;
                z-index: 1;
            }
        }

        .swal2-input-label {
            @include flex(column, flex-start, flex-start, nowrap);
            margin-left: 1.25em;
            font-family: "Roboto", sans-serif;
            font-size: 1.6rem;
            line-height: normal;
            font-weight: bold;
            width: 100%;
        }

        .swal2-textarea {
            max-width: 100%;
            height: 150px;
            min-height: 150px;
            border-radius: 5px;
            vertical-align: top;
            background-color: map-get($colour-palette, open-white);
            border: 1px solid map-get($colour-palette, open-brand-primary);
            color: map-get($colour-palette, open-text-primary);
            resize: vertical;
            font-family: montserrat, sans-serif;
            font-size: 1.4rem;
            @include boxSizing(border-box);

            @media (max-width: $tablet-width) {
                width: 100%;
            }

            margin: 1em 1.5em 3px;
        }

        .swal2-validation-message {
            color: map-get($colour-palette, open-text-primary);
            font-family: "Roboto", sans-serif;
            font-size: 1.4rem;
            line-height: normal;
            font-weight: bold;
        }

        .swal2-html-container {
            font-family: NotoSans-Medium;
            font-size: 1.6rem;
            line-height: normal;
        }

        .swal2-close {
            position: absolute;
            top: 2rem;
            right: 2rem;
        }
    }
}

.swal2-container.swal2-bottom-end.autosave-container {
    width: 100%;

    & .swal2-popup {
        border-radius: 0;
    }

    & .swal2-title {
        font-size: 1.4rem !important;
    }
}