.sbLegalViewContainer {
    @include flex(row, space-between, center, wrap);
    width: 100%;
    max-height: 50px;
    background-color: map-get($colour-palette, open-white);
    padding: 2rem 0;
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);

    .companyName {
        margin-left: 2rem;
        color: map-get($colour-palette, open-brand-primary);
    }

    .statusLabel {
        @include flex(row, space-between, center, wrap);
        .button {
            margin-left: 2rem;
            min-width: max-content;
        }
        margin-right: 2rem;
    }
}

.sbContentHeader {
    @include flex(row, flex-end, center, wrap);
    width: 100%;
    max-height: 50px;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    padding: 2rem 0;
    margin-bottom: 3rem;

    #esHeaderLabel-statuslabel {
        margin-right: 2rem;
    }

    .headerEvaluationContainer {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        margin-left: 2rem;

        p {
            color: map-get($colour-palette, open-brand-primary);
        }
    }

    .headerButtonContainer {
        @include flex(row, flex-end, center, nowrap);
        .button {
            margin-right: 2rem;
            width: auto;
            min-width: 130px;
        }
        // .standstill-end-label {
        //     margin-right: 2rem;
        // }
        #colorRed{
            margin-right: 1rem;
            color: red;
        }
        #addPadding{
            margin-right: 1rem;
        }
    }
}

.sbContentSection {
    @include flex(column, flex-start, center, nowrap);
    width: 100%;
    // background-color: map-get($colour-palette, open-white);
    // border-radius: 10px;
    // border: 1px solid map-get($colour-palette, open-content-container-border);
    // margin-bottom: 2rem;
    padding: 0;
    @include boxSizing(border-box);
    flex: none;

    .sbContentBody {
        @include flex(column, flex-start, center, nowrap);
        width: 100%;
        // padding: 1rem 0;

        #sbAccordion,
        #sbAccordion_Supplier {
            width: 100%;
            max-width: 100%;

            .break {
                flex-basis: 100%;
                height: 0;
            }

            .timestamp-container {
                text-align: left;
            }

            .accordion-section-content {
                &.open {
                    max-height: none !important;
                }
            }
            .accordion-section {
                margin-bottom: 3rem;
                border: none;

                .supplierLevel {
                    margin-bottom: 2rem;
                    border-bottom: 2px solid map-get($colour-palette, open-divider);
                    overflow-y: auto;

                    .headerLevel {
                        padding-left: 4rem;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &-header {
                    border-radius: 5px;
                    padding: 0 2rem;
                    background-color: none;
                    height: 55px;
                    &.contentOpen {
                        border-radius: 5px 5px 0 0;
                    }

                    &-data {
                        pointer-events: none;

                        .headerTitle,
                        .headerLevel {
                            margin-right: 2rem;
                            color: map-get($colour-palette, open-brand-primary);
                        }

                        .left-align-header {
                            text-align: left;
                        }

                        .sbAccordionSectionHeaderAddon {
                            @include flex(row, space-between, center, nowrap);

                            .spacer,
                            .label {
                                padding: 0 1rem;
                            }

                            .label {
                                padding-right: 1rem;
                                font-size: 1.4rem;
                                color: map-get($colour-palette, open-text-primary);
                            }

                            @media (max-width: $mobile-width) {
                                width: auto;
                            }

                            .button {
                                width: 125px;
                                pointer-events: all;
                            }
                        }
                    }
                }

                &-content {
                    &.open {
                        // margin-top: 1rem;
                        border-top: 2px solid map-get($colour-palette, open-divider);
                        border-radius: 0 0 5px 5px;
                        overflow-y: auto;
                    }

                    &-data {
                        @include flex(column, flex-start, center, nowrap);
                        @include boxSizing(border-box);

                        .sbAccordionSectionContentRow {
                            @include flex(row, space-between, center, wrap);
                            width: 100%;

                            .sbAccordionSectionContentData {
                                @include flex(column, flex-start, flex-start, wrap);
                                padding: 1.5rem 5rem;
                                .label {
                                    margin-right: 1rem;
                                    color: map-get($colour-palette, open-text-primary);
                                    font-size: 1.4rem;
                                }
                                > p {
                                    padding-bottom: 1rem;
                                }
                            }
                            .lotLevelAdditionalPadding {
                                padding-left: 8rem;
                            }
                            .sbAccordionSectionContentAward {
                                @include flex(column, flex-start, center, nowrap);
                                width: 100%;
                                padding: 1rem 2rem;

                                .sbAccordionSectionContentAwardData {
                                    @include flex(column, flex-start, flex-start, wrap);
                                    padding: 1.5rem 2rem;
                                    width: 100%;
                                    .label {
                                        margin-right: 1rem;
                                        color: map-get($colour-palette, open-text-primary);
                                        font-size: 1.4rem;
                                    }

                                    > p {
                                        padding-bottom: 1rem;
                                    }

                                    .sbAccordionSectionContentDataAwardRow {
                                        @include flex(row, flex-start, center, nowrap);
                                        padding-bottom: 1.5rem;

                                        > p {
                                            color: map-get($colour-palette, open-green);
                                        }

                                        .statuslabel-content {
                                            margin: 0 1.5rem;
                                        }
                                        .button {
                                            padding: 0 3rem;
                                            margin-right: 3rem;
                                        }
                                    }

                                    .sbAccordionSectionContentDataUpload {
                                        @include flex(row, flex-start, center, nowrap);
                                        width: 100%;

                                        .uploadContainer {
                                            width: auto;
                                        }
                                    }
                                }

                                .sbAccordionSectionContentDataFooter {
                                    @include flex(row, flex-end, flex-end, nowrap);
                                    width: 100%;
                                    .button {
                                        padding: 0 3rem;
                                    }
                                    .button:first-child {
                                        margin-right: 3rem;
                                    }
                                }
                            }

                            .sbAccordionSectionContentAddon {
                                @include flex(row, space-between, center, nowrap);
                                padding: 1.5rem 2rem;

                                .label {
                                    padding: 0 1rem;
                                    font-size: 1.4rem;
                                    color: map-get($colour-palette, open-text-primary);
                                    min-width: max-content;
                                }

                                @media (max-width: $mobile-width) {
                                    width: auto;
                                }

                                .button {
                                    min-width: max-content;
                                    margin-left: 1rem;
                                }
                            }
                            .splitter {
                                margin: 0 2rem;
                                width: 98%;
                                border-bottom: 2px solid map-get($colour-palette, open-divider);
                            }
                        }

                        .intentStatusButtonContainer {
                            @include flex(row, flex-end, center, nowrap);
                            width: 100%;
                            margin: 1rem;
                            .button:last-child {
                                margin-right: 3rem;
                                margin-left: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }

    .sbAccordionSectionContentDataUpload {
        padding-top: 2rem;
        width: 100%;
        @include flex(row, flex-start, center, nowrap);
        margin-left: 5rem;
        .sbAccordionSectionContentDataAwardRow {
            width: 100%;
            margin-bottom: 2rem;
            @include flex(column, flex-start, flex-start, wrap);
            div:not(:last-child) {
                margin-bottom: 1rem;
            }
            .flex-break {
                flex-basis: 100%;
                height: 0;
                margin: 0 0;
            }
            .sbAccordionSectionContentDataAwardRowDPS {
                width: 100%;
                @include flex(row, flex-start, flex-start, wrap);
                .button:not(:last-child) {
                    margin-right: 2rem;
                }
            }
            .button {
                min-width: max-content;
            }
        }
    }
    .sbAccordionSectionContentDataUploadProject {
        width: 100%;
        @include flex(row, flex-start, center, nowrap);
        border-top: 2px solid #ebebeb;
        border-radius: 0 0 5px 5px;
        overflow-y: auto;
        background-color: white;
        .marginContainer {
            width: 100%;
        }
        .sbAccordionSectionContentDataAwardRowProject {
            width: 100%;
            margin-bottom: 2rem;
            @include flex(column, flex-start, flex-start, wrap);
            div:not(:last-child) {
                margin-bottom: 1rem;
            }
            .uploadContainer .upFormInputContainer {
                padding: 2rem 2rem 0rem 2rem;
                .flex-break {
                    flex-basis: 100%;
                    height: 0px;
                    margin: 0;
                }
            }
            .statuslabel-content {
                margin: 2rem;
            }
        }
    }
}

.standstill-container {
    .labelled-radio-button {
        margin-bottom: 2rem;
    }
    .standstill-form-item {
        margin-top: 2rem;
    }
    .standstill-form-label {
        margin-bottom: 1rem;
    }
    .standstill-date-input {
        width: 250px;
        max-width: 250px;
    }
    .formateDate{
        white-space: nowrap;
    }
    .optional{
        color: gray;
    }
}

.disqualifySuppliersModal {
    .disqualifySelect {
        margin-top: 1rem;
        margin-bottom: 2rem;

        p {
            margin-bottom: 1rem;
        }
    }

    .disqualifyItem {
        margin-bottom: 1rem;

        .supplierTitle {
            margin-top: 1rem;
        }

        p {
            margin-bottom: 1rem;
        }

        #reason-textarea {
            width: 100%;
            height: 130px;
        }

        .inputContainer {
            @include flex(row, space-between, center, wrap);

            .labelled-input {
                flex-grow: 2;
                margin-right: 1rem;
            }
        }
    }
}

#sendAwardModal {
    .button {
        margin-top: 1rem;
    }
}

