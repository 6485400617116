#messengerPanel,
#messengerChatPanel {
  @include flex(column, flex-start, flex-start nowrap);
  top: 100px;
  bottom: 20px;

  @media (max-width: $mobile-width) {
    top: 0;
    bottom: 0;
  }
}
.messengerPanelHeader {
  p:nth-child(2) {
    width: 80%;
  }
  @include flex(row, space-between, center, nowrap);
  padding: 2rem;
  border-top-left-radius: 10px;
  @include boxSizing(border-box);

  .closeBtn {
    padding: 0;
    border: none;
    background: none;
    &-img {
      cursor: pointer;
    }
  }

  .backIcon {
    padding: 0;
    border: none;
    background: none;
    &-img {
      cursor: pointer;
    }
  }

  @media (max-width: $mobile-width) {
    padding: 2rem 1rem;
  }

  @media (max-width: $small-mobile-width) {
    padding: 1rem;
  }
}

.messengerPanelContent {
  flex: 1;
  padding: 2rem;
  height: calc(100vh - 430px);
  overflow-y: auto;
  @include boxSizing(border-box);
  border-top: 1px solid map-get($colour-palette, open-content-container-border);
  border-bottom: 1px solid map-get($colour-palette, open-content-container-border);

  @media (max-width: $mobile-width) {
    padding: 2rem 1rem;
  }
}

.messengerPanelFooter {
  .labelled-textarea {
    max-height: 120px;
    height: 120px;
    max-width: 360px;
    width: 100%;
  }
  .labelled-textarea .form-textarea {
    background-color: rgba(map-get($colour-palette, open-footer-border), 0.15);
  }
  @include flex(column, space-around, flex-end, nowrap);
  padding: 2rem 2rem 3rem;
  border-bottom-left-radius: 10px;
  @include boxSizing(border-box);
}
.mpanel-item {
  @include flex(row, space-between, stretch, wrap);
  width: 100%;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border: none;
  background: none;
  border-bottom: 1px solid map-get($colour-palette, open-brand-primary);

  div {
    @include flex(row, space-between, stretch, wrap);
  }
}
.msgCard {
  @include boxSizing(border-box);
  max-width: 360px;
  width: 100%;
  height: auto;
  word-break: break-word;
  padding: 1rem;
  margin-bottom: 3rem;
  background-color: rgba(map-get($colour-palette, open-footer-border), 0.15);
  p:nth-child(3) {
    padding-top: 2rem;
  }
}
.msgCard:first-of-type {
  margin-bottom: 0;
}
.msgCard:last-of-type {
  margin-top: 1rem;
}

#chatPanel {
  @include flex(column-reverse, end, center, nowrap);
}
