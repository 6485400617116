.ssTimerContainer {
  width: 100%;
  @include flex(row, center, center, nowrap);
  // margin-bottom: 1rem;

  @media (max-width: $tablet-width) {
      @include flex(row, center, center, wrap);
  }

  .timerDivider {
    padding: 1rem 0;
    border-right: 1px solid map-get($colour-palette, open-divider);
  }

  .timerTile {
    @include flex(row, center, center, nowrap);
      background-color: map-get($colour-palette, open-white);
      // padding: 2rem;
      margin: 1rem;
      // color: map-get($colour-palette, open-brand-primary);
      text-align: center;

      #time {
        color: map-get($colour-palette, open-brand-primary);
        margin-right: 0.5rem; 
      }

      &.content {
          white-space: nowrap;

          @media (max-width: $tablet-width) {
              padding: 2rem;
          }
      }
  }
}