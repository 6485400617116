.selectQuestionnaireContainer {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  // -webkit-box-sizing: border-box;
  // -moz-box-sizing: border-box;
  // box-sizing: border-box;
}

.sqButtonContainer {
  @include flex(row, space-between, center, nowrap);

  .button {
    margin-left: 2rem;
  }
}

.sqSummaryContainer {
  @include flex(row, space-between, center, nowrap);
  width: 100%;
  margin-bottom: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid map-get($colour-palette, open-divider);

  p {
    color: map-get($colour-palette, open-brand-primary);
  }
}

.sqSelectionPanel {
  @include flex(row, flex-start, center, nowrap);
  background-color: map-get($colour-palette, open-white);
  height: 60px;
  width: 100%;
  margin-bottom: 2rem;
  border-radius: 10px;

  button {
    margin-left: 2rem;
    width: fit-content;
  }
}

.sqSelectedContainer {
  @include flex(column, flex-start, center, nowrap);
  width: 100%;
  min-height: 135px;
  max-height: 300px;
  overflow: auto;
  margin-top: 3rem;
}

.sqSelectedRow {
  @include flex(column, flex-start, center, nowrap);
  min-height: 40px;
  width: 95%;
  padding: 2rem 0;
  margin-bottom: 1rem;
  border: 1px solid map-get($colour-palette, open-brand-primary);
  border-radius: 5px;
}

.sqSelectedHeader {
  @include flex(row, space-between, center, nowrap);
  width: 95%;
}

.sqSelectedLots {
  @include flex(row, flex-start, center, wrap);
  width: 95%;
}

.bqLotModal {
  padding: 1rem 0;
  border-bottom: 1px solid map-get($colour-palette, open-divider);

  &:last-child {
    border-bottom: 0px;
  }
}

#selectQuestionnaires {
  height: auto;
}