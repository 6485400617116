.overview-section {
    width: 400px;
    height: 400px;
    background-color: map-get($colour-palette, open-white);
    border: 1px solid map-get($colour-palette, open-content-container-border);
    border-radius: 10px;
    margin-top: 42px;
    margin-right: 55px;
    overflow-y: auto;
    padding: 20px;
    display: inline-block;
}

.overview-header{
    border-bottom: 1px solid map-get($colour-palette, open-divider);
    padding-bottom: 10px;
    display:flex;
}


#overview-title{
    display: flex;
    justify-content: flex-end;
    margin-right: 200px;
}