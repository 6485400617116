.evaluation-summary-tree {
    width:calc(100% - 4rem);
    margin-top:2rem;
    padding-bottom: 2rem;
    & div[aria-level='0'] > a {
        color: #2c4874;
        font-size: 1.8rem;
        font-weight: bold;
        flex-wrap: wrap;
        & .tree-view-menu {
            margin-left: inherit;
            margin-right: auto;
        }
    }
    & div[aria-level='1'] > a {
        padding-left:2rem;
        font-weight: 500;
        flex-wrap: wrap;
        &  .tree-view-menu {
            margin-left: inherit;
            margin-right: auto;
        }
    }
    & div[aria-level='2'] > a {
        padding-left:4rem;
        color:#2c4874;
        font-weight: 600;
        row-gap: 1rem;
        & .button {
            width: auto;
            padding-right: 2rem;
            padding-left: 2rem;
        }
        @media only screen and (max-width : 700px) {
            padding-left:2rem;
        }
    }
    & div[aria-level='2'] .tree-view-menu span {
        color:#0b0c0c;
        font-weight: normal;
    }
    & div[aria-level='3'] {
        & > div {
            padding-left: 6rem;
            background: #f2f6f9;
            padding-right: 2rem;
            border-bottom: 1px solid #fff;
            flex-wrap: wrap;
            row-gap: 2rem;
            @media only screen and (max-width : 700px) {
                padding-left:2rem;
            }
        }
        & .tree-view-child {
            @media only screen and (max-width : 700px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        & .tree-view-menu {
            @media only screen and (max-width : 700px) {
                flex-wrap: wrap; 
                align-items: flex-start;
            }
        }
        
    }
   
    
    & .tree-viewer-item > a .tree-view-menu {
        margin-left: auto;
        margin-right: inherit;
        
        @media only screen and (max-width : 700px) {
            display: block;
            & > div {
                margin-bottom:1rem;
            }
        }
    }
    & .redButton {
        background: #ce0000;
        color: #fff;
    }
    & .statuslabel-txt {
        font-size:1.6rem;
    }
    & .user-icon{
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    & .submission-label span {
        display: block;
        margin-top: 0.5rem;
        font-weight: normal;
        font-size:1.3rem;
    }
    & .title {
        font-family:'NotoSans-Regular';
    }
    &.statuslabel-txt-container a 
    {
    text-transform: capitalize;
    }
}