#questionnaireQuestionForm {
    .modal {
        width: 500px;
        height: 850px;
        max-height: 100%;
        max-width: 100%;
    }

    #description-textarea {
        width: 100%;
    }

    #questionFileUploadInput-input {
        border: 1px solid map-get($colour-palette, open-green);
        border-radius: 10px;
        border-style: dashed;
        height: 100px;
    }

    .labelled-radio.radio-button-group.row {
        flex-wrap: wrap;
        .form-radio-button-container {
            width: 120px;
            margin-right: 3rem;
        }
    }

    #questionWeightageContainer {
        @include flex(row, flex-start, flex-end, nowrap);

        input {
            width: 200px;
        }

        p {
            padding-bottom: 1rem;
            margin-left: 1rem;
        }
    }

    #responseOptionInput-0-input {
        width: 90%;
    }

    .responseOptionsInputRow {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        margin: 0.5rem 0;

        .labelled-input {
            width: 100%;
        }

        input[id^="responseOptionInput-"] {
            width: 100%;
        }

        button {
            width: 30px;
            margin-left: 1rem;
            background: none;
            border: none;
        }

        .responseOptionsDeleteBtn {
            pointer-events: none;
        }
    }

    #addAnotherOptionBtn {
        width: auto;
        margin-top: 1rem;
    }
}
