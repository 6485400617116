.customButtonStyle{
    margin-right: 1rem;
}

#addSupplierToOpportunityButton{
    width: fit-content;
}

#cleverQuotesContentHeader{
    @include flex(row, flex-end, center, wrap);
}
