#dCFormContent {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: nowrap;

    .button.secondary {
        margin-right: 4rem;
    }

    @media (max-width: 840px) {
        .datePickerWrapper {
            max-width: 250px;
            width: 100%;
        }
    }

    .datePickerWrapper {
        width: 350px;
    }

    // TODO Remove when the Table global value is removed
    .labelled-input .form-input {
        height: 40px;
        width: 350px;
    }
    .textIconContainer {
        @include flex(row, flex-start, center, nowrap);
    }
    .sectionContentTags {
        @include flex(row, flex-start, flex-start, wrap);
    }
    #lotSectionContent {
        border-top: 0px !important;
        .sectionHeader {
            margin-bottom: 0;
        }
    }
    #responsibleOfficer,#contractManager{
        width: 350px;
        @media (max-width: $tablet-width) {
            width: 250px;
        }
        @media (max-width: $mobile-width) {
            width: 50%;
        }
    }
}
#addSupplierModalCR {
    #addAnotherSupplierBtn {
        width: 100%;
    }

    .crossSupplier {
        @include flex(row, space-between, center, nowrap);
        .labelled-input {
            width: 85%;
        }
        .remove {
            width: auto;
            .button-icon {
                margin: 0;
            }
        }
    }
}
#contractDescription{
    width: 100%;
    .labelled-textarea {
        width: 66.6%;
        #description-textarea{
            width: 100%;
        }
    }
}

#supplierSectionContent{
    padding: 1rem 0;
    border-top: 1px solid #ebebeb;
    width: 100%;
}
