.labelled-select {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;

    .form-label {
        margin-bottom: 1rem;
        font-weight: bold;
    }

    .form-select {
        width: 100%;
        max-width: 350px;
        height: 40px;
        border-radius: 5px;
        border: 1px solid map-get($colour-palette, open-brand-primary);
        padding: 0 5rem 0 1rem;
        background-image: url("../../styles/images/dropdownIcon.svg");
        background-repeat: no-repeat;
        background-position: right 1rem center;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

    .form-select option:disabled { color: gray; }
}
