#evaluationContainer {
  @include flex(column, flex-start, center, nowrap);
  flex: 1;
  width: 100%;
  border-radius: 10px;
  background-color: map-get($colour-palette, open-white);
  border: 1px solid map-get($colour-palette, open-content-container-border);

  p {
      margin: 4rem 0 0 0;
  }
}

.sbContentSection {
  #sbStatusLabel {
    min-width: 152px;
  }
}

#asHeaderLabel-statuslabel {
  margin-right: 1rem;
}