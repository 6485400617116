.homepageBannerContainer {
    background-color: map-get($colour-palette, open-base-blue);
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding: 1.5rem;
    position: relative;
    z-index: 1;
    text-align: center;
    color: map-get($colour-palette, open-brand-primary);
    align-items: center;
    gap: 3rem;

    @media (max-width: $mobile-width) {
        flex-direction: column;
        gap: 1.5rem;
    }

    & .hpBannerText1 {
        font-size: 1.6rem;
        line-height: 1.5;
    }

    & .hpBannerTextBold {
        font-weight: bold;
    }

    & .button {
        background-color: transparent;
        border: 1px solid map-get($colour-palette, open-brand-primary);
        border-radius: 50px;
        min-width: 120px;
        color: map-get($colour-palette, open-brand-primary);
    }
}