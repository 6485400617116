.tag-content {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    max-width: 250px;
    min-height: 50px;
    background: #78cbc3; //map-get($colour-palette, open-white);
    border: 1px solid map-get($colour-palette, open-jade);
    border-radius: 50px;
    padding: 0.5rem 1rem;
    color: map-get($colour-palette, open-white);
    margin-right: 5px;

    @include boxSizing(border-box);

    .tag-del-btn-wrapper {
        // margin-right: 2rem;
        border: none;
        background: none;
    }

    .tag-delete-btn {
        cursor: pointer;
    }

    @media (max-width: $mobile-width) {
        max-width: 220px;
        width: 100%;
        min-height: 40px;
    }

    &.small {
        width: 100%;
        max-width: 200px;
        max-height: 35px;
        margin: 0.5rem;
        padding: 1rem;

        .tag-del-btn-wrapper {
            margin-right: 0rem;
            border: none;
            background: none;
        }
        @media (max-width: $mobile-width) {
            max-height: 100%;
        }
    }

    &.large {
        width: 100%;
        max-width: 325px;
        min-height: 50px;
        padding: .5rem 1rem;

        @media (max-width: $mobile-width) {
            max-width: 275px;
            width: 100%;
            min-height: 40px;
        }

        &.with-margins {
            margin: 0 20px 30px 0px;
        }
    }

    &.xlarge {
        width: 100%;
        max-width: 400px;
        height: 50px;
        padding: 1rem 2rem;

        @media (max-width: $mobile-width) {
            max-width: 350px;
            width: 100%;
            height: 40px;
        }
    }
}
.tag-txt-container{
    margin-left: .6rem;
}