.mainContent {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    max-width: 1280px;
    flex: 1;
    margin: 10rem 0 3rem 0;
    padding: 0 3rem;
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
        margin-top: 8rem;
        margin-bottom: 2rem;
        padding: 0 2rem;
    }

    @media (max-width: $mobile-width) {
        margin-top: 8rem;
        margin-bottom: 1rem;
        padding: 0 1rem;
    }
}

.m-50 {
    margin-top: 50px;
}

.nepo-container {
    @include flex(column, flex-start, stretch, nowrap);
    width: 100%;
    flex: 1;
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;
    border: 1px solid map-get($colour-palette, open-content-container-border);
    padding: 2rem;
    @include boxSizing(border-box);
}

.select-search__input {
    font-family: montserrat, sans-serif !important;
}

// Override react-daterange-picker package styles
.customDateInput {
    width: 100%;
    height: 40px;
    padding: 0.5rem;
    border-radius: 5px;
    background-color: map-get($colour-palette, open-white);
    color: map-get($colour-palette, open-text-primary);
    border: 1px solid map-get($colour-palette, open-brand-primary);
    font-family: montserrat, sans-serif;
    font-size: 1.4rem;

    .react-daterange-picker__wrapper {
        border: none;
        font-family: montserrat, sans-serif;
        font-size: 1.6rem;

        .react-daterange-picker__inputGroup {
            text-align: center;
        }

        .react-daterange-picker__range-divider {
            padding: 0 1rem;
        }

        .clearIcon {
            vertical-align: middle;
        }

        @media (max-width: $mobile-width) {
            font-size: 1.4rem;

            .react-daterange-picker__range-divider {
                padding: 0;
            }
        }
    }

    .react-calendar {
        font-family: montserrat, sans-serif;
        font-size: 1.4rem;

        @media (max-width: $tablet-width) {
            max-width: 250px;
            width: 100%;
        }

        @media (max-width: $mobile-width) {
            max-width: 230px;
            width: 100%;
        }

        button {
            font-family: montserrat, sans-serif;
            font-size: 1.4rem;
        }

        .react-calendar__tile--now {
            color: map-get($colour-palette, open-white);
            background-color: map-get($colour-palette, open-green);
        }

        .react-calendar__tile--hasActive {
            color: map-get($colour-palette, open-white);
            background-color: map-get($colour-palette, open-green);
        }
    }

    .react-date-picker__calendar,
    .react-time-picker__clock {
        z-index: 2 !important;
    }

    .react-date-picker__wrapper,
    .react-time-picker__wrapper {
        border: none !important;
        font-family: montserrat, sans-serif !important;
        font-size: 1.6rem !important;


        .react-date-picker__inputGroup input,
        .react-time-picker__inputGroup input {
            border: none !important;
            text-align: center !important;
            // padding: 0 1rem !important;
            height: 30px !important;
        }

        .react-date-picker__inputGroup__leadingZero {
            margin-left: 18px;
        }

        .react-date-picker__inputGroup__day,
        .react-date-picker__inputGroup__month,
        .react-time-picker__inputGroup__hour,
        .react-time-picker__inputGroup__minute {
            width: 50px !important;

            @media (max-width: $mobile-width) {
                padding: 0 !important;
            }

            &.react-date-picker__inputGroup__input--hasLeadingZero {
                width: 30px !important;
            }
        }

        .react-date-picker__inputGroup__year {
            width: 61px !important;

            @media (max-width: $mobile-width) {
                padding: 0 !important;
            }
        }

        .react-date-picker__inputGroup__divider,
        .react-time-picker__inputGroup__divider {
            padding: 0 0.5rem;
        }

        .clearIcon {
            vertical-align: middle;
        }

        @media (max-width: $mobile-width) {
            font-size: 1.4rem;

            .react-date-picker__inputGroup__divider {
                padding: 0;
            }
        }
    }
}

.loader {
    border: 4px solid map-get($colour-palette, open-light-grey);
    border-top: 4px solid map-get($colour-palette, open-green);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 2s linear infinite;
    z-index: 99;
    position: fixed;
    left: 50%;
    top: 50%;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.sectionTitle {
    border-bottom: 1px solid #ebebeb;
    margin-bottom: 2rem;
    padding-bottom: 2rem;
    width: 100%;
}

.sectionHeader {
    margin-bottom: 2rem;
}

.label-margin {
    margin-bottom: 1rem;
}

.actionButtonContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-end;
    flex-wrap: nowrap;
}

.bold {
    font-weight: bold;
}

.xx-large-title {
    font-size: 2.2rem;
}

.justify-content {
    text-align: justify;
}

.textIconContainer {
    .sectionHeader {
        font-weight: bold;
    }
}

.datePickerLabel,
.selectSearchTitle,
.dateRangeBetween {
    margin-bottom: 1rem;
    font-weight: bold;
}

.keyInfo {
    font-weight: bold;
    color: map-get($colour-palette, open-brand-primary) !important;
}

.screenTitleHeading {
    font-weight: bold;
    color: map-get($colour-palette, open-brand-primary);
}

.dashboardHeaderText {
    .headerTitle {
        font-weight: bold;
        color: map-get($colour-palette, open-brand-primary);
    }

    // .guidanceText{
    //     color: map-get($colour-palette, open-jade)!important;
    // }
}

.disabled {
    cursor: not-allowed;
    opacity: 0.7;
    outline: none;
}

.itemBody {
    .sectionHeader {
        font-weight: 600;
        color: map-get($colour-palette, open-brand-primary);
    }
}

.justify {
    text-align: justify;
}

#guidanceBanner-supplierCodeInfo,
#guidanceBanner-supplierRegionInfo {
    margin-bottom: 2rem;
}

.rbc-allday-cell {
    z-index: 0;
}

.newOrUnread {
    @include flex(row, center, center, nowrap);

    &.tag {
        background-color: map-get($colour-palette, open-yellow);
        border-radius: 5rem;
        width: 20px;
        height: 20px;
        color: white;
    }
}

.newOrUnreadTitleMargin {
    margin-left: 1rem;
}

.line-break {
    white-space: pre-line;
}

.formatted-html {
    strong {
        font-weight: bold;
    }

    em {
        font-style: italic;
    }

    ins {
        text-decoration: underline;
    }

    ul {
        display: block;
        list-style-type: disc;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        unicode-bidi: isolate;

        ul {
            list-style-type: circle;
        }
    }

    ol {
        display: block;
        list-style-type: decimal;
        margin-block-start: 0;
        margin-block-end: 0;
        margin-inline-start: 0;
        margin-inline-end: 0;
        padding-inline-start: 40px;
        unicode-bidi: isolate;
    }

    li {
        display: list-item;
        text-align: -webkit-match-parent;
        unicode-bidi: isolate;
    }

    li::marker {
        font-size: 1.5rem;
    }

    p:empty {
        min-height: 2.4rem;
    }

}

.loading-page,
.error-page {
    @include flex(row, center, center, wrap);
    width: 100%;
    font-size: 14px;
}