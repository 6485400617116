.msDetailsContainer {
  @include flex(row, flex-start, center, wrap);

  @media (max-width: $mobile-width) {
    @include flex(row, center, center, wrap);
  }

  width: 100%;

  #supplierCard {
    max-width: 393px;
  }

  .manage-suppliers-card {
    margin-right: 2rem;

    &:last-child {
      margin-right: 0;
    }

    @media (max-width: $mobile-width) {
      margin-right: 0;
    }

    .dashboardCardHeader {
      min-height: 47.6px;
    }

    #cleverQuotesContent {
      @include flex(column, center, center, nowrap);
      #cleverQuotesBtn {
        margin-top: 2rem;
        width: fit-content;
      }
    }
  }

  #viewSupplier {
    width: 105px;
  }
}

.msSupplierRow {
  @include flex(column, space-between, flex-start, nowrap);
  min-height: 100px;
  padding: 1rem 0;
  border-bottom: 1px solid map-get($colour-palette, open-divider);
  &.supplierRowHeight{
    min-height: 80px;
  }
}

.msButtonContainer {
  @include flex(row, flex-end, center, nowrap);
  width: 100%;

  button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.msPendingButtonContainer {
  @include flex(row, space-between, center, nowrap);
  width: 100%;

  .msPendingActionButtonContainer {
    @include flex(row, flex-end, center, nowrap);
  }

  button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
