#corespondenceSectionWrapper {
    width: 100%;

    #correspondenceSection {
        @include flex(column, flex-start, flex-start, nowrap);
        @include boxSizing(border-box);
        width: 100%;
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;
        border: 1px solid map-get($colour-palette, open-content-container-border);
        padding: 2rem;
        word-break: break-word;
        #correspondenceHeader {
            @include flex(column, flex-start, flex-start, wrap);
            width: 100%;
            min-height: 50px;
           //  padding-bottom: 1.5rem;
            border-bottom: 1px solid map-get($colour-palette, open-divider);

            #correspondenceSubjectBar {
                @include flex(row, space-between, center, wrap);
                width: 100%;
                // padding-bottom: 1.5rem;
                // border-bottom: 1px solid map-get($colour-palette, open-divider);

                .title-xLarge {
                    color: map-get($colour-palette, open-brand-primary);
                }
            }

            // #correspondenceSubjectContent,
            #correspondenceSubjectDetails{
                margin-bottom: 1.5rem;
            }

            .vcButtonContainer {
                @include flex(row, flex-end, flex-end, nowrap);

                button {
                    margin-left: 1rem;
                }
            }
        }
        #correspondenceSubjectContent {
            margin-top: 1.5rem;
            #correspondenceContentButton {
                @include flex(row, center, center, wrap);
                margin-top: 1.5rem;
                width: 100%;
            }
        }
      
            #threadContanier {
                width: 100%;
                padding: 1.5rem 0;
                .threadContentItem {
                    margin-bottom: 3rem;
                    .threadMainContent {
                        border-radius: 5px;
                        padding: 1rem;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                        // min-height: 100px;
                        &.marginBottomFromOther{
                            margin-bottom: 2rem;
                        }
                        .threadHeader {
                            @include flex(row, space-between, center, nowrap);
                            // margin-bottom: 1rem;

                            p {
                                font-weight: bold;
                            }

                            .threadHeaderItems {
                                @include flex(row, center, nowrap);

                                button {
                                    margin-left: 1rem;
                                }
                            }
                        }

                        .threadContent {
                            margin-bottom: 1rem;
                        }

                        .threadButtonContanier {
                            margin-bottom: 1rem;
                            .topicButtonContanier {
                                @include flex(row, flex-start, center, nowrap);
                                button:not(:last-child) {
                                    margin-right: 1rem;
                                }
                            }
                        }
                    }

                    .threadMessageContent {
                        // margin-left: 4rem;
                        border-radius: 0 0 5px 5px;
                        overflow-y: auto;
                        // padding: 1rem;
                        box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
                        @media (max-width: $mobile-width) {
                            margin-left: 0rem;
                        }

                        .threadMessages {
                            margin: 2rem;
                            .messageItem {
                                min-height: 100px;
                                margin-top: 1rem;
                                border-radius: 5px;
                                padding: 1rem;
                                box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                                word-break: break-word;

                                &.recipient {
                                    background-color: map-get($colour-palette, open-light-grey);
                                    margin-right: 10rem;

                                    .uploadContainer {
                                        background-color: map-get($colour-palette, open-light-grey);
                                    }
                                }

                                &.sender {
                                    margin-left: 10rem;
                                }
                                .messageHeader {
                                    @include flex(row, space-between, center, nowrap);
                                    // margin-bottom: 1rem;

                                    p.title {
                                            font-weight: bold;
                                    }
                                }

                                .messageContent {
                                    margin-top: 2rem;
                                    word-wrap: break-word;
                                }

                                .messageDocuments {
                                    // border-top: 1px solid map-get($colour-palette, open-divider);
                                    .upFormInputContainer {
                                        .flex-break {
                                            height: 0;
                                            margin: 0;
                                        }
                                    }
                                }
                                &:last-child{
                                    margin-bottom: 2rem;
                                }
                            }
                        }
                    }
                }

                .threadResponse {
                    margin-top: 1rem;

                    .threadResponseWarning {
                        @include flex(row, center, center, nowrap);
                        width: 100%;
                        margin-bottom: 2rem;
                    }

                    .form-textarea {
                        width: 100%;
                    }
                    .upbuttonContainer {
                        @include flex(row, space-between, flex-end, nowrap);
                        .upFormInputContainer {
                            .flex-break {
                                height: 0;
                                margin: 0;
                            }
                        }

                        @media (max-width: $mobile-width) {
                            @include flex(column, center, center, nowrap);

                            #sendMessagebtn {
                                margin-top: 1rem;
                            }
                        }
                    }
                }

                #noThreads {
                    @include flex(row, center, center, wrap);
                }
            }
    }
}
.inlineTag{
    @include flex(row, center, center, wrap);
}
.newOrUnreadTimestampMargin{
    margin-left: 3rem;
}
.headerThreads{
    width: 100%;
}
.borderClass {
    border: 1px solid #2c4874;
}

#createNewCorrespondence {
    max-width: 800px;

    textarea {
        width: 100%;
    }
}

#reply-all-button {
    margin-right: 5px;
}

.messageContent .caption{
    white-space: pre-line;
}

.labelled-radio-button {
    margin-bottom: 15px;
}