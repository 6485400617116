.contractOverviewContainer {
  @include flex(row, space-between, stretch, wrap);
  width: 100%;

  #contractDetails {
    height: 100%;
    .dashboardCardMain {
      overflow: hidden;
      .sectionHeader {
        margin-top: 2rem;
        color: #2c4874;
      }
      .sectionContent {
        padding-top: 0;
        padding-right: 0;
        padding-left: 2rem;
        padding-bottom: 2rem;
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        .sectionRow.supplier {
          width: unset;
          max-width: unset;
          padding-right: 3rem;
          & > div {
            flex: 1 1 0;
          }
          .sectionRowPanel {
            display: flex;
            align-items: baseline;
            width: 50%;
            &>.title, &>.caption {
              line-height: 150%;
            }
            &>.title {
              white-space: nowrap;
            }
          }
          .sectionLabel, .sectionValue {
            width: unset;
            max-width: unset;
            display: block;
            margin-right: 2.5rem;
            word-wrap: normal;
            word-break: keep-all;
            &.larger {
              flex-grow: 1;
            }
          }
        }
      }
    }
  }

  .pill-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin: 0;
  }
  .lotSectionLabel {
    max-width: auto;
    font-family: Roboto,sans-serif;
    line-height: 1.6;
    font-size: 1.6rem;
    color: map-get($colour-palette, open-brand-primary);
    font-weight: 600;
   }
}
.cap-first-letters {
  text-transform: capitalize;
}