.codesNoResult {
  @include flex(column, space-around, center, nowrap);
  height: 100%;

  margin-top: 4rem;

  p {
    padding-left: 0.6rem;
  }
}

.codeResult {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  
  .codesOrigin {
    @include flex(row, flex-start, flex-start, nowrap);
    p {
      color: map-get($colour-palette, open-text-grey);
    }
  }
}