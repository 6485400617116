.panel-container {
    width: 100%;
    height: auto;
    margin-top: 30px;
    border-radius: 1rem;
    background-color: map-get($colour-palette, open-white);
    position: relative;

    .panel {
        border-radius: 10px;
        background-color: map-get($colour-palette, open-white);
        min-width: 250px;

        &-content {
            height: 100%;
            padding: 2rem;
        }

        &-header {
            @include flex(row, center, center, nowrap);
            width: 100%;
            height: 25px;
            border-bottom: 1px solid map-get($colour-palette, open-divider);
            border-radius: 10px 10px 0 0;
            position: relative;
            padding-bottom: 1rem;

            &-title {
                width: 100%;
            }
        }

        &-body {
            flex: 1;
            width: 100%;
            overflow-y: auto;
            overflow-x: hidden;
            margin: 2rem 0;
            padding: 0 0.1rem;
            @include boxSizing(border-box);

            &-title {
                font-size: 16px;
            }

            &-label {
                font-size: 14px;
            }
        }

        &-footer {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 2rem 3rem 2rem 3rem;
            @include boxSizing(border-box);
            @include flex(row, space-between, center, wrap);

            #main-action-btn {
                width: 100%;
                height: 40px;
            }

            @media (max-width: $mobile-width) {
                padding: 1rem 2rem 1rem 2rem;

                #main-action-btn {
                    width: 120px;
                    height: 35px;
                }
            }
        }
    }
    
    &.small {
        max-width: 400px;
        max-height: 400px;
    }

    &.medium {
        max-width: 790px;
        max-height: 400px;
    }

    &.large {
        width: 100%;
        max-height: 400px;
    }
}
