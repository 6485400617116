.tree-viewer-container {
    width: 100%;
    background: #fff;
    border-radius: 10px;
    padding: 0rem 2rem 0 2rem;
}

.tree-viewer-item > a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.4rem;
    padding: 2rem 0;
    border-bottom: 1px solid #d3d3d3;
    cursor: pointer;
    text-decoration: none;
    color:#0b0c0c;
    gap: 20px;
}
.tree-viewer-item > a .tree-view-menu {
    margin-right: auto;

}
.tree-view-child{
    font-size: 1.4rem;
    padding: 2rem 0;
    border-bottom: 1px solid #d3d3d3;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.tree-view-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
}
.tree-view-menu .btnSize {
        height: 30px!important;
        width: 130px!important;
        font-size:1.4rem!important;
}
.tree-view-menu .statuslabel-content {
    font-weight: normal;
    text-transform: capitalize;
}

