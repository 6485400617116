.navbar {
    @include flex(column, flex-start, center, nowrap);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 3;

    #skipper {
        color: map-get($colour-palette, open-white);
        height: 30px;
        left: 50%;
        padding: 8px;
        position: absolute;
        transform: translateY(-100%);
        transition: transform 0.3s;
      }
      
      #skipper:focus {
        transform: translateY(0%);
      }

    &-primary-navigation {
        @include flex(row, space-between, center, nowrap);
        background-color: map-get($colour-palette, open-brand-primary);
        background-image: radial-gradient(circle at center, #20b3aa -100%, #1f3765);
        width: 100%;
        height: 60px;
        padding: 0 4rem;
        @include boxSizing(border-box);

        @media (max-width: $tablet-width) {
            height: 80px;
            padding: 0 2rem;
            margin: 0;
        }

        @media (max-width: $mobile-width) {
            height: 80px;
            padding: 0 1rem;
            margin: 0;
        }

        .logo {
            @include flex(row, flex-start, center, nowrap);
            cursor: pointer;
            
            img {
                height: 50px;
            }

            @media (max-width: $tablet-width) {
                img {
                    height: 55px;
                }
            }

            @media (max-width: $mobile-width) {
                img {
                    height: 50px;
                }
            }

            // All beta CSS. Can be removed safely once out of beta.
            .betabox {
                background-color: map-get($colour-palette, open-white);
                padding: 0.4rem;
                border: 1px solid map-get($colour-palette, open-white);
                border-radius: 2px;
                color: map-get($colour-palette, open-brand-primary);
                margin-right: 1rem;
                display: none;

                // This is a custom width due to the size of the navbar.
                @media (max-width: 1100px) {
                    display: block;
                }

            }

            .betatext {
                color: map-get($colour-palette, open-white);

                a {
                    color: inherit;
                }

                display: none;

                // This is a custom width due to the size of the navbar.
                @media (max-width: 1100px) {
                    display: block;
                }

                @media (max-width: $mobile-width) {
                    position: absolute;
                    left: 1px;
                    top: 1px;
                }

                @media (max-width: 355px) {
                    // This conflicts with scroll to top, so we don't show
                    // any text at this size.
                    display: none;
                }
            }
        }

        #navMenuItems {
            @include flex(row, center, space-between, nowrap);
            @include boxSizing(border-box);
        }

        #additionalItems {
            @include flex(row, center, center, nowrap);
            @include boxSizing(border-box);

            @media (max-width: $tablet-width) {
                margin-right: 1rem;
            }

            #notificationWrapper {
                position: relative;
                @include flex(row, flex-start, center, nowrap);

                #notificationsContainer {
                    position: absolute;
                    top: 35px;
                    left: -175px;
                    width: 350px;
                    max-height: 470px;
                    overflow-y: auto;
                    z-index: 1;
                
                    @media (max-width: $tablet-width) {
                        left: -300px;
                    }
                }
            }

            #userNavMenuWrapper {
                position: relative;
                margin-left: 5rem;
                @include flex(row, flex-start, center, nowrap);

                @media (max-width: $tablet-width) {
                    display: none;
                }

                #userNavMenuContainer {
                    position: absolute;
                    top: 35px;
                    left: -70px;
                    width: 220px;
                    height: fit-content;
                    height: -moz-fit-content;
                    @include boxSizing(border-box);
                }
            }

            #loginBtn {
                width: 100px;
                margin: 0.25rem 10px;

                @media (max-width: $tablet-width) {
                    display: none;
                }
            }

            #getStartedBtn {
                border-radius: 50px;
                width: 120px;
                margin: 0.25rem 0;

                @media (max-width: $tablet-width) {
                    display: none;
                }
            }

            // @media (max-width: $tablet-width) {
            //     display: none;
            // }
        }
    }

    &-secondary-navigation {
        width: 100%;
        height: 40px;
        // background-color: map-get($colour-palette, open-jade);
        background-image: radial-gradient(circle at center, #20b3aa -100%, #1f3765);
        border-top: 1px solid white;

        // All beta CSS. Can be removed safely once out of beta.
        .betabar {
            @include flex(row, flex-start, center, nowrap);
            position: absolute;
            left: 5px;
            top: 64.2px;

            // This is a custom width due to the size of the navbar.
            @media (max-width: 1366px) {
                max-width: 300px;
            }

            @media (max-width: 1100px) {
                display: none;
            }

            .betabox {
                background-color: map-get($colour-palette, open-white);
                padding: 0.4rem;
                border: 1px solid map-get($colour-palette, open-white);
                border-radius: 2px;
                color: map-get($colour-palette, open-brand-primary);
                margin-right: 1rem;
            }

            .betatext {
                color: map-get($colour-palette, open-white);

                a {
                    color: inherit;
                }
            }
        }

        .items {
            @include flex(row, center, center, nowrap);
            list-style: none;
            margin: 0 2rem;
            padding: 0;
            height: 100%;
            @include boxSizing(border-box);
            flex: auto;
        }

        @media (max-width: $tablet-width) {
            display: none;
        }
    }
}
