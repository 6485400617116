@import '../../../styles/scss/variables.scss';

.errorMessages {
    padding: 1rem;
    margin: 1rem 0 2rem 0;
    border: 2px solid map-get($colour-palette, open-text-warning);
    border-radius: 1rem;

    width: 75%;

    min-width: 350px;

    @media (max-width: $tablet-width) {
        width: 100%
    }

    @media (max-width: $mobile-width) {
        width: 100%
    }

    h3.title-large {
        color: map-get($colour-palette, open-text-warning);
        margin-bottom: 1.5rem;
        padding-bottom: 1rem;
        font-weight: bold;
        border-bottom: 1px solid map-get($colour-palette, open-text-warning);
    }

    ul {
        li {
            margin-left: 2rem;
            list-style: disc;
            padding-bottom: 1rem;
            // padding-bottom: 1rem;
            color: map-get($colour-palette, open-text-grey);

            &:last-child {
                padding-bottom: .5rem;
            }
        }

    }
}