.button {
    @include flex(row, center, center, nowrap);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    width: 150px;
    height: 40px;
    transition: 0.2;

    @media (max-width: $tablet-width) {
        min-width: 120px;
        height: 35px;
    }

    @media (max-width: $mobile-width) {
        min-width: 100px;
        height: 30px;
    }

    &-icon {
        margin-right: 1rem;

        @media (max-width: $tablet-width) {
            height: 60%;
        }

        @media (max-width: $mobile-width) {
            height: 50%;
        }
    }

    &.primary {
        background-color: map-get($colour-palette, open-blue-90);
        color: map-get($colour-palette, open-white);
        
        &:hover, &:focus-visible{
            outline: 2.5px solid map-get($colour-palette, open-yellow-focus);
        }

        &.white {
            color: map-get($colour-palette, open-brand-primary);
            background-color: map-get($colour-palette, open-white);
            border: 1px solid map-get($colour-palette, open-brand-primary);
        }

        &.skinless {
            background: none;
            width: 120px;

            &:hover, &:focus-visible{
                outline: 1px solid #1B75BC;
            }

            &.white {
                color: map-get($colour-palette, open-jade);
                background-color: map-get($colour-palette, open-white);
                border: 1px solid map-get($colour-palette, open-jade); 
                &:hover, &:focus-visible{
                    outline: 1px solid #78cbc3;
                }
            }

            &.red {
                color: map-get($colour-palette, open-red);
                background-color: map-get($colour-palette, open-white);
                border: 1px solid map-get($colour-palette, open-red); 
                &:hover, &:focus-visible{
                    outline: 1px solid  map-get($colour-palette, open-brown-dark);
                }
            }

            &.green {
                color: map-get($colour-palette, open-text-success);
                background-color: map-get($colour-palette, open-white);
                border: 1px solid map-get($colour-palette, open-text-success); 
                &:hover, &:focus-visible{
                    outline: 1px solid  map-get($colour-palette, open-light-green);
                }
            }
        }

        &.red {
            background-color: map-get($colour-palette, open-red);
            color: map-get($colour-palette, open-white);
            border: none;
        }

    }

    &.secondary {
        border: 1px solid map-get($colour-palette, open-yellow);
        color: map-get($colour-palette, open-blue);
        background: map-get($colour-palette, open-yellow-light);

        &:hover, &:focus-visible {
            outline: 1px solid map-get($colour-palette, open-yellow-focus);
        }

        &.white {
            color: map-get($colour-palette, open-white)!important;
            border: 1px solid map-get($colour-palette, open-white);
            background: none;
            &:hover, &:focus-visible {
                outline: 2px solid map-get($colour-palette, open-yellow-focus);
            }
        }

        &.skinless {
            color: map-get($colour-palette, open-brand-primary);
            border: none;
            background: none;
            &:hover, &:focus-visible {
            outline: 2px solid map-get($colour-palette, open-yellow-focus);
            }
        }

        &.cancel {
            background-color: map-get($colour-palette, open-red);
            color: map-get($colour-palette, open-white);
            border: none;
            margin-right: 22px;
        }

        &.cancel:last-child {
            margin-right: 0px;
        }
    }


    &.tertiary {
        border: 1px solid map-get($colour-palette, open-yellow);
        color: map-get($colour-palette, open-blue);
        min-width: 120px;
        height: 30px;
        background: map-get($colour-palette, open-yellow-light);
        font-size: 1.4rem;

        &:hover, &:focus-visible {
            outline: 2px solid map-get($colour-palette, open-yellow-focus);
        }

        @media (max-width: $mobile-width) {
            width: 100px;
            font-size: 1.2rem;
        }

        &.worknoteView {
            background-color: map-get($colour-palette, open-white);
            color: map-get($colour-palette, open-text-primary);
            border-radius: 5px;
            height: 35px;
            margin-right: 70px;
        }

        &.worknoteAdd {
            background-color: map-get($colour-palette, open-brand-primary);
            color: map-get($colour-palette, open-white);
            border-radius: 5px;
            height: 35px;
        }
        &.riskStatus {
            border: none;
            border-radius: 50px;
            font-size: 1.4rem;
            height: 40px;
            min-width: 140px;

            &.default {
                background-color: map-get($colour-palette, open-platinum);
                color: map-get($colour-palette, open-text-risk);
            }

            &.low {
                background-color: map-get($colour-palette, open-text-success);
                color: map-get($colour-palette, open-white);
            }
            &.amber {
                background-color: map-get($colour-palette, open-medium-risk);
                color: map-get($colour-palette, open-white);
            }
            &.high {
                background-color: map-get($colour-palette, open-high-risk);
                color: map-get($colour-palette, open-white);
            }
            &.high {
                background-color: map-get($colour-palette, open-high-risk);
                color: map-get($colour-palette, open-white);
            }
            &.critical {
                background-color: map-get($colour-palette, open-critical-risk);
                color: map-get($colour-palette, open-white);
            }
        }
        &.creditScore {
            border: none;
            border-radius: 50px;
            font-size: 1.4rem;
            height: 40px;
            min-width: 140px;

            &.default {
                background-color: map-get($colour-palette, open-platinum);
                color: map-get($colour-palette, open-text-risk);
            }
            &.lowest {
                background-color: map-get($colour-palette, open-text-success);
                color: map-get($colour-palette, open-white);
            }
            &.low {
                background-color: map-get($colour-palette, open-light-green);
                color: map-get($colour-palette, open-white);
            }
            &.amber {
                background-color: map-get($colour-palette, open-medium-risk);
                color: map-get($colour-palette, open-white);
            }
            &.high {
                background-color: map-get($colour-palette, open-brown-dark);
                color: map-get($colour-palette, open-white);
            }
            &.highest {
                background-color: map-get($colour-palette, open-high-risk);
                color: map-get($colour-palette, open-white);
            }
        }
    }

    &.regular {
        width: 150px;
        height: 40px;
        font-size: 1.8rem;
    }

    &.medium {
        width: 120px;
        height: 35px;
        font-size: 1.6rem;

        &.tertiary {
            width: 100px;
            height: 20px;
        }
    }

    &.small {
        width: 100px;
        height: 30px;
        font-size: 1.4rem;

        &.tertiary {
            width: 80px;
            height: 20px;
        }

        &.edit {
            width: 40px;
            height: 20px;
        }
    }

    &.disabled {
        cursor: not-allowed;
        opacity: 0.7;
    }

    &.oneLineText {
        white-space: nowrap;
        text-align: center;
     }

     &.size-fit-text {
        width: fit-content;
        height: 35px;
        font-size: 1.6rem;        
     }
}