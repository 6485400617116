.footer {
  @include flex(row, flex-start, center, wrap);
  width: 100%;
  height: fit-content;
  height: -moz-fit-content;
  background-color: #FFFFFF;
  @include boxSizing(border-box);
  border-top: 1px solid map-get($colour-palette, open-footer-border);

  #footerLogo {
    padding: 1.5rem 4rem 1.5rem 3rem;
    cursor: pointer;
    @include boxSizing(border-box);

    img {
      height: 65px;
    }

    @media (max-width: $tablet-width) {
      padding-left: 2rem;
      padding-right: 0;

      img {
        height: 60px;
      }
    }

    @media (max-width: $mobile-width) {
      img {
        height: 55px;
      }
    }
  }

  #footerLinks {
    @include flex(row, flex-start, center, wrap);
    flex: 1;
    height: auto;
    min-width: 550px;
    @include boxSizing(border-box);

    .footerLink {
      padding: 1rem 4rem 1rem 0;
      font-family: 'Roboto', sans-serif;
      color: map-get($colour-palette, open-text-footer);
    }

    @media (max-width: $tablet-width) {
      min-width: 100%;
      padding-left: 2rem;
      :nth-child(1) {
        padding-left: 0;
      }

      .footerLink {
        padding-right: 2rem;
      }
    }

    @media (max-width: $mobile-width) {
      min-width: 100%;
      padding-left: 2rem;
    }
  }

  #copyrightTextContainer {
    @include flex(row, flex-end, center, nowrap);
    padding: 1.5rem 3rem 1.5rem 2rem;
    @include boxSizing(border-box);
    

    @media (max-width: $tablet-width) {
      padding-right: 2rem;
    }

    @media (max-width: $mobile-width) {
      padding-left: 2rem;
    }

    .caption {
      font-family: 'Roboto', sans-serif;
    }
  }
}
