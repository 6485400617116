.dashboardCard {
  @include flex(column, flex-start, flex-start, wrap);
  background-color: map-get($colour-palette, open-white);
  border: 1px solid map-get($colour-palette, open-content-container-border);
  border-radius: 10px;
  padding: 2rem;
  margin-bottom: 3rem;
  @include boxSizing(border-box);
  min-width: 300px;

  &.small {
    width: 100%;
    max-width: 400px;
    height: 520px;
  }

  &.medium {
    width: 100%;
    max-width: 790px;
    height: 520px;
  }

  &.large {
    width: 100%;
    height: auto;
  }

  .dashboardCardHeader {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    min-height: 40px;
    padding-bottom: 1rem;
    border-bottom: 1px solid map-get($colour-palette, open-divider-grey);

    .guidanceText {
      margin-top: 0.5rem;
      color: map-get($colour-palette, open-text-primary);
    }

    .dashboardHeaderBtn {
      @include flex(row, flex-end, center, nowrap);
      button {
        width: auto;
      }
    }
  }

  .dashboardCardMain {
    width: 100%;
    flex: 1;
    padding: 1rem 1rem 1rem 2px;
    overflow: auto;

    .dashboardCardMainRow {
      @include flex(row, space-around, flex-start, wrap);
      height: 98%;
    }
  }

  .dashboardCardFooter {
    @include flex(row, center, center, nowrap);
    width: 100%;
    min-height: 60px;
  }
}
