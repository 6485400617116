.ppContentSection {
    padding: 4rem;
    align-items: flex-start;
}

.pp {
    &-container {
        margin: 4rem;
    }

    &-title {
        margin-top: 3rem;
    }

    &-content {
        margin-top: 2rem;
    }

    &-warning {
        margin-top: 4rem;
        padding: 1rem;
        background-color: map-get($colour-palette, open-warning);
    }
    &-list {
        padding-left: 4rem;
        list-style-type: disc;
        &-content {
            margin-top: 2rem;
            &-sublist{
                margin-left: 45px;
            }
        }
    }
}
li::marker
{
    font-size: 2rem;
}