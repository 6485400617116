.pill-large {
  align-items: center;
  background: #78cbc3;
  border: 1px solid #20b3aa;
  border-radius: 50px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #fefefe;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 325px;
  min-height: 50px;
  padding: .5rem 1rem;
  width: 100%;
  margin: 0 2rem 2rem 0;

  .text-container {
    .text-holder {
      font-size: 1.6rem;
      line-height: 150%;
    }
  }
}