.breadcrumbsContainer {
    width: 100%;
    @include flex(row, flex-start, flex-start, wrap);
    margin-bottom: 1rem;

    .breadcrumbDivider {
        margin: 0 0.5rem;
    }

    .breadcrumbLink {
        cursor: pointer;
        text-decoration: underline;
        color: map-get($colour-palette, open-white);
    }
}
