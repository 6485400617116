.bdContentSectionWrapper {
    width: 100%;
    #projectActions {
        height: auto;
        .projectActionContainer {
            @include flex(row, space-between, center, nowrap);
            width: 100%;

            @media (max-width: $mobile-width) {
                @include flex(column, space-between, center, nowrap);
            }

            .primaryToolbarBtn {
                margin-left: 2rem;
                width: 200px;
            }

            .cancelButtonContainer {
                @include flex(column, flex-start, flex-start, nowrap);
                width: 100%;

                @media (max-width: $mobile-width) {
                    @include flex(row, space-between, space-between, nowrap);
                    padding-bottom: 1rem;
                }

                .statuslabel-content {
                    margin-right: 1rem;
                }

                .import-title {
                    color: map-get($colour-palette, open-brand-primary);
                    margin-bottom: 5px;
                    font-size: 1.8rem;
                }
            }

            .moreActionContainer {
                @include flex(row, flex-end, center, nowrap);
                width: 100%;
                button {
                    margin-left: 3rem;
                }
                @media (max-width: $mobile-width) {
                    @include flex(row, space-between, space-between, nowrap);
                    padding-top: 1rem;
                    button {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    .bdContentSection {
        @include flex(column, flex-start, center, nowrap);
        width: 100%;
        flex: 1;
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;
        border: 1px solid map-get($colour-palette, open-content-container-border);
        padding: 2rem;
        @include boxSizing(border-box);

        .bdContentHeader {
            @include flex(row, flex-start, center, wrap);
            width: 100%;
            min-height: 50px;

            .searchBarContainer {
                width: 45%;
                padding: 1rem;
                @include boxSizing(border-box);

                @media (max-width: $mobile-width) {
                    width: 100%;
                }
            }

            .sortFilterContainer {
                @include flex(row, flex-end, center, nowrap);
                width: 55%;
                padding: 1rem;
                @include boxSizing(border-box);
                .button {
                    @media (max-width: $mobile-width) {
                        min-width: 70px;
                    }
                }
                #opportunitiesSortDropdown,
                #contractsregisterSortDropdown,
                #suppliersSortDropdown,
                #projectsSortDropdown,
                #requestsSortDropdown,
                #solutionsSortDropdown,
                #workflowsSortDropdown,
                #questionnairesSortDropdown,
                #questionnairesselectSortDropdown,
                #contractsSortDropdown,
                #spendsSortDropdown,
                #activitylogsSortDropdown,
                #spendsBtn-upload,
                #rebatesSortDropdown,
                #rebatesBtn-upload,
                #eventsSortDropdown,
                #stagesSortDropdown,
                #sectionsSortDropdown,
                #correspondenceSortDropdown,
                #correspondenceExportButton {
                    margin-right: 1rem;

                    @media (max-width: $tablet-width) {
                        margin-right: 0.5rem;
                    }
                }

                #opportunitiesFilterButton,
                #contractsregisterFilterButton,
                #projectsFilterButton,
                #suppliersFilterButton,
                #requestsFilterButton,
                #solutionsFilterButton,
                #workflowsFilterButton,
                #questionnairesFilterButton,
                #solutionsFilterButton,
                #contractsFilterButton,
                #activitylogsFilterButton,
                #contractsregisterExportButton,
                #addNewButton,
                #eventsFilterButton,
                #stagesFilterButton {
                    margin-left: 1rem;

                    @media (max-width: $tablet-width) {
                        margin-left: 0.5rem;
                    }
                }

                #contractsregisterExportButton {
                    margin-right: 1.5rem;
                }

                @media (max-width: $mobile-width) {
                    @include flex(row, space-between, center, nowrap);
                    width: 100%;
                }
            }
        }

        #bdContentHeader.sticky {
            position: fixed;
            z-index: 1;
            top: 100px;
            background: white;
            transition: top 0.5s ease-in-out;
            border-bottom: 2px solid #eee;
            max-width: 1066px;

            @media (max-width: $tablet-width) {
                top: 80px;
            }
        }

        .bdContentBody {
            @include flex(column, flex-start, center, nowrap);
            width: 100%;
            flex: 1;
            padding: 3rem 0;

            #bdAccordion {
                width: 100%;
                max-width: 900px;

                .accordion-section {
                    margin-bottom: 3rem;
                    border: 1px solid map-get($colour-palette, open-brand-primary);
                    border-radius: 5px;
                    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (max-width: $tablet-width) {
                        margin-bottom: 2rem;
                    }

                    &-header {
                        border-radius: 5px;
                        padding: 0 2rem;

                        img {
                            padding: 0;
                        }

                        @media (max-width: $tablet-width) {
                            padding: 0 1rem;
                        }

                        &.contentOpen {
                            border-radius: 5px 5px 0 0;
                        }

                        &-data {
                            pointer-events: none;
                            word-break: break-word;

                            .bdAccordionSectionTitleColumn {
                                @include flex(column, space-between, flex-start, nowrap);
                            }

                            .bdAccordionSectionTitleRow {
                                @include flex(row, space-between, flex-start, nowrap);
                                width: 100%;
                            }

                            .neworUnread_accordion_header {
                                @include flex(row, center, center, nowrap);
                            }

                            .title {
                                font-weight: bold;
                                color: map-get($colour-palette, open-blue);
                            }

                            p {
                                padding: 1rem 0;
                            }

                            .headerTitleContent {
                                @include flex(row, center, center, nowrap);

                                .share-icon {
                                    height: 20px;
                                    width: 20px;
                                    margin-right: 1rem;
                                }
                            }

                            .headerTitle {
                                margin-right: 2rem;

                                @media (max-width: $tablet-width) {
                                    margin-right: 1rem;
                                }
                            }

                            .bdAccordionSectionHeaderAddon {
                                @include flex(row, center, center, nowrap);

                                .status-label:not(:last-child) {
                                    margin-right: 2rem;
                                }

                                .status-label {
                                    min-width: 130px;
                                    max-width: max-content;
                                    background: map-get($colour-palette, open-white);
                                    color: map-get($colour-palette, open-white);
                                    border: 1px solid map-get($colour-palette, open-brand-primary);
                                    border-radius: 50px;
                                    padding-left: 1rem;
                                    padding-right: 1rem;
                                    &.red {
                                        color: map-get($colour-palette, open-red);
                                        border: 1px solid map-get($colour-palette, open-red);
                                    }

                                    &.amber {
                                        color: map-get($colour-palette, open-amber);
                                        border: 1px solid map-get($colour-palette, open-amber);
                                    }

                                    &.green {
                                        color: map-get($colour-palette, open-green);
                                        border: 1px solid map-get($colour-palette, open-green);
                                    }

                                    &.primary {
                                        background: map-get($colour-palette, open-blue-90);
                                        border: 1px solid map-get($colour-palette, open-blue-90);
                                    }
                                }

                                &.cancelled {
                                    background-color: map-get($colour-palette, open-red);
                                    border-radius: 5rem;
                                    margin: 1rem;
                                    width: 135px;
                                    height: 25px;
                                }
                                &.active {
                                    background-color: map-get($colour-palette, open-active-pill);
                                    border-radius: 5rem;
                                    margin: 1rem;
                                    width: 135px;
                                    height: 25px;
                                }
                                &.awarded {
                                    background-color: map-get($colour-palette, open-awarded-pill);
                                    border-radius: 5rem;
                                    margin: 1rem;
                                    width: 135px;
                                    height: 25px;
                                }

                                &.projects {
                                    background-color: map-get($colour-palette, open-green);
                                    border-radius: 5rem;
                                    margin: 1rem;
                                    width: 135px;
                                    height: 25px;
                                }

                                .label {
                                    margin-right: 1rem;

                                    &.projects {
                                        color: map-get($colour-palette, open-white);
                                        padding: 0;
                                        margin: 0 auto;
                                    }
                                }

                                .headerTag {
                                    color: map-get($colour-palette, open-grey);
                                    background: map-get($colour-palette, open-footer-border);
                                    min-width: 120px;
                                    border-radius: 50px;
                                    font-size: 1.6rem;
                                    text-align: center;
                                    margin: 1rem 0;

                                    &.active {
                                        color: map-get($colour-palette, open-white);
                                        background: map-get($colour-palette, open-green);
                                        padding: 0 10px;
                                    }

                                    @media (max-width: $mobile-width) {
                                        width: 100px;
                                        font-size: 1.4rem;
                                    }
                                }
                            }
                        }
                    }

                    &-content {
                        &.open {
                            border-top: 1px solid map-get($colour-palette, open-brand-primary);
                            border-radius: 0 0 5px 5px;
                            overflow-y: auto;
                        }

                        &-data {
                            @include flex(column, flex-start, center, nowrap);
                            @include boxSizing(border-box);

                            .bdAccordionSectionContentColumn {
                                @include flex(column, space-between, flex-start, wrap);
                            }
                            .bdAccordionSectionContentRow {
                                @include flex(row, space-between, flex-start, wrap);
                                width: 100%;

                                .bdAccordionSectionContentData {
                                    @include flex(row, flex-start, flex-start, wrap);
                                    padding: 1rem 2rem;
                                    word-break: break-word;
                                    flex-wrap: nowrap;
                                    &:not(:first-child){
                                        padding-top: 0;
                                    }
                                    .label {
                                        margin-right: 1rem;
                                        font-weight: 600;
                                        display: flex;
                                        flex: none;
                                    }

                                    @media (max-width: $tablet-width) {
                                        padding: 1rem;
                                    }

                                    @media (max-width: $mobile-width) {
                                        padding: 0.5rem;

                                        p {
                                            padding: 0.5rem;
                                        }

                                        .label {
                                            margin-right: 0;
                                            width: 100%;
                                        }
                                    }
                                }
                            }

                            .bdAccordianSectionButton {
                                width: 50%;
                                @include flex(row, space-evenly, center, nowrap);

                                .selected-event {
                                    width: 150px;
                                    height: 30px;
                                }
                            }

                            .button {
                                margin: 3rem 0 1.5rem 0;
                                width: fit-content;
                                width: -moz-fit-content;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }

            .infinite-scroll-component__outerdiv {
                width: 100%;
                .infinite-scroll-component {
                    width: 100%;
                    @include flex(column, center, center, nowrap);

                    #endMessage {
                        margin-top: 3rem;
                        text-align: center;
                    }
                }
            }
        }

        #boFilterPanel,
        #bsFilterPanel,
        #bprFilterPanel,
        #bcrFilterPanel,
        #bsolFilterPanel,
        #bwFilterPanel,
        #bqFilterPanel,
        #bcFilterPanel {
            @include flex(column, flex-start, flex-start nowrap);
            top: 100px;
            bottom: 20px;

            @media (max-width: $mobile-width) {
                top: 0;
                bottom: 0;
            }
        }

        .bdContentFooter {
            width: 100%;
            height: 80px;
        }
    }
}
.newOrUnreadData {
    @include flex(row, center, center, nowrap);

    &.tag {
        background-color: map-get($colour-palette, open-yellow);
        border-radius: 5rem;
        width: 20px;
        color: white;
    }
}

.width70 {
    width: 70%;
}