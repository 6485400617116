.cuFilterContainer {
  @include flex(row, flex-start, center, nowrap);
  @include boxSizing(border-box);
  @media (max-width: $mobile-width) {
    @include flex(column, flex-start, center, nowrap);
  }
}

.cuHeader {
  border-bottom: 1px solid #ebebeb;
  padding-bottom: 2rem
}

.cuItems {
  margin-top: 2rem;
}

.cuRowItems {
  border: 0.1rem solid #2c4874;
  border-radius: 5px;
  margin: 1rem 0;
  word-break: break-word;
}

.cuUserSection {
  padding-top: 2rem;
}

.cuSectionTags {
  @include flex(row, flex-start, center, wrap);
}

.cuFilter {
  @include flex(column, flex-start, flex-start, nowrap);
  padding: 1rem;
}

.cuFilterButton {
  margin: 2rem 1.5rem 0 1.5rem;
}

#endMessage {
  text-align: center;
}

.select-search:not(.select-search--multiple) .select-search__select {
      position: absolute;
      z-index: 1;
      top: 44px;
      right: 0;
      left: 0;
      border-radius: 3px;
      overflow: auto;
      max-height: 360px;
  }

.select-search--multiple .select-search__select {
      position: relative;
      overflow: auto;
      max-height: 260px;
      border-top: 1px solid map-get($colour-palette, open-white);
      border-radius: 0 0 3px 3px;
  }

.select-search__not-found {
      height: auto;
      padding: 1.6rem;
      text-align: center;
      color: map-get($colour-palette, open-grey);
  }