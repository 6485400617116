.event-section-container {
    @include flex(column, flex-start, space-between, nowrap);
    .event-section-card {
        @include flex(column, space-evenly, stretch, wrap);
        @include boxSizing(border-box);
        border-left: 1rem;
        border-left: 1px solid map-get($colour-palette, open-footer-border);
        border-radius: 5px;
        padding: 1rem;
        margin-top: 3rem;

        &.success {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-success);
        }
        &.warning {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-error);
        }
        &.error {
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-warning);
        }

        .event-section-header {
            @include flex(row, space-between, center, wrap);
            &-content {
                @include flex(row, flex-start, center, wrap);
            }
            .mr-1 {
                margin-right: 1rem;
            }
            .event-section {
                width: 100%;
                min-height: 40px;
                @include flex(column, flex-start, flex-start, nowrap);

                &-header {
                    min-height: inherit;
                    @include flex(row, space-between, center, nowrap);
                    background-color: map-get($colour-palette, open-white);
                    cursor: pointer;
                    @include boxSizing(border-box);
                    border: none;

                    &-button {
                        outline: none;
                        border: none;
                        background-color: white;
                        @include flex(row, center, center, nowrap);
                        &:focus-visible {
                            border: 1px solid #1B75BC;
                        }
                    }

                    &-icon {
                        height: 50%;
                        transition: transform 0.6s ease;
                        pointer-events: none;
                        margin-right: 10px;

                        @media (max-width: $tablet-width) {
                            padding: 0 1rem;
                        }

                        &.rotate {
                            transform: rotate(90deg);
                        }
                    }
                }

                &-content {
                    width: 100%;
                    background-color: map-get($colour-palette, open-white);
                    overflow: hidden;
                    transition: max-height 0.2s ease;
                    @include boxSizing(border-box);
                }
            }
        }
        .event-section-button {
            @include flex(row, flex-end, center, wrap);

            .button-container {
                @include flex(row, flex-end, center, wrap);
                margin-bottom: 12px;
                // margin-top: 2rem;
                button {
                    width: auto;
                }
            }
            .button-container > *:not(:first-child) {
                margin-left: 1rem;
            }
        }

        .event-section-header-button {
            cursor: pointer;
        }

        .event-section-content {
            p {
                &.label {
                    font-weight: 100;
                    padding: 1px;
                    line-height: 1.6;
                }
            }
        }
        .event-section-subsection {
            @include flex(row, flex-start, center, nowrap);
            border-top: 1px solid map-get($colour-palette, open-footer-border);
            .event-section-content {
                @include flex(row, space-between, center, nowrap);
                width: 30%;
            }
            .warning {
                color: map-get($colour-palette, open-text-error);
            }
            .success {
                color: map-get($colour-palette, open-text-success);
            }
            .component-container {
                @include flex(row, space-evenly, flex-start, nowrap);
                width: 100%;
                padding: 1rem;
                .upFormInputContainer {
                    width: 100%;
                    max-width: 100%;

                    .flex-break {
                        margin: 0;
                        height: 0;
                    }

                    .upDocumentButtonContainer {
                        @include flex(row, flex-start, center, nowrap);
                    }
                    .upDocumentButtonContainer > *:not(:last-child) {
                        margin-right: 1rem;
                    }
                }
                .worknotes-container {
                    @include flex(column, flex-start, flex-start, nowrap);
                    width: 100%;
                    .button-container {
                        @include flex(row, flex-start, center, wrap);
                    }
                    .button-container  > *:not(:last-child) {
                        margin-right: 1rem;
                    }
                }

                .worknotes-container > *:not(:last-child) {
                    margin-bottom: 1rem;
                }
            }
        }
    }
}
