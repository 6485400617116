.bdContentSection {
    padding: 4rem;
    align-items: flex-start;
}

.tc {
    &-container {
        margin: 4rem;
    }

    &-title {
        margin-top: 3rem;
    }

    &-content {
        margin-top: 2rem;
    }

    &-list {
        display: grid;
        grid-template-columns: 4% auto;
    }

    &-sub-list {
        display: grid;
        grid-template-columns: 6% auto;
        margin-left: 45px;
    }

    &-table-list {
        display: grid;
        grid-template-columns: 6% auto;
    }

    &-title-align {
        text-align: center;
    }

    &-bold {
        font-weight: bold;
    }

    &-table-content{
        margin: 2px;
    }

    &-content-c{
        margin-left: 45px;
    }
}