.contractDetailsItem {
    @include flex(row, space-between, center, nowrap);
    width: 100%;
    margin-top: 2rem;
    word-break: break-word;
    .leftColumn {
        width: 20%;
        word-break: break-word;
    }

    .rightColumn {
        width: 80%;
    }
}

.contractStatusPills {
    @include flex(row, space-between, center, nowrap);

    #contractCancelled-statuslabel {
        margin-right: 1rem;
    }

    .statusText {
        @include flex(column, space-around, center, nowrap);
        height: 30px;
        min-width: 100px;
        border-radius: 50px;
        margin-right: 2rem;
        &.platinum {
            background-color: map-get($colour-palette, open-platinum);
        }
        &.silver {
            background-color: map-get($colour-palette, open-silver);
        }
        &.gold {
            background-color: map-get($colour-palette, open-gold);
        }
    }
}

#mainContent {
    height: auto;
}
#contractDetailsContainer{
    padding: 2rem;
}
#awardedContractOverviewContainer{
    .itemHeaderContainer{
        line-height: normal;
        margin-left: -2rem;
        .headerTitle {
            font-weight: bold;
            color: #2c4874;
        }
        .referenceNumber{
            font-family: montserrat, sans-serif;
            font-size: 1.4rem;
            line-height: 150%;
            // color: #20b3aa !important;
        }
    }
}
