#duForm {
    .modal-container {
        .modal {
            .modal-content {
                .fileUpload {
                    @include flex(column, center, center, nowrap);
                    border: 1px dashed map-get($colour-palette, open-brand-primary);
                    border-radius: 10px;
                    vertical-align: middle;

                    img {
                        margin-top: 4rem;
                    }

                    .labelled-input {

                        margin-top: 0;

                        #fileUpload-input::before {
                            text-align: center;
                        }

                        input[type="file"] {
                            border: none;
                            height: auto;
                            @include flex(column, center, center, nowrap);
                        }
                    }
                }

                .uploadedFile {
                    @include flex(row, space-between, flex-start, nowrap);
                }

                .nmiDate-imput {
                    margin-bottom: 12px;
                }

                .upload-expiry {
                    margin-bottom: 2rem;
                    margin-top: 1rem;
                }
            }
        }
    }

    .documentUpload-content>.caption {
        padding: 1rem 1.5rem;
        text-align: justify;
    }
}

#downloadMultipleDocuments {
    margin-top: 2rem;
    float: right;
}