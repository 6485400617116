.labelled-input {
  @include flex(column, flex-start, flex-start, nowrap);

  .form-label {
    margin-bottom: 1rem;
    font-weight: bold;
  }

  .form-input {
    width: 350px;
    height: 40px;
    border-radius: 5px;
    vertical-align: middle;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    background-color: map-get($colour-palette, open-white);
    color: map-get($colour-palette, open-text-primary);
    border: 1px solid map-get($colour-palette, open-brand-primary);

    @media (max-width: $tablet-width) {
      max-width: 250px;
      width: 100%;
    }

    @media (max-width: $mobile-width) {
      max-width: 230px;
      width: 100%;
    }
  }

  #fileUpload-input {
    width: auto;
    border: 1px dashed map-get($colour-palette, open-brand-primary);
    border-radius: 10px;
    vertical-align: middle;

    & input[type="file"] {
      height: auto;
    }
  }

  #fileUpload-input::-webkit-file-upload-button {
    visibility: hidden;
  }

  #fileUpload-input::before {
    content: "Browse files";
    display: inline-block;
    background: -webkit-linear-gradient(top,
        map-get($colour-palette, open-white),
        map-get($colour-palette, open-white));
    border-radius: 3px;
    padding: 5px 8px;
    outline: none;
    white-space: nowrap;
    text-decoration: underline;
    font-size: 10pt;
    color: map-get($colour-palette, open-text-primary);
  }

  #fileUpload-input:hover::before {
    border-color: black;
  }

  #fileUpload-input:active::before {
    background: -webkit-linear-gradient(top,
        map-get($colour-palette, open-white),
        map-get($colour-palette, open-white));
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}