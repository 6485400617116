.suppliers-trees-award-info {
    width: 100%;
}
.headerEvaluationContainer #back {
    margin-left: auto;
    margin-right: 20px;
}

.award-summary-tree-supplier {
    width:calc(100% - 4rem);
    margin-top:2rem;
    border-radius: 10px;
    & > div {
        padding-bottom: 1rem;
    }
    & div[aria-level='0'] > a {
        color: #2c4874;
        font-size: 1.8rem;
        font-weight: bold;
        flex-wrap: wrap;
        & .tree-view-menu {
            margin-right: inherit;
            margin-left: auto;
        }
        & p.title{
            font-size: 1.8rem;
        }
    }
    & div[aria-level='1'] > a {
        padding-left:2rem;
        color: #2c4874;
        font-weight: 600;
        row-gap: 1rem;
        &  .tree-view-menu {
            margin-left: inherit;
            margin-right: auto;
        }
    }
    & div[aria-level='1']  {
        &  > .tree-view-child {
            display: inherit;
            padding-top: 0;
            padding-bottom: 0;
            border-bottom: 0;
        }
    }
     & .redButton {
        background: #ce0000;
        color: #fff;
    }
    & .statuslabel-txt {
        font-size:1.6rem;
    }
    & .user-icon{
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    & .submission-label span {
        display: block;
        margin-top: 0.5rem;
        font-weight: normal;
        font-size:1.3rem;
    }
    & .title {
        font-family:'NotoSans-Regular';
    }
    &.statuslabel-txt-container a 
    {
    text-transform: capitalize;
    }
}
.award-summary-tree-submissions{
    width: 100%;
    padding-bottom: 0rem;
    border-radius: 0 0 10px 10px;
    padding: 0;

    & div[aria-level='0'] > a {
        padding-left: 2rem;
        color: #0b0c0c;
        font-weight: 600;
        row-gap: 1rem;
        font-size: 1.4rem;
        &  .tree-view-menu {
            margin-right: inherit;
            margin-left: auto;
        }
    }
    & div[aria-level='1'] > a {
        padding-left:4rem;
        color:#2c4874;
        font-weight: 600;
        row-gap: 1rem;
        & .button {
            width: auto;
            padding-right: 2rem;
            padding-left: 2rem;
        }
        @media only screen and (max-width : 700px) {
            padding-left:2rem;
        }
    }
    & div[aria-level='1'] .tree-view-menu span {
        color:#0b0c0c;
        font-weight: normal;
    }
    & div[aria-level='2'] {
        & > div {
            padding-left: 6rem;
            background: #f2f6f9;
            padding-right: 2rem;
            flex-wrap: wrap;
            row-gap: 2rem;
            @media only screen and (max-width : 700px) {
                padding-left:2rem;
            }
        }
        & .tree-view-child {
            display: flex;
            @media only screen and (max-width : 700px) {
                flex-direction: column;
                align-items: flex-start;
            }
        }
        & .tree-view-menu {
            @media only screen and (max-width : 700px) {
                flex-wrap: wrap; 
                align-items: flex-start;
            }
        }
        
    }
   
    & .tree-viewer-item > a .tree-view-menu {
        margin-left: auto!important;
        margin-right: inherit!important;
        
        @media only screen and (max-width : 700px) {
            display: block;
            & > div {
                margin-bottom:1rem;
            }
        }
    }
    & .redButton {
        background: #ce0000;
        color: #fff;
    }
    & .statuslabel-txt {
        font-size:1.6rem;
    }
    & .user-icon{
        width: 15px;
        height: 15px;
        margin-right: 10px;
    }
    & .submission-label span {
        display: block;
        margin-top: 0.5rem;
        font-weight: normal;
        font-size:1.3rem;
    }
    & .title {
        font-family:'NotoSans-Regular';
    }
    &.statuslabel-txt-container a 
    {
    text-transform: capitalize;
    }
}

.prev-submissions-sent {
    margin: 1rem 0;
    & .uploadContainer {
        background-color: #f3f3f3;
    }
    & .upFormInputContainer{
        display: flex;
        gap: 1.5rem;
        padding: 2rem;
        width: 100% !important;
        max-width: 100% !important;
        align-items: center;
    }
    & .flex-break {
        display: none;
    }
    & .formLabel {
        margin: 0!important;
        padding: 0!important;
        & p {
            font-size: 1.4rem;
            font-weight: 600;
        }
    }
     & .formCaption {
        margin: 0!important;
        padding: 0!important;
        & p {
            font-size: 1.4rem;
        }
    }
    & .upDocumentButtonContainer {
        margin: 0!important;
        padding: 0!important;
        & p {
            font-size: 1.4rem;
        }
    }
}

