#contractManagementDocuments, #documentModal {
    .documentContainer {
        width: 100%;
        .documentItem {
            @include flex(row, space-between, center, nowrap);
            width: 100%;
            margin-top: 2rem;

            .button {
                height: 30px;
                width: 100px;
                border-radius: 5px;
                font-size: 1.4rem;
            }
        }
    }

    .downloadButton {
        width: 100%;
        height: 40px;
        border-radius: 5px;
        background-color: map-get($colour-palette, open-brand-primary);
        color: map-get($colour-palette, open-white);
    }
}
