#projectOverview {
  @include flex(row, space-around, stretch, wrap);
  width: 100%;

  .modal-container .modal-content .labelled-textarea .form-textarea {
    width: 100%;
  }

  #faqModal,
  #registerRequestModal,
  #escalate {
    max-height: 525px;
  }

  .progress-bar {
    margin-bottom: 3.5rem;
  }

  .projectOverviewContainer {
    @include flex(row, space-between, stretch, wrap);
    width: 100%;

    .dashboardCard {
      &.small,
      &.medium {
        height: 410px;
      }
    }

    #steeringSection .button {
      @include flex(row, flex-end, center, nowrap);
    }

    #projectDetailsEvent,
    #projectDetails {
      height: auto;
      min-height: 590px;

      .dashboardCardHeader {
        padding: 2rem;
        @include boxSizing(border-box);

        .dashboardHeaderText {
          margin-left:-2rem;
          word-break: break-word;
        }

        .dashboardHeaderBtn {
          @include flex(row, space-between, center, nowrap);

          @media (max-width: $mobile-width) {
            @include flex(column, space-between, center, nowrap);

            .button:first-child {
              margin-right: 0rem;
              margin-bottom: 2rem;
            }
          }
        }
      }
      #error {
        padding-left: 2rem;
      }
    }

    .dashboardCardMain {
      .accordion-container {
        padding: 2rem 0;
      }
    }

    .dashboardCardMain #noWorkflow,
    #noLot {
      @include flex(column, center, center, nowrap);
      height: 100%;

      #workFlowText,
      #lotText {
        margin: 2rem;
        margin-bottom: 0;
      }
    }
    .dashboardCardMain,
    .sectionLabel {
      margin-bottom: 1rem;
    }

    #steeringGroupEvent .sectionContent {
      padding: 1rem 0 2rem;
    }
    #interestRemoved .sectionContent {
      padding: 1rem 0 2rem;
    }

    #steeringGroupEvent .button {
      @include flex(row, flex-end, center, nowrap);
    }

    #faqEvents #dashbaordActionBtn {
      @include flex(row, flex-end, center, nowrap);
    }
    #registerInterestRequests .button {
      @include flex(row, center, center, nowrap);
    }

    .sectionContent {
      padding: 2rem 2rem;
      border: none;
      box-sizing: border-box;
      word-break: break-word;
      @media (max-width: $mobile-width) {
        padding-bottom: 1rem;
      }

      &Tags {
        @include flex(row, flex-start, flex-start, wrap);
        flex: 1;

        @media (max-width: $mobile-width) {
          padding: 1.5rem 0;
        }

        .tag-content {
          margin: 0 2rem 2rem 0;

          @media (max-width: $mobile-width) {
            margin: 0 0 1rem 0;
          }
        }
      }

      .sectionRow {
        @include flex(row, flex-start, flex-start, nowrap);
        margin-top: 2rem;

        @media (max-width: $mobile-width) {
          flex-wrap: wrap;
          p {
            width: 100%;
            margin-left: 0;

            &:first-of-type {
              margin-bottom: 1rem;
            }
          }
        }

        .sectionLabel {
          width: 100%;
          @media (min-width: $laptop-width) {
            max-width: 250px;
          }

          @media (min-width: $mobile-width) {
            max-width: 230px;
          }
        }

        .sectionValue {
          width: 100%;
          word-break: break-word;
          color: map-get($colour-palette, open-text-primary);
        }
      }
    }

    .taskEventContentContainer {
      @include flex(column, space-between, space-between, nowrap);
      width: 100%;
      max-height: 520px;
      padding-bottom: 1.2rem;
      @include boxSizing(border-box);

      @media (max-width: $mobile-width) {
        padding-bottom: 1.5rem;
      }
      @media (max-width: $tablet-width) {
        #regIntReqBtnContainer {
          .button {
            margin-right: 0;
          }

          .actionBtnContainer {
            @include flex(row, center, center, nowrap);
            button {
              background: transparent !important;
              color: map-get($colour-palette, open-brand-primary);
            }

            @media (max-width: $mobile-width) {
              .button {
                height: fit-content;
                height: -moz-fit-content;
                width: 80px;
              }
            }
          }
        }
      }

      .registerItem {
        @include flex(column, space-between, space-between, nowrap);
        margin: 1rem 0;

        @media (max-width: $tablet-width) {
          @include flex(row, space-between, center, nowrap);
        }

        #regIntReqBtnContainer {
          @include flex(row, space-between, space-between, nowrap);
          margin-top: 1rem;
          .button {
            max-width: 120px;
            width: 120px;
            height: 30px;
            font-size: 1.4rem;
          }
          .button:first-of-type {
            width: 120px;
            max-width: 120px;
            margin-right: 5%;
          }
          @media (max-width: $tablet-width) {
            @include flex(column, space-between, flex-end, nowrap);
            margin-right: 1.5rem;
            .button:first-of-type {
              margin-right: 0;
              margin-bottom: 1rem;
            }

            .button:nth-child(2) {
              margin-bottom: 1rem;
            }
          }
          .button:last-of-type {
            margin-left: 1rem;
          }
        }

        #regIntBtnContainer {
          @include flex(row, space-between, space-between, nowrap);
          margin-top: 1rem;
          .button {
            max-width: 120px;
            width: 120px;
            height: 30px;
            font-size: 1.4rem;
          }
          .button:first-of-type {
            width: 120px;
            max-width: 120px;
            margin-right: 5%;
          }
          @media (max-width: $tablet-width) {
            @include flex(column, space-between, flex-end, nowrap);
            margin-right: 1.5rem;
            .button:first-of-type {
              margin-right: 0;
              margin-bottom: 1rem;
            }

            .button:nth-child(2) {
              margin-bottom: 1rem;
            }
          }
          .button:last-of-type {
            margin-left: 1rem;
          }
        }
      }

      .documentItem {
        @include flex(row, space-between, center, nowrap);
        width: 100%;
        margin: 1rem 0;
        #documentBtn {
          font-size: 1.4rem;
        }
      }

      .documentItem:last-of-type {
        margin-bottom: 0;
      }
    }

    #projectActions {
      height: auto;
      #projectActionContainer {
        @include flex(row, flex-end, center, nowrap);
        // width: 100%;

        @media (max-width: $mobile-width) {
          @include flex(column, space-between, center, nowrap);
        }

        .cancelButtonContainer {
          @include flex(row, flex-start, center, nowrap);

          margin-left: 1rem;

          @media (max-width: $mobile-width) {
            @include flex(row, space-between, space-between, nowrap);
            margin-top: 1rem;
            margin-left: 0;
          }

          .statuslabel-content {
            margin-right: 1rem;
          }
        }

        .cancelButtonContainer > *:not(:last-child) {
          margin-right: 1rem;
        }

        .moreActionContainer {
          @include flex(row, flex-end, center, nowrap);
          width: 100%;
          button {
            margin-left: 3rem;
          }
          @media (max-width: $mobile-width) {
            @include flex(row, space-between, space-between, nowrap);
            padding-top: 1rem;
            button {
              margin-left: 0;
            }
          }
        }
      }
    }

    #projectActionContainer > *:not(:last-child) {
      margin-right: 1rem;

      @media (max-width: $mobile-width) {
        margin-right: 0rem;
      }
    }

    #projectGuidanceContainer {
      width: 100%;
      .guidanceBannerContainer {
        width: auto;
      }

      .dashboardCardMain {
        margin-bottom: 0;
        padding: 0;
        .guidanceBannerContainer {
          margin-bottom: 0;
        }
      }

      #publishEventGuidance {
        .publish-icon {
          height: 20px;
          width: 20px;
        }
        .showButton {
          button {
            width: auto;
            margin-right: 0;
          }
        }
      }

      #missingSteeringGroup {
        .showButton {
          button {
            width: auto;
            margin-right: 0;
          }
        }
      }
    }

    #projectGuidanceContainer > * {
      height: auto;
    }

    #projectActivityLog {
      .logItem {
        @include flex(column, flex-start, flex-start, nowrap);
        .headerTitle {
          margin: 0;
        }
      }
      .logItem:not(:last-child) {
        margin-bottom: 1rem;
      }
    }
  }

  #escalationModal {
    .escalationReasonItem:not(:last-child) {
      margin-bottom: 2rem;
    }
  }

  .projectOverviewWorkflowContainer {
    @include flex(row, space-between, stretch, nowrap);
    @media (max-width: $mobile-width) {
      @include flex(column, space-between, stretch, nowrap);
    }
    width: 100%;
    .dashboardCard {
      &.small,
      &.medium {
        height: 410px;
      }
    }
    .leftContainer {
      width: 50%;

      #actions {
        #projectActionContent {
          #worknoteContent,
          #correspondenceContent {
            margin-top: 2rem;

            & > *:not(:last-child) {
              margin-bottom: 1rem;
            }

            &.unread {
              border-radius: 5px;
              padding: 1rem;
              box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), inset 5px 0px 0px map-get($colour-palette, open-text-error);
            }
          }
        }

        .uploadContainer {
          margin-top: 3rem;

          .upFormInputContainer {
            width: 100%;
            max-width: 100%;

            .flex-break {
              margin: 0;
              height: 0;
            }

            .upDocumentButtonContainer {
              padding: 0;
              margin: 0;
            }
          }

          .upFormInputContainer > *:not(:last-child) {
            margin-bottom: 1rem;
          }
        }
      }
    }

    #workflowEvent {
      height: 1290px;
    }
  }

  #publishEventModal {
    max-width: 730px;
    max-height: 730px;
    overflow: auto;
    .modalQuestionnairesAttached {
      margin-top: 2rem;
      border: solid 1px black;
      border-radius: 10px;
      background-color: white;
      padding: 1rem;
    }
    .modalContentBody {
      border-top: 1px solid #ebebeb;
      margin-top: 2rem;
      padding-top: 1rem;
    }
    .formElementsRow {
      @include flex(row, flex-start, flex-start, wrap);
      width: 100%;
      margin-bottom: 1rem;
      .label {
        color: map-get($colour-palette, open-brand-primary);
      }

      .underline {
        padding: 1rem 0;
        border-bottom: 1px solid #ebebeb;
        width: 100%;
      }
      .datePickerWrapper,
      .timePickerWrapper {
        @include flex(column, flex-start, flex-start, nowrap);
        width: auto;

        &:nth-child(odd) {
          margin-right: 2rem;
        }

        label {
          margin-bottom: 1rem;
        }

        // .customDateInput {
        //   width: 210px !important;
        //   max-width: 250px;

        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero {
        //     width: 35px !important;
        //   }

        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
        //     width: 50px !important;
        //   }

        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__day,
        //   .react-date-picker__inputGroup__divider,
        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__month.react-date-picker__inputGroup__input--hasLeadingZero,
        //   .react-date-picker__inputGroup__input.react-date-picker__inputGroup__year {
        //     padding: 0.5rem !important;
        //   }
        // }

        .customTimeInput {
          width: 170px !important;
          font-family: NotoSans-Regular;
          font-size: 1.6rem;

          .react-time-picker__wrapper {
            border-radius: 5px;
            border: 1px solid map-get($colour-palette, open-brand-primary);
          }

          .react-time-picker__inputGroup {
            margin: 0.5rem;
          }

          .react-time-picker__inputGroup__input.react-time-picker__inputGroup__hour,
          .react-time-picker__inputGroup__input.react-time-picker__inputGroup__minute {
            width: 50px !important;
            height: 28px !important;
          }

          .react-time-picker__inputGroup__divider {
            margin: 0 1rem;
          }
        }
      }
    }
  }

  #projectEscalations {
    .escalationItem {
      @include flex(column, space-between, nowrap);
      margin-top: 2rem;
    }

    #escalationBtnContainer {
      @include flex(row, space-between, center, nowrap);
      margin-top: 0.5rem;
    }
  }

  #contractDocument {
    .contractItem {
      @include flex(row, space-between, center nowrap);
      margin-top: 2rem;
    }
  }
}

#actionBtnBack {
  margin-right: 22px;
}

// #guidanceContent{
//   height:35%;
//   @media (max-width: $mobile-width) {
//     height:80%;
//   }
// }
