#supplierProfileData {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
     @include boxSizing(border-box);

     .itemContainer {
        width: 100%;
        height: auto;
        background-color: map-get($colour-palette, open-white);
        border-radius: 1rem;
        padding: 4rem;
        @include boxSizing(border-box);

        @media (max-width: $tablet-width) {
          padding: 3rem;
        }

        @media (max-width: $mobile-width) {
          padding: 2rem;
        }

        .itemHeaderContainer {
          @include flex(row, flex-end, center, nowrap);
          border-bottom: 1px solid map-get($colour-palette, open-divider);
          width: 100%;
          padding-bottom: 2rem;

          #actionBtn {
            margin-left: 1rem;
          }

          @media (max-width: $mobile-width) {
            @include flex(column, center, center, nowrap);

            #actionBtn,#backBtn {
              margin-left: 0;
              margin-bottom: 1rem;
            }

            padding-bottom: 1.5rem;
          }

          .itemHeader {
            @include flex(column, flex-start, flex-start, nowrap);

            .headerTitle {
              margin-bottom: 1rem;
            }

            @media (max-width: $mobile-width) {
              margin-right: 1.5rem;
            }
          }

          .headerTitle {
            color: map-get($colour-palette, open-brand-primary);
            margin-right: 2rem;

            @media (max-width: $mobile-width) {
              margin-right: 0;
            }
          }

          .headerCaption {
            padding-top: 1rem;
          }

          #actionBtnContainer {
            @include flex(row, center, center, nowrap);

            #actionBtn {
              margin-right: 0px;
              margin-left: 20px;
            }

            @media (max-width: $mobile-width) {
              .button {
                height: fit-content;
                height: -moz-fit-content;
                width: 80px;
              }

              #actionBtn {
                margin-right: 0px;
                margin-left: 20px;
              }
            }
          }
        }

        .itemBody {
          #office-accordion-id{
          .accordion-section {
            margin-bottom: 3rem;
            border: 1px solid map-get($colour-palette, open-brand-primary);
            border-radius: 5px;

            &-header {
              border-radius: 5px;
              &.contentOpen {
                border-radius: 5px 5px 0 0;
              }
            }
            &-content {
              &.open {
                  border-top: 1px solid map-get($colour-palette, open-brand-primary);
                  border-radius: 0 0 5px 5px;
                  overflow-y: auto;
                  padding-left: 1%;
              }
              &-data {
                padding:2rem;
              }
            }
          }
        }
          .sectionHeader {
            color: map-get($colour-palette, open-brand-primary);
            padding-top: 2rem;

            &-default{
              display: none;
            }
            @media (max-width: $mobile-width) {
              padding-top: 1rem;
            }
          }

          .sectionContent {
            padding-bottom: 3rem;
            border-bottom: 1px solid map-get($colour-palette, open-divider);
            word-break: break-word;
            .sectionLabel {
              width: 250px;
            }


            #personalInformation {
              .sectionLabel {
                width: 200px;
              }
            }

            #preferences {
              .sectionLabel {
                width: 200px;
              }
            }

            #organisationInformation {
              .sectionLabel {
                width: 310px;
              }
            }

            #specialAccountPrivileges {
              .sectionLabel {
                width: 500px;
              }
            }

            &:last-child {
              border: none;
            }

            @media (max-width: $mobile-width) {
              padding-bottom: 1rem;
            }

            &Tags {
              @include flex(row, flex-start, flex-start, wrap);
              border-bottom: 1px solid map-get($colour-palette, open-divider);
              padding: 2rem 0;

              @media (max-width: $mobile-width) {
                padding: 1.5rem 0;
              }

              .tag-content {
                margin: 0 1rem 1rem 0;

                @media (max-width: $mobile-width) {
                  margin: 0 0 1rem 0;
                }
              }
            }

            .sectionRow {
              @include flex(row, flex-start, flex-start, nowrap);
              margin-top: 1.5rem;

              @media (max-width: $mobile-width) {
                flex-wrap: wrap;
                p {
                  width: 100%;
                  margin-left: 0;

                  &:first-of-type {
                    margin-bottom: 1rem;
                  }
                }
              }
            }
          }

          // .sectionLabel,
          // .form-label {
          //   width: 100%;
          //   @media (min-width: $laptop-width) {
          //     max-width: 250px;
          //   }

          //   @media (min-width: $mobile-width) {
          //     max-width: 230px;
          //   }
          // }

          .sectionValue {
            width: 100%;
            word-break: break-word;
            color: map-get($colour-palette, open-text-primary);

            @media (max-width: $tablet-width) {
              margin-left: 2rem;
            }

            @media (min-width: $tablet-width) {
              margin-left: 4rem;
            }
          }
        }
      }
      
      .labelled-input .disabled{
        cursor: not-allowed;
        opacity: 0.7;
        outline: none;
      } 

      #country-select.form-select {
          width: 22.1%;
      }
}

.editBtn {
    @include flex(row, flex-start, flex-start, nowrap);
    cursor: pointer;
}

.twoActionBtnContainer {
    max-width: 100%;
    width: 100%;
    @include flex(row, flex-end, flex-start, nowrap);

    button {
      margin-left: 2rem;
    }
  }

.mpWorkflowItem {
  @include flex(column, space-between, center, nowrap);
  border-bottom: 1px solid map-get($colour-palette, open-divider);
  width: 100%;
  // border-radius: 1rem;
  margin: 1rem;
  padding-bottom: 1.5rem;
  // min-height: 120px;

  p {
    margin-bottom: 1rem;
  }

  .mpHeader {
    @include flex(column, flex-start, flex-start, nowrap);
    width: 100%;
    margin-bottom: 1rem;
  }

  // .title {
  //   color: map-get($colour-palette, open-brand-primary);
  // }
}

.mpButtonContainer {
  @include flex(row, center, center, nowrap);
  width: 100%;

  .button {
    margin: 0 1rem;
  }
}
