.sortDropdown {
    width: 100%;
    max-width: 250px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid map-get($colour-palette, open-brand-primary);
    padding: 0 5rem 0 1rem;
    background-image: url('../../styles/images/dropdownIcon.svg');
    background-repeat: no-repeat;
    background-position: right 1rem center;
    display: inline-block;
    -webkit-appearance: none;
    -moz-appearance: none;

    @media (max-width: $tablet-width) {
        height: 35px;
    }

    @media (max-width: $mobile-width) {
        height: 30px;
    }
}
