@use '../../styles/scss/mixins.scss' as m;

.awardSummaryLotInfoCard {
    @include m.flex(column, center, center, wrap);
    background-color: white;
    border-radius: 10px;
    height: 60px;
    margin-top: 20px;
    padding: 10px;
    padding-left: 20px;
    padding-right: 20px;

    .awardSummaryQuestionTitle {
        @include m.flex(row, flex-end, center, nowrap);
        display: flex;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        align-items: center;
    }
}