#lpMainContent {
    @include flex(row, center, stretch, nowrap);
    width: 100%;
    height: 100%;
    min-height: 90vh;
    overflow: hidden;

    #lpLeftContainer {
        width: 40%;
        min-width: 150px;
        min-height: 550px;
        padding: 4rem;
        color: map-get($colour-palette, open-white);
        background-image: radial-gradient(circle at center, #20b3aa -100%, #1f3765);

        @media (max-width: $tablet-width) {
            min-height: 100%;
            padding: 2rem;
        }

        @media (max-width: $mobile-width) {
            display: none;
        }

        p {
            margin: 2rem 2rem 0 0;
            width: 90%;
            white-space: pre-wrap;
            .bold {
                font-family: NotoSans-Bold;
            }
        }

        #logo {
            @include flex(row, flex-start, center, nowrap);
            cursor: pointer;

            img {
                height: 50px;
            }

            @media (max-width: $tablet-width) {
                img {
                    height: 55px;
                }
            }

            @media (max-width: $mobile-width) {
                img {
                    height: 50px;
                }
            }
        }

        #supplierCardImage {
            @include flex(row, center, center, nowrap);

            img {
                padding: 4rem;
                width: 100%;
            }
        }
    }

    #lpRightContainer {
        @include flex(column, flex-start, center, nowrap);
        width: 60%;
        min-height: 550px;
        padding: 4rem;
        background: map-get($colour-palette, open-white);

        @media (max-width: $tablet-width) {
            @include flex(column, center, center, nowrap);
            min-height: 100%;
        }

        @media (max-width: $mobile-width) {
            @include flex(column, center, center, nowrap);
            min-height: 400px;
            width: 100%;
        }

        #auth-nav {
            @include flex(row, flex-end, baseline, nowrap);
            width: 100%;
            padding-bottom: 10rem;
            order: 1;

            @media (max-width: $tablet-width) {
                padding-bottom: 8rem;
            }

            @media (max-width: $mobile-width) {
                @include flex(row, center, baseline, nowrap);
                padding-bottom: 0rem;
                order: 4;
            }

            #create-an-account-btn {
                @include flex(column, flex-start, flex-start, nowrap);
                width: auto;
                color: map-get($colour-palette, open-browser-link-blue);
                background: none;
            }
        }

        #login-form-contanier {
            order: 3;
            width: 350px;

            @media (max-width: $tablet-width) {
                width: 100%;
                max-width: 350px;
            }

            #auth-content {
                @include flex(row, flex-start, center, nowrap);
                width: 100%;
                order: 2;
                margin-bottom: 4rem;
            }

            #form-login {
                @include flex(column, center, center, nowrap);

                .form-login-fields {
                    @media (max-width: $tablet-width) {
                        width: 100%;
                    }
                }

                #passwordLabel {
                    @include flex(row, space-between, baseline, nowrap);
                    width: 100%;
                    @media (max-width: $tablet-width) {
                        @include flex(column, space-between, baseline, nowrap);
                    }

                    @media (max-width: $mobile-width) {
                        @include flex(column, space-between, baseline, nowrap);
                    }
                }

                #login-action-btn {
                    margin-top: 3rem;
                    width: 100%;
                }


                #password-input,
                #email-input {
                    @media (max-width: $tablet-width) {
                        max-width: 100%;
                    }

                    @media (max-width: $mobile-width) {
                        max-width: 100%;
                    }
                }

                @media (max-width: $tablet-width) {
                    margin-bottom: 3rem;
                }

                @media (max-width: $mobile-width) {
                    margin-bottom: 3rem;
                }
            }
        }

        #login-form-maintenence-box {
            @include flex(row, center, center, nowrap);
            border: 2px solid map-get($colour-palette, open-brand-primary);
            border-radius: 5px;
            margin-top: 4rem;
            max-width: 350px;
            order: 5;

            p {
                padding: 1rem;
                color: map-get($colour-palette, open-brand-primary);
                text-align: center;
            }
        }

        #auth-logo {
            @include flex(row, center, center, nowrap);
            width: 100%;
            cursor: pointer;
            display: none;
            order: 1;
            margin-bottom: 4rem;

            @media (max-width: $tablet-width) {
                display: none;
            }

            @media (max-width: $mobile-width) {
                display: flex;
                img {
                    height: 50px;
                    background-color: map-get($colour-palette, open-blue);
                }
            }
        }


    }
}
