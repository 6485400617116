#faqPanel {
  margin-left: 30px;
  min-height: 400px;
  // @include flex(column, flex-start, flex-start, nowrap);
}

.panel-container {
    .panel {
        &-body {
          max-height: 300px;
            overflow-y: auto;
        }
    }
}

#documentsPanel {
  min-height: 400px;
  margin-bottom: 2rem;
  
  .panel-body {
      overflow-y: auto;
      max-height: 250px;
  }
}

#browseOpportunitiesAccordion {
  width: 100%;
  max-width: 900px;

  .accordion-section {
      border: 1px solid map-get($colour-palette, open-brand-primary);
      @include boxSizing(border-box);
      margin-bottom: 1rem;
      border-radius: 5px;

      @media (max-width: $tablet-width) {
          margin-bottom: 2rem;
      }

      &-header {
          border-radius: 10px;
          padding: 1.4rem 2rem 1.4rem 2rem;          

          p {
              pointer-events: none;
          }

          img {
              padding: 0;
          }

          @media (max-width: $tablet-width) {
              padding: 0 1rem;
          }

          &.contentOpen {
              border-radius: 10px 10px 0 0;
          }
      }

      &-content {
          &.open {
              border-top: 1px solid map-get($colour-palette, open-brand-primary);
              border-radius: 0 0 10px 10px;
          }

          &-data {
              @include flex(column, flex-start, center, nowrap);
              @include boxSizing(border-box);

              .browseOpportunitiesAccordionSectionContentRow {
                  @include flex(row, space-between, flex-start, wrap);
                  width: 100%;

                  .browseOpportunitiesAccordionSectionContentData {
                      @include flex(row, flex-start, flex-start, wrap);
                      padding: 1rem 2rem;

                      .label {
                          margin-right: 1rem;
                      }

                      @media (max-width: $tablet-width) {
                          padding: 1rem;
                      }

                      @media (max-width: $mobile-width) {
                          padding: 0.5rem;

                          p {
                              padding: 0.5rem;
                          }

                          .label {
                              margin-right: 0;
                              width: 100%;
                          }
                      }
                  }
              }
          }
      }
  }
}

#overViewData {
  @include flex(column, flex-start, flex-start, nowrap);
  width: 100%;
  @include boxSizing(border-box);

  #supplierActionCard {
    margin-top: 2rem;
  }

  .dashboardContainer #actionBtnS {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }

  .panel-section {
    width: 100%;
    @include flex(row, space-between, flex-start, nowrap);
  }

  .tenderSection {
    @include flex(row, flex-start, initial, nowrap);
    margin-bottom: 1rem;
  }

  .tenderText {
    margin-left: 1rem;
  }

  .tenderTitle {
    line-height: 150%;
  }

  #main-action-btn {
    margin-right: 0;
  }

  #opportunityRejectButton {
    color: map-get($colour-palette, open-red);
    border: 1px solid map-get($colour-palette, open-red);
    height: 40px;
  }


  #unsuccessful {
    color: map-get($colour-palette, open-red);
  }

  #successful {
    color: map-get($colour-palette, open-green);
  }

  .panel-footer {
    width:100%;
    @include flex(row, space-evenly, flex-start, nowrap);
  }

  #faq-panel-body {
    padding: 0;
  }

  .document-item {
    @include flex(row, space-between, center, nowrap);
    font-size: 16px;
    margin-bottom: 2rem;

    // .button {
    //   height: 30px;
    //   width: 100px;
    //   font-size: 14px;
    // }

    .title {
      // margin-bottom: 1rem;
      &.tender {
        margin-bottom: 1rem;
      }
    }

    &.tender {
      @include flex (column, initial, initial, nowrap);

      .button-container {
        @include flex(row, space-between, flex-start, nowrap);
      }
    }
  }

  .withdrawInterestModal {

    .caption {
      margin: 1rem 0 1rem 0;
    }

    #statusReason-select {
      height: 50px;
      width: 100%;
      max-width: 100%;
      font-size: 1.4rem;
      margin-bottom: 1rem;
    }

    #otherReason-textarea {
      width: 100%;

    }
  }

  .twoActionBtnContainer {
    max-width: 320px !important;
    width: 100%;
    @include flex(row, space-between, flex-start, nowrap);
  } 

  .itemContainer {
    width: 100%;
    height: auto;
    background-color: map-get($colour-palette, open-white);
    border-radius: 1rem;
    padding: 4rem;
    @include boxSizing(border-box);

    @media (max-width: $tablet-width) {
      padding: 3rem;
    }

    @media (max-width: $mobile-width) {
      padding: 2rem;
    }

    .itemHeaderContainer {
      @include flex(row, space-between, center, nowrap);
      border-bottom: 1px solid map-get($colour-palette, open-divider);
      width: 100%;
      padding-bottom: 2rem;

      @media (max-width: $mobile-width) {
        padding-bottom: 1.5rem;
      }

      .itemHeader {
        @include flex(column, flex-start, flex-start, nowrap);
        word-break: break-word;

        .headerTitle {
          margin-bottom: 1rem;
        }

        @media (max-width: $mobile-width) {
          margin-right: 1.5rem;
        }
      }

      .headerTitle {
        color: map-get($colour-palette, open-brand-primary);
        margin-right: 2rem;

        @media (max-width: $mobile-width) {
          margin-right: 0;
        }
      }

      .headerCaption {
        padding-top: 1rem;
      }

      #actionBtnContainer {
        @include flex(row, center, center, nowrap);

        #actionBtn {
          margin-right: 0px;
          margin-left: 20px;

          .button-icon {
            height: 60%;
          }
        }

        @media (max-width: $mobile-width) {
          .button {
            height: fit-content;
            height: -moz-fit-content;
            width: 80px;
          }

          #actionBtn {
            margin-right: 0px;
            margin-left: 20px;
          }
        }
      }
    }

    .itemBody {
      .sectionHeader {
        color: map-get($colour-palette, open-brand-primary);
        padding-top: 2rem;

        @media (max-width: $mobile-width) {
          padding-top: 1rem;
        }
      }

      .sectionContent {
        padding-bottom: 3rem;
        border-bottom: 1px solid map-get($colour-palette, open-divider);
        word-break: break-word;
        &:last-child {
          border: none;
        }

        @media (max-width: $mobile-width) {
          padding-bottom: 1rem;
        }

        &Tags {
          @include flex(row, flex-start, flex-start, wrap);
          border-bottom: 1px solid map-get($colour-palette, open-divider);
          padding: 2rem 0;

          @media (max-width: $mobile-width) {
            padding: 1.5rem 0;
          }

          .tag-content {
            margin: 0 2rem 2rem 0;

            @media (max-width: $mobile-width) {
              margin: 0 0 1rem 0;
            }
          }
        }

        .sectionRow {
          @include flex(row, flex-start, flex-start, nowrap);
          margin-top: 2rem;

          @media (max-width: $mobile-width) {
            flex-wrap: wrap;
            p {
              width: 100%;
              margin-left: 0;

              &:first-of-type {
                margin-bottom: 1rem;
              }
            }
          }
        }
      }

      .sectionLabel,
      .form-label {
        width: 100%;
        @media (min-width: $laptop-width) {
          max-width: 250px;
        }

        @media (min-width: $mobile-width) {
          max-width: 230px;
        }
      }

      .sectionValue {
        width: 100%;
        word-break: break-word;
        color: map-get($colour-palette, open-text-primary);

        @media (max-width: $tablet-width) {
          margin-left: 2rem;
        }

        @media (min-width: $tablet-width) {
          margin-left: 4rem;
        }
      }
    }
  }
}
