#cpMainContainer {
    @include flex(row, flex-start, initial, nowrap);
    border: 1px solid map-get($colour-palette, open-content-container-border);
    @include boxSizing(border-box);
    background-color: map-get($colour-palette, open-white);
    border-radius: 10px;

    @media (max-width: $mobile-width) {
        @include flex(column, initial, initial, nowrap);
    }

    #cpFormContainer {
        padding: 3rem 3rem 2rem;
        @include boxSizing(border-box);
        background: map-get($colour-palette, open-white);
        border-radius: 0 10px 10px 0;

        @media (max-width: $tablet-width) {
            width: 100%;
        }

        @media (max-width: $mobile-width) {
            border-radius: 0 0 10px 10px;
        }

        p {
            color: map-get($colour-palette, open-brand-primary);
        }

        @media (max-width: $tablet-width) {
            padding: 2rem 3rem;
        }

        @media (max-width: $mobile-width) {
            padding: 2rem 1rem;
        }

        #cpForm {
            @include flex(row, flex-start, flex-end, wrap);
            margin-top: 2rem;

            & > .formInputContainer:nth-child(odd) {
                margin-right: 4rem;

                @media (max-width: $tablet-width) {
                    margin-right: 0;
                }
            }

            .formInputContainer {
                @include flex(column, space-between, flex-start, nowrap);

                @media (max-width: $tablet-width) {
                    width: 100%;
                    .labelled-input,
                    .labelled-textarea {
                        width: 100%;
                    }
                }

                & > #char-count {
                    align-self: flex-end;
                }
                &.largeBox {
                    width: 100%; 
                    max-width: 750px;
            
                    &>.labelled-textarea:has(#enquiry-textarea){
                        width: 100%;
                    }
            
                    & #enquiry-textarea {
                        width: 100%;
                    }
                }
            
            }
            .formButtonContainer {
                @include flex(column, flex-start, flex-end, nowrap);
                width: 100%;
            }
        }
    }
}
