#viewPublishNoticesSection {
    @include boxSizing(border-box);

    flex: 1;
    width: 100%;

    #viewPublishNoticesForm {
        padding: 4rem;
        background-color: map-get($colour-palette, open-white);
        border-radius: 10px;

        // Want to do this but needs more effort to work on all form types.
        // &.submitted {
        //     input {
        //         &:invalid {
        //             border: 1px solid map-get($colour-palette, open-text-warning);
        //             outline-color: map-get($colour-palette, open-text-warning);
        //         }
        //     }
        // }


        .pageHeading, .subHeading {
            color: map-get($colour-palette, open-brand-primary);
        }

        .subHeading {
            margin-bottom: 1rem;

            .labelled-textarea{
                width: 33.3%;

                .form-textarea{
                    width: 92%;
                }
            }
        }

        #char-count {
            width: 30.75%;
        }

        #type-select, #contractType-select, #procedureType-select {
            min-width: 350px;
        }

        .noticeSearch {
            width: 350px;

            .select-search {
                margin-top: 1rem;
            }

            .select-search__options {
                max-height: 150px;
            }
        }

        .noticeDatePicker {
            width: 350px;

            .customDateInput {
                margin-top: 1rem;
            }
        }

        .vpnDivider {
            width: 100%;
            border: 1px solid map-get($colour-palette, open-divider);
            margin: 2rem 0 3rem;
        }

        .vpnItem {
            padding: 1.5rem 0;

            @media (max-width: $mobile-width) {
                padding: 1rem 0;
            }

            .vpnLabel {
                @include flex(row, flex-start, center, wrap);

                .sectionValue {
                    margin-left: 1rem;
                }
            }

            .labelled-textarea #char-count {
                width: inherit;
            }

            #valueLow-input, #contactPhone-input, #budget-input, #procedureType-select, #contractType-select {
                font-size: 1.4rem;
            }

            &Tags {
                @include flex(row, flex-start, flex-start, wrap);
                margin-top: 1rem;

                .tag-content {
                    margin: 1rem 3rem 0 0;

                    @media (max-width: $mobile-width) {
                        margin: 0 1rem 0 0;
                    }
                }
            }

            &Edit {
                @include flex(row, flex-start, flex-start, wrap);
            }

            &ButtonGroup {
                display: flex;
                justify-content: flex-end;
            }

        }
        .vpnItemButtonGroup {
            margin-top: 5rem;
        }
    }
}

.notice-header {
    color: #213d43;
    padding-top: 2rem;
    margin-bottom: 2rem;
    font-family: NotoSans-Medium;
    font-size: 1.8rem;
    line-height: normal;
}

.notice-title {
    width: 100%;
    max-width: 230px;
}

.notice-data-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-top: 2rem;
}

#form_02{
    .labelled-select,
    .labelled-input,
    .labelled-radio-button,
    .noticeSearch,
    .noticeDatePicker,
    .checkbox-title {
        margin-top: 2rem;
        // margin-bottom: 2rem;
    }

    .selectSearchTitle,
    .noticeSearch{
        width: 100%!important;
    }

    .select-search{
        width:350px;
    }

    .vpnItem {
        padding: 0!important;
    }
    .flex-break {
        flex-basis: auto;
        height: auto;
        margin: 0;
    }
    
}
#form_01,
#form_02,
#form_03{
    .criteria{
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-evenly;
        flex-wrap: nowrap;
    }
    .vpnItemNutsCode{
        margin-top: 1rem;
        @include flex(row, flex-start, center, nowrap);
    }
    .form-textarea {
        resize: auto!important;
    }

    #char-count {
        width: auto!important;
    }
}
.customDateInput.timeReceipt{
    margin-bottom: 1rem;
    width: 160px;
}


